import React, { useEffect, useState } from "react";

import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useFetchFormData, useCreateResponse } from "features/form/redux/hooks";
import { apiUrl, shareUrl } from "features/configure";
import { useConnectWallet } from "features/home/redux/hooks";
import Web3 from "web3";
import { useParams } from "react-router-dom";
import { Loading } from "features/common/Loading";
import { useNavigate, Link, useLocation } from "react-router-dom";
import FormFooter from "./components/FormFooter";
import Share from "./components/Share";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";
import Layout from "./components/Layout";
import {
  Button,
  SIZE,
  VARIANT,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/Button";
import TextEditor from "components/TextEditor/TextEditor";
import { usePublicClient } from "wagmi";
import { Helmet } from "react-helmet";
import _ from "lodash";
import axios from "axios";
import useUnload from "common/useUnload";
import FormHeader from "./components/FormHeader";
import Restriction from "./components/Restriction";
import RewardField from "./components/RewardField";
import QuestionOptions from "./question/QuestionOptions";
import IconIcRequired from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcRequired";
import Prompt from "features/common/Prompt";
import {
  StyledErrorPageContainer,
  StyledExpiredImg,
  StyledExpiredTitle,
  StyledExpiredText,
} from "./FillForm.styles";

import { validateAptosAddress } from "features/helpers/utils";

const useStyles = makeStyles((theme) => ({
  topAppBar: {
    top: "auto",
    top: 0,
    padding: 15,
    zIndex: 500,
    background: "#333333",
  },
  appBar: {
    top: "auto",
    bottom: 0,
    zIndex: 500,
    height: 60,
    background: "#333333",
  },
  headerBg: {
    background: "rgba(255, 255, 255, 0.25)",
    backdropFilter: "blur(6.23961px)",
    padding: 10,
  },
  modal: {
    textAlign: "center",
  },
  circle: {
    backgroundColor: "#459BFF",
  },
}));

const QubicFormIds = [
  "6409ac140e6dd47958a315f7",
  "641ad3c87dda1bb98cbc4d93",
  "641d7d53d00e88afdff43c9a",
];

const joyIdForms = [
  "65544aa769b6ff402a5b091b",
  "654dbd827e72ae2219ce84b3",
  "654dbdb67e72ae2219ce84dc",
  "654dbdda7e72ae2219ce8502",
  "654dbe217e72ae2219ce852c",
  "654dbe487e72ae2219ce8553",
  "654dbe927e72ae2219ce85ab",
  "654dbeb97e72ae2219ce85d2",
  "654dbedf7e72ae2219ce85f8",
];

export default function FillForm({ previewData, closePreview, fullPage }) {
  const classes = useStyles();
  const [errors, setErrors] = useState({});

  const { formId } = useParams();
  const [hover, setHover] = useState(false);
  const [init, setInit] = useState(true);
  const { userData, auth, checkAuth, signout, authCheck } = useConnectWallet();
  const publicClient = usePublicClient();
  const { createResponse, createResponsePending } = useCreateResponse();
  const { data, fetchFormData, fetchFormDataPending } = useFetchFormData();
  const responseData = _.get(data[formId], "data.response");
  const [shareOpen, setShareOpen] = React.useState(false);
  const alreadyFilled = responseData && !previewData;
  const [formData, setFormData] = useState(_.get(previewData, "form", ""));
  const isValidDate = _.get(formData, "isValidDate");
  const reward = _.get(formData, "reward");
  const [questions, setQuestions] = useState(
    _.get(previewData, "questions", [])
  );
  const navigate = useNavigate();
  const [closeCheckRestriction, setCloseCheckRestriction] = useState(false);
  const [answers, setAnswers] = useState();
  const [page, setPage] = useState(0);
  const restrictions = _.get(formData, "restrictions", []);
  const restrictionsLogic = _.get(formData, "restrictionLogic", "");
  const [passRestrictions, setPassRestrictions] = useState(false);
  const [checkRestrictions, setCheckRestrictions] = useState(false);
  const [fillAgain, setFillAgain] = useState(false);
  let allPages = questions ? questions.length : 0;
  const divRef = React.useRef(null);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(
    !previewData && fullPage
  );
  const location = useLocation();

  const isQubic = _.includes(QubicFormIds, formId);
  const isJoyId = _.includes(joyIdForms, formId);
  useEffect(() => {
    if (divRef.current) divRef.current.focus();
  }, [page]);

  useEffect(() => {
    if (previewData) return;
    fetchFormData({ formId });
    authCheck();
    setCloseCheckRestriction(false);
  }, [formId]);
  useEffect(() => {
    if (previewData) {
      setFormData(previewData.form);
      setQuestions(previewData.questions);
      if (!answers) {
        setAnswers(
          _.map(previewData.questions, (q) => {
            return { value: "", isRoleVerify: false };
          })
        );
      }
    } else if (data && data[formId]) {
      setFormData({
        ...data[formId].form,
        responseLimit: _.get(data[formId].data, "responseLimit", 0),
      });
      setQuestions(data[formId].questions);
      if (!answers) {
        setAnswers(
          _.map(data[formId].questions, (q) => {
            return { value: "", isRoleVerify: false };
          })
        );
      }
    }
  }, [data, formId, previewData]);

  useUnload((e) => {
    if (shouldBlockNavigation) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  const onNextPress = () => {
    if (page < allPages) {
      if (validate(page - 1)) {
        setPage(page + 1);
      }
    }
  };
  const onBackPress = () => {
    if (page == 1) {
      setInit(true);
      setPage(page - 1);
      return;
    }
    if (page <= allPages && page > 1) {
      setPage(page - 1);
    }
  };

  const handleKey = (event) => {
    // if (event.keyCode == 39) {
    //   if (page < allPages) {
    //     onNextPress();
    //   }
    //   if (page == allPages) {
    //     onAction();
    //   }
    // }
    // if (event.keyCode == 37) {
    //   onBackPress();
    // }
  };

  const renderMetadata = (
    title = "MetaForm",
    desc = "MetaForm",
    url,
    imageURL
  ) => {
    if (!url) {
      url = window.location.href;
    }
    if (!imageURL) {
      imageURL =
        window.location.origin +
        require("assets/img/defaultBanner.png").default;
    }
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="fragment" content="!" />
        <meta itemprop="name" content={title} />
        <meta itemprop="description" content={desc} />
        <meta itemprop="image" content={imageURL} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={imageURL} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content={imageURL} />
        <meta name="description" content={desc} />
      </Helmet>
    );
  };

  const renderOptionsField = (index) => {
    return (
      <div style={{ marginTop: 30 }}>
        <QuestionOptions
          questions={questions}
          setValue={setAnswers}
          index={index}
          answers={answers}
          isPreview={previewData}
        />
        {errors[index] && (
          <div
            style={{ color: "#FF3296", marginTop: 10, fontWeight: 700 }}
            className="startRow"
          >
            <i
              className="meta-crm-icon-ic_alert font-size-16"
              style={{ marginRight: 3 }}
            />{" "}
            {errors[index]}
          </div>
        )}
      </div>
    );
  };

  const onAction = async () => {
    try {
      if (validate(page - 1)) {
        if (previewData || !fullPage) {
          setPage(page + 1);
          return;
        }

        await createResponse({
          formData: data[formId].data,
          answers,
          questions,
        });
        setPage(page + 1);
        setShouldBlockNavigation(false);
      }
    } catch (err) {
      // console.log(err);
    }
  };
  const isReqiredValueEmpty = (q, index) => {
    let value = _.get(answers, `[${index}].value`, "");
    let file = _.get(answers, `[${index}].file`, []);
    const isValueEmpty = !value || value.length === 0;
    const isFileEmpty = !file || file.length === 0;
    return q.isRequired && isValueEmpty && isFileEmpty;
  };

  const validate = (index) => {
    if (index < 0 || previewData) return true;
    let errors = {};
    let q = questions[index];

    let value = _.get(answers, `[${index}].value`, "");

    if (isReqiredValueEmpty(q, index)) {
      errors[index] = "This is required!";
      setErrors(errors);
      return false;
    }
    if (q.type == "verify" && value !== q.validation) {
      errors[index] = "Invalid Answer!";
    }
    if (q.type == "singleText" && q.validation != "text") {
      if (
        value &&
        q.validation == "email" &&
        !value.match(
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        )
      ) {
        errors[index] = "Invalid Email! ";
      }
      if (
        value &&
        q.validation == "wallet" &&
        (formData.blockChain == "evm" || formData.chainId != 999999) &&
        !Web3.utils.isAddress(value)
      ) {
        errors[index] = "Invalid Ethereum Wallet! ";
      }

      if (
        value &&
        q.validation == "wallet" &&
        (formData.blockChain == "aptos" || formData.chainId == 999999) &&
        !validateAptosAddress(value)
      ) {
        errors[index] = "Invalid Aptos Wallet! ";
      }

      if (value && q.validation == "phone" && !isValidPhoneNumber(value)) {
        errors[index] = "Invalid phone number! ";
      }
    }
    setErrors(errors);
    if (Object.keys(errors).length > 0) return false;
    return true;
  };

  const renderHeaderPage = () => {
    return (
      <FormHeader
        fullPage={fullPage}
        hasPayment={_.some(questions, { type: "payment" })}
        nextAction={() => {
          if (
            _.get(userData, "blockchain", "no user data") !=
              _.get(formData, "blockchain", "no form data") &&
            !previewData
          ) {
            signout();
          }

          setInit(false);
          setPage(1);
        }}
        formData={formData}
      />
    );
  };
  const renderFooterPage = () => {
    return (
      <>
        <div style={{ background: "white" }}>
          <Layout
            isCard={!fullPage}
            type={_.get(formData, "footer.layout", "center")}
            text={
              <div style={{ textAlign: "center" }}>
                <div style={{ fontSize: 22, fontWeight: 900, marginBottom: 6 }}>
                  {_.get(formData, "footer.title")}
                </div>

                <TextEditor
                  id="footer.descR"
                  readOnly={"true"}
                  value={_.get(formData, "footer.desc")}
                />
                <div>
                  <Button
                    color={COLOR.INFO}
                    size={SIZE.MEDIUM}
                    variant={VARIANT.OUTLINED}
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                    onClick={() => {
                      if (!previewData) {
                        setShareOpen(true);
                      }
                    }}
                  >
                    Share
                  </Button>
                  {isQubic && (
                    <div style={{ marginLeft: 4 }}>
                      <a
                        href="https://qubic.page.link/download"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          color={COLOR.INFO}
                          variant={VARIANT.OUTLINED}
                          size={SIZE.MEDIUM}
                        >
                          Visit
                          <img
                            src={require("assets/img/qubic.svg").default}
                            className="lgIcon"
                            style={{ margin: "0 10px" }}
                          />
                          to view your NFT!
                          <img
                            src={require("assets/img/blueExport.svg").default}
                            className="smIcon"
                            style={{ margin: "0 10px" }}
                          />
                        </Button>
                      </a>
                    </div>
                  )}
                  {isJoyId && (
                    <a
                      href="https://passport.redreamer.io/"
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginLeft: 4 }}
                    >
                      <Button
                        style={{
                          background: "#B143FF",
                          color: "#FFEC00",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        size={SIZE.MEDIUM}
                      >
                        To redeem page{" "}
                        {hover ? (
                          <i
                            className="meta-crm-icon-ic_arrowLineRight font-size-16"
                            style={{ marginLeft: 10 }}
                          />
                        ) : (
                          ""
                        )}
                      </Button>
                    </a>
                  )}
                </div>
              </div>
            }
            imgUrl={_.get(
              formData,
              "footer.img",
              _.get(formData, "footer.img[0]")
            )}
            upside={true}
          />
        </div>
      </>
    );
  };

  const renderQuestionPage = () => {
    let index = page - 1;
    let q = questions[index];

    return (
      <div style={{ background: "white" }}>
        <Layout
          isCard={!fullPage}
          type={_.get(q, "layout", "right")}
          text={
            <div
              style={{
                margin: "0 auto",
                maxWidth: 350,
                marginBottom: 60,
                position: "relative",
                background: "rgba(255, 255, 255, 0.25)",
                backdropFilter: "blur(6.23961px)",
              }}
            >
              {q.isRequired && (
                <span
                  style={{
                    textAlign: "left",
                    color: "#FF3296",
                    fontWeight: 700,
                    padding: 5,
                    fontSize: 10,
                  }}
                  className="startRow"
                >
                  <IconIcRequired className="smIcon" width={18} height={18} />{" "}
                  Required
                </span>
              )}

              <div style={{ fontWeight: 900, fontSize: 20 }}>
                {_.get(q, "title", "")}
              </div>
              {_.get(q, "type") === "bind" && (
                <div style={{ fontSize: "12px", marginTop: "10px" }}>
                  Please connect to {_.get(q, "validation")} and meet the Roles
                  qualifications to continue filling out.
                </div>
              )}
              <TextEditor
                id={"qR" + index}
                readOnly={"true"}
                value={_.get(q, "subtitle", "")}
              />
              {renderOptionsField(index)}
              {isReqiredValueEmpty(q, index) ? (
                <div />
              ) : (
                <div style={{ marginTop: 20 }}>
                  {page < allPages && (
                    <>
                      <Button color="primary" onClick={onNextPress}>
                        Next
                      </Button>
                      {/* <span style={{ verticalAlign: "middle" }}>Press →</span> */}
                    </>
                  )}
                  {page == allPages && (
                    <Button color="primary" onClick={onAction}>
                      Done
                    </Button>
                  )}
                </div>
              )}
            </div>
          }
          img={
            q.img &&
            q.img.length > 0 && (
              <div style={{ textAlign: "center" }}>
                <img
                  src={_.get(q, "img", _.get(q, "img[0]"))}
                  style={{
                    height: `calc(100vh - 65px)`,
                    objectFit: "scale-down",
                    overflow: "hidden",
                    objectPosition: "center center",
                  }}
                />
              </div>
            )
          }
          imgUrl={_.get(q, "img", _.get(q, "img[0]"))}
        />
      </div>
    );
  };
  const renderRestrictionMsg = () => {
    const notPass = auth && checkRestrictions && !passRestrictions;

    return (
      <div>
        <Restriction
          restrictions={restrictions}
          notPass={notPass}
          checked={passRestrictions}
          restrictionsLogic={restrictionsLogic}
        />
        <RewardField reward={reward} />
      </div>
    );
  };
  const renderModal = () => {
    const hasRestriction =
      restrictions.length > 0 && formData.isRestrictionsRequired == true;
    // if (auth && hasRestriction) doCheckRestrictions();
    const notPass = checkRestrictions && !passRestrictions;

    return (
      <ConnectWallet
        isQubic={isQubic}
        isJoyId={isJoyId}
        extraText={
          _.some(questions, { type: "payment" }) ? (
            <ul>
              <li
                style={{
                  listStyle: "disc",
                  textAlign: "left",
                }}
              >
                MetaCRM Inc. will not be responsible with respect to (i) how
                Metaform will operate or perform and (ii) any transactions
                conducted, goods or services purchased, or funds transferred or
                received using Metaform
              </li>
            </ul>
          ) : (
            ""
          )
        }
        notPass={notPass}
        checked={checkRestrictions}
        restriction={hasRestriction ? renderRestrictionMsg() : ""}
        title={
          notPass ? "Sorry, Please try other wallet" : "Connect your wallet"
        }
        callback={doCheckRestrictions}
        startAction={() => setCloseCheckRestriction(true)}
        defaultOpen={true}
        hidden={true}
        formLogin={true}
        aptosOnly={_.get(formData, "blockchain") == "aptos"}
        evmOnly={_.get(formData, "blockchain") != "aptos"}
      />
    );
  };

  const renderExpiredPage = () => {
    return (
      <StyledErrorPageContainer>
        <h2>
          <>
            <StyledExpiredImg
              src={require("assets/img/fall_people.svg").default}
            />
            <StyledExpiredTitle>
              Oops ! This survey has already expired.
            </StyledExpiredTitle>
          </>
        </h2>
      </StyledErrorPageContainer>
    );
  };

  const renderContent = () => {
    const error = _.get(data, `[${formId}].error`);

    if (error || !formData) {
      return (
        <div style={{ textAlign: "center", marginTop: "35vh" }}>
          <h2>
            {error ||
              "The form you are trying to access does not exist or has been deleted."}
          </h2>
        </div>
      );
    }

    if (!isValidDate && !previewData) {
      return <>{renderExpiredPage()}</>;
    }

    if (init) {
      return renderHeaderPage();
    }
    if (
      !checkAuth ||
      !auth ||
      !publicClient ||
      (restrictions.length > 0 &&
        formData.isRestrictionsRequired == true &&
        !closeCheckRestriction)
    ) {
      return renderModal();
    }
    if (shareOpen) {
      return (
        <div>
          <Share link={shareUrl + formId} hideBackToList={true} />
        </div>
      );
    }

    if (page <= questions.length) {
      return renderQuestionPage();
    }
    return renderFooterPage();
  };

  const renderFooter = () => (
    <FormFooter
      absolute={!fullPage}
      page={page}
      allPages={allPages}
      onBackPress={onBackPress}
      onNextPress={onNextPress}
      previewData={previewData}
      closePreview={closePreview}
      isValidDate={isValidDate}
    />
  );
  const doCheckRestrictions = async () => {
    try {
      setCheckRestrictions(false);

      if (previewData) {
        if (!passRestrictions) {
          setPassRestrictions(true);
        }

        if (!checkRestrictions) {
          setCheckRestrictions(true);
        }

        return;
      }

      const result = await axios.post(apiUrl + "api/forms/check", { formId });

      if (_.get(result, "data.isValid")) {
        setPassRestrictions(true);
      }
      setCheckRestrictions(true);
    } catch (err) {
      // console.log(err);
      setCheckRestrictions(true);
    }
  };

  if (
    alreadyFilled &&
    !previewData &&
    !fillAgain &&
    !location.pathname.includes("preview")
  ) {
    if (_.isBoolean(isValidDate) && !isValidDate) {
      return (
        <>
          {renderExpiredPage()}
          {renderFooter()}
        </>
      );
    }

    return (
      <>
        <div className={classes.modal}>
          <div style={{ paddingTop: "50vh" }}>
            <h3>You have submitted this form.</h3>
            <Button
              color={COLOR.INFO}
              variant={VARIANT.OUTLINED}
              size={SIZE.MEDIUM}
              onClick={() => {
                if (!previewData) navigate("/");
              }}
            >
              MetaForm Homepage
            </Button>

            <Button
              color={COLOR.INFO}
              variant={VARIANT.OUTLINED}
              size={SIZE.MEDIUM}
              style={{ marginLeft: 4 }}
              onClick={() => {
                setFillAgain(true);
              }}
            >
              Submit again
            </Button>
          </div>
        </div>
        {renderFooter()}
      </>
    );
  }

  // if (!_.get(formData, "header.title")) return <div />;

  return (
    <div
      style={{ width: "100%", position: "relative" }}
      tabIndex={0}
      onKeyDown={handleKey}
      ref={divRef}
    >
      <Prompt
        message="Are you sure you want to leave?"
        when={shouldBlockNavigation}
      />

      <Loading
        open={fetchFormDataPending || createResponsePending}
        text={
          createResponsePending && (
            <div style={{ fontSize: 14 }}>
              Please do not close the screen or leave the webpage to avoid
              submission failure.
            </div>
          )
        }
      />
      {/* <LandingHeader hidden={previewData}/> */}
      {renderMetadata(
        _.get(formData, "header.title"),
        _.get(formData, "header.desc"),
        window.location.href,
        _.get(formData, "header.img")
      )}
      {renderFooter()}
      <div style={{ padding: "0 0 48px 0" }}>{renderContent()}</div>
    </div>
  );
}
