import React, { useEffect, useState } from "react";
import { Grid, Modal, IconButton, Popover } from "@material-ui/core";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import { useFetchInvite, useFetchCreateList } from "features/form/redux/hooks";
import Button from "components/CustomButtons/Button";
import { useConnectWallet } from "features/home/redux/hooks";
import _ from "lodash";
import { Loading } from "features/common";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import {
  InputField,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/InputField";

const InvitePopover = ({ formId, onClose, anchor, dataList, checkEdit }) => {
  const { userData } = useConnectWallet();
  const [addInput, setAddInput] = useState("");
  const [form, setForm] = useState({});
  const { updateFormAccess, updateAccessPending } = useFetchInvite();
  const role = _.get(form, "userFormAccessRole", "-");
  const editable = checkEdit ? role == "editor" || role == "owner" : true;

  useEffect(() => {
    setAddInput("");
    setForm(_.find(dataList, { _id: formId }) || {});
  }, [formId, updateAccessPending]);

  const renderAddress = (address, status) => {
    return (
      <div
        className="betweenRow"
        style={{
          padding: "10px 10px",
          fontSize: 12,
          position: "relative",
          color: "#555555",
          borderBottom: "0.5px solid #a5a5a5",
        }}
      >
        {address}
        {status}
      </div>
    );
  };

  return (
    <Popover
      open={Boolean(anchor)}
      PaperProps={{
        style: {
          background: "white",
          position: "relative",
          padding: 20,
          borderRadius: 20,
          maxWidth: 467,
          width: "90%",
          border: "0.5px solid #0096FF",
          boxShadow: "4px 4px 13px rgba(0, 0, 0, 0.25)",
          borderRadius: 5,
        },
      }}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div style={{ position: "relative" }}>
        <Loading open={updateAccessPending} fullScreen={false} />
        {editable && (
          <InputField
            fullwidth
            variant={VARIANT.OUTLINED}
            color={COLOR.PRIMARY}
            value={addInput}
            placeholder="Address"
            onChange={(e) => setAddInput(e.target.value)}
            endAdornment={
              <Button
                color="primary"
                onClick={async () => {
                  await updateFormAccess({
                    formId: formId,
                    address: addInput,
                    role: "viewer",
                    dataListName: checkEdit ? "invitedList" : "createList",
                  });
                }}
                size="sm"
              >
                + Invite
              </Button>
            }
          />
        )}
        {renderAddress(
          _.get(form, "creator.address", userData.address),
          <span
            style={{
              background: "#A5A5A5",
              color: "#FFFFFF",
              borderRadius: 4,
              float: "right",
              padding: "4px 10px",
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            Owner
          </span>
        )}
        {_.map(form.formAccess, (access, i) => {
          return renderAddress(
            access.address,
            editable ? (
              <CustomDropdown
                white
                disabled={access.address == userData.address}
                data={[
                  { value: "viewer", label: "Viewer" },
                  { value: "editor", label: "Editor" },
                  { value: "remove", label: "Remove" },
                ]}
                id={i}
                value={access.role}
                onSelect={async (e) => {
                  await updateFormAccess({
                    formId: formId,
                    address: access.address,
                    role: e.target.value,
                    dataListName: checkEdit ? "invitedList" : "createList",
                  });
                }}
              />
            ) : (
              <div style={{ fontWeight: 600, fontSize: 14 }}>
                {" "}
                {access.role}
              </div>
            )
          );
        })}
      </div>
    </Popover>
  );
};

export default InvitePopover;
