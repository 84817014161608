import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import {
  useFetchCreatorResponseData,
  useFetchCreatorQuestionResponseData,
} from "features/form/redux/hooks";
import Button from "@mui/material/Button";
import { useNetwork } from "wagmi";
import { Container, CircularProgress, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { COLORS } from "features/configure";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import { getShortAddress } from "features/helpers/utils";
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
  gridSortedRowIdsSelector,
} from "@mui/x-data-grid";
import { formatValue } from "features/helpers/bignumber";

export default function CreatorResponseList({}) {
  const {
    creatorResponseData,
    fetchCreatorResponseData,
    fetchCreatorResponseDataPending,
  } = useFetchCreatorResponseData();

  const {
    creatorQuestionResponseData,
    fetchCreatorQuestionResponseData,
    fetchCreatorQuestionResponseDataPending,
  } = useFetchCreatorQuestionResponseData();

  const { formId } = useParams();

  const [cols, setCols] = useState({});
  const [filterZero, setFilterZero] = useState(false);
  const [questions, setQuestions] = useState({});
  const [questionId, setQuestionId] = React.useState();
  const [apiRef, setApiRef] = React.useState();
  const [filterResponse, setFilterResponse] = useState([]);
  const { chains } = useNetwork();
  useEffect(() => {
    // console.log('creatorQuestionResponseData', creatorQuestionResponseData)
    if (
      !creatorQuestionResponseData ||
      !creatorQuestionResponseData[questionId]
    )
      return;

    let totalCount = _.get(
      creatorQuestionResponseData[questionId],
      "[0].metadata[0].total",
      0
    );
    let dataCount = _.get(
      creatorQuestionResponseData[questionId],
      "[0].data",
      []
    ).length;

    // console.log({ totalCount, dataCount })
    if (apiRef) {
      if (totalCount == dataCount) {
        const handleExport = (options) =>
          apiRef.current.exportDataAsCsv(options);
        const getUnfilteredRows = ({ apiRef }) =>
          gridSortedRowIdsSelector(apiRef);

        setTimeout(() => {
          handleExport({ getRowsToExport: getUnfilteredRows, delimiter: ";" });
        }, 1000);
      }
    }
  }, [apiRef, creatorQuestionResponseData]);

  useEffect(() => {
    if (_.get(creatorResponseData, `[${formId}]`)) {
      fetchCreatorResponseData({ formId, filterZero: filterZero ? 1 : 0 });
    }
  }, [filterZero]);

  useEffect(() => {
    if (questionId && questions) {
      fetchCreatorQuestionResponseData({
        formId,
        questionId,
        page: _.get(questions, `${questionId}.page`, 0),
        limit: _.get(questions, `${questionId}.pageSize`, 20),
        filterZero: filterZero ? 1 : 0,
      });
    }
  }, [
    questionId,
    filterZero,
    questions[questionId]?.page,
    questions[questionId]?.pageSize,
  ]);

  useEffect(() => {
    if (questionId && creatorQuestionResponseData) {
      setQuestionData(
        questionId,
        "responses",
        creatorQuestionResponseData[questionId][0].data
      );
    }
  }, [creatorQuestionResponseData]);

  const setQuestionData = (id, label, value) => {
    let newData = { ...questions };
    if (!newData[id]) {
      newData[id] = {};
    }

    if (label == "pageSize") {
      newData[id]["page"] = 0;
    }
    newData[id][label] = value;

    // console.log('newData', newData)
    setQuestions(newData);
    setQuestionId(id);
  };

  useEffect(() => {
    if (creatorResponseData && creatorResponseData[formId]) {
      let qColumns = {};
      creatorResponseData[formId].map((data, index) => {
        let q = data.question;
        let columns = [];
        if (q.type != "singleSelect" && q.type != "range") {
          columns = [
            { field: "id", headerName: "Id", width: 90 },
            { field: "address", headerName: "Address", width: 230 },
            {
              field: "response",
              headerName: "Value",
              width: 300,
              renderCell: (params) => {
                if (!params.value) return "";
                if (q.type == "payment") {
                  return (
                    <div>
                      {params.value} {q.payment.symbol}
                    </div>
                  );
                }

                return params.value;
              },
            },
          ];
          if (q.type == "payment") {
            columns.push({
              field: "txHash",
              headerName: "Tx Hash",
              width: 550,
              renderCell: (params) => {
                if (!params.value) return "";
                const chain = _.find(chains, { id: q.payment.chainId });

                return (
                  <a
                    target="_blank"
                    href={
                      chain?.blockExplorers.default.url + "/tx/" + params.value
                    }
                  >
                    {params.value}
                  </a>
                );
              },
            });
          }
          if (q.type == "file") {
            columns[2] = {
              field: "response",
              width: 300,
              renderCell: (params) => {
                const value = params.value;
                if (!value) return "";
                if (Array.isArray(value)) {
                  return (
                    <div>
                      {_.map(value, (v) => {
                        const urls = v.split("/");
                        return (
                          <div>
                            <a href={v} target="_blank">
                              {urls[urls.length - 1]}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
                const urls = value.split("/");
                return (
                  <a href={value} target="_blank">
                    {urls[urls.length - 1]}
                  </a>
                );
              },
            };
          }
        }
        qColumns[q._id] = columns;
      });
      setCols(qColumns);
    }
  }, [creatorResponseData]);

  useEffect(() => {
    if (filterZero && creatorResponseData[formId]) {
      setFilterResponse(
        _.filter(
          _.get(creatorResponseData, `[${formId}].responses`, []),
          (r) => {
            return r.responseInfo && r.responseInfo.addressNonce > 0;
          }
        )
      );
    } else if (!filterZero && creatorResponseData) {
      setFilterResponse(
        _.get(creatorResponseData, `[${formId}].responses`, [])
      );
    }
  }, [creatorResponseData, formId, filterZero]);

  const CustomToolbar = () => {
    const apiRef = useGridApiContext();
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ delimiter: ";" }} />
      </GridToolbarContainer>
    );
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (data) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, fill, index } =
      data;
    const radius = innerRadius + (outerRadius - innerRadius) * 1.25;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={fill}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  if (fetchCreatorResponseDataPending) {
    return (
      <div>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ height: `calc(100vh - 65px)` }}
        >
          <Grid item>
            <img
              src={require("assets/img/loading.gif").default}
              className="lgIcon"
            />
            <h4>Hold on, just getting this page ready</h4>
          </Grid>
        </Grid>
      </div>
    );
  }

  const renderToggleZeroButton = () => {
    if (!creatorResponseData || !creatorResponseData[formId]) return;
    return (
      <div>
        <span style={{ fontWeight: 500, marginRight: 10 }}>
          Filter Non-Active Addresses
        </span>
        <CustomSwitch
          checked={filterZero}
          onChange={(e) => {
            setFilterZero(e.target.checked);
          }}
        />
      </div>
    );
  };
  const getOptionPieStatics = (q, matchingResponses, totalCount) => {
    if (!cols[q._id]) return;

    const columns = [
      { field: "id", headerName: "Id", width: 90 },
      {
        field: "response",
        headerName: "Value",
        width: 300,
      },
    ];
    if (q.type == "file") {
      columns[1] = {
        field: "response",
        width: 300,
        renderCell: (params) => {
          if (!params.value) return "";
          const urls = params.value.split("/");
          return (
            <a href={params.value} target="_blank">
              {urls[urls.length - 1]}
            </a>
          );
        },
      };
    }

    let datagridResponses = questions[q._id]?.responses
      ? questions[q._id].responses
      : matchingResponses;

    const page = questions[q._id]?.page ? questions[q._id].page : 0;
    const pageSize = questions[q._id]?.pageSize
      ? questions[q._id].pageSize
      : 20;
    const startIndex = page * pageSize + 1;

    if (q.type != "singleSelect" && q.type != "range") {
      const rows = _.map(datagridResponses, (answer, i) => {
        const address = getShortAddress(answer.address);
        return {
          id: startIndex + i,
          response: answer.response,
          address: address,
          txHash: answer.txHash,
        };
      });

      return (
        <div>
          <div
            style={{ textAlign: "left", marginBottom: 5 }}
            className="startRow"
          >
            {totalCount} responses{" "}
            {/* {q.type == "payment" && (
              <div
                style={{ marginLeft: 10, fontSize: 16, color: "#0096FF" }}
                className="startRow"
              >
                Total Receive:{" "}
                <img
                  src={_.get(q, "payment.logoURI")}
                  className="smIcon"
                  style={{ marginLeft: 8 }}
                  onError={(e) => {
                    e.target.src = require("assets/img/spot.png").default;
                  }}
                />{" "}
                <span
                  style={{ color: "#383538", fontSize: 14, fontWeight: 500 }}
                >
                  {_.get(q, "totalPaid", "-")} {_.get(q, "payment.symbol")}
                </span>
              </div>
            )} */}
          </div>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              keepNonExistentRowsSelected
              rows={rows}
              columns={cols[q._id]}
              getRowClassName={(params) => `tableValue`}
              getCellClassName={(params) => "tableCell"}
              getRowHeight={() => "auto"}
              checkboxSelection
              disableSelectionOnClick
              components={{
                Toolbar: CustomToolbar,
              }}
              componentsProps={{ toolbar: { questionId: q._id } }}
              rowsPerPageOptions={[20, 50, 100]}
              rowCount={totalCount}
              loading={fetchCreatorQuestionResponseDataPending}
              pagination
              page={questions[q._id]?.page || 0}
              pageSize={questions[q._id]?.pageSize || 20}
              paginationMode="server"
              onPageChange={(newPage) =>
                setQuestionData(q._id, "page", newPage)
              }
              onPageSizeChange={(newPageSize) => {
                setQuestionData(q._id, "pageSize", newPageSize);
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        <div style={{ textAlign: "left" }}>
          {_.sumBy(matchingResponses, "response")} responses
        </div>
        <ResponsiveContainer height={250}>
          <PieChart style={{ margin: "0 auto" }}>
            <Pie
              data={matchingResponses}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              dataKey="response"
            >
              {matchingResponses.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend align="left" layout="vetical" verticalAlign="middle" />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const renderQuestionCards = () => {
    if (creatorResponseData && creatorResponseData[formId]) {
      return creatorResponseData[formId].map((data, index) => {
        let q = data.question;
        let responses = _.sortBy(data.responses, ["name"]);
        let totalCount = data.totalCount;
        return (
          <div className="card" key={index}>
            <div style={{ margin: "10px 40px" }}>
              <div
                style={{ fontWeight: 900, fontSize: 24, position: "relative" }}
              >
                <span
                  style={{
                    color: "#459BFF",
                    position: "absolute",
                    left: "-40px",
                    fontSize: 16,
                    top: 8,
                  }}
                >
                  Q{index + 1}.
                </span>
                <span>{q.title}</span>
              </div>

              <div style={{ textAlign: "center", marginTop: 20 }}>
                {getOptionPieStatics(q, responses, totalCount)}
              </div>
              <div
                style={{
                  margin: "10px 0",
                  color: "rgba(56, 53, 56, 0.65)",
                  fontWeight: 700,
                  fontSize: 16,
                }}
              >
                <img
                  src={require(`assets/img/${q.type}.svg`).default}
                  className="icon"
                />
                {q.type} {q.multiple && "(multiple)"}
              </div>
            </div>
          </div>
        );
      });
    }
  };

  return (
    <div>
      {renderToggleZeroButton()}
      {renderQuestionCards()}
    </div>
  );
}
