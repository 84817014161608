import React, { useEffect, useState } from "react";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import SelectToken from "../components/SelectToken";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Button from "components/CustomButtons/Button.js";
import { ethers } from "ethers";
import {
  erc20ABI,
  nativeAddress,
  chainList,
  getAlchemyKey,
  getAlchemyNetwork,
} from "features/configure";
import { useFetchFormData } from "features/form/redux/hooks";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { enqueueSnackbar } from "features/common/redux/actions";
import { useDispatch } from "react-redux";
import CustomListItem from "components/CustomListItem/CustomListItem";
import { convertAmountToRawNumber } from "features/helpers/bignumber";
import { useConnectWallet } from "features/home/redux/connectWallet";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import BigNumber from "bignumber.js";
import { useAccount } from "wagmi";
import { writeContract, sendTransaction } from "@wagmi/core";
const { Network, Alchemy } = require("alchemy-sdk");

const useStyles = makeStyles((theme) => ({
  borderTopDots: {
    borderTop: "2px dotted #777777",
    paddingTop: 8,
  },
  borderBottomGrey: {
    borderBottom: "0.5px solid #E6E6E6",
    padding: 10,
    margin: 5,
    marginBottom: 8,
    fontSize: 14,
    fontWeight: 500,
  },
  button: {
    minWidth: 87,
  },
  info: {
    fontWeight: 500,
    fontSize: 12,
  },
}));

const Payment = ({
  questions,
  index,
  setValue,
  answers,
  checkAgain,
  settings,
  isPreview,
  renderErrorMessages,
}) => {
  const [openTokenSelect, setOpenTokenSelect] = useState(false);
  const { data } = useFetchFormData();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [verifyingPayment, setVerifyingPayment] = useState(false);
  const [status, setStatus] = useState("");
  const { formId } = useParams();
  const dispatch = useDispatch();
  const { userData } = useConnectWallet();
  const { address } = useAccount();
  const q = questions[index];
  const answer = _.get(answers, `[${index}].value`, "");
  const supportChains = [1, 10, 137, 42161];
  const setQuestionData = (index, label, value) => {
    let newData = [...(answers || questions)];
    _.set(newData, `[${index}].${label}`, value);
    setValue(newData);
  };
  const getIsSuccess = () => {
    return _.get(answers, `[${index}].value`, "");
  };
  useEffect(async () => {
    if (answers) {
      if (getIsSuccess()) {
        setStatus("Success");
      } else if (!q.allowMultipleQuantity) {
        await verify();
      }
    }
  }, []);
  const isEditing = q._id;
  const verify = async () => {
    try {
      if (!formId || isPreview) return;
      setLoading(true);
      setVerifyingPayment(true);
      let payTx = "";
      const createdBlock = _.get(data, `[${formId}].data.createdBlock`, "0");

      const tokenAddress = _.get(q, "payment.address", "");
      const isNativeToken = nativeAddress == tokenAddress;
      const chainId = _.get(q, "payment.chainId", "");
      const to = _.get(q, "payment.to", "");
      const from = userData.address;

      const alchemySettings = {
        apiKey: getAlchemyKey(chainId),
        network: getAlchemyNetwork(chainId),
        maxRetries: 5,
      };

      const alchemy = new Alchemy(alchemySettings);
      const txs = await alchemy.core.getAssetTransfers({
        fromBlock: "0x" + createdBlock.toString(16),
        toBlock: "latest",
        order: "desc",
        toAddress: to,
        fromAddress: from,
        category: [isNativeToken ? "external" : "erc20"],
        withMetadata: false,
        excludeZeroValue: true,
        maxCount: "0x" + (50).toString(16),
      });
      const transfers = _.get(txs, "transfers", "");

      if (!transfers) return;

      const amount = new BigNumber(_.get(q, "payment.amount", ""))
        .multipliedBy(new BigNumber(quantity))
        .toNumber();

      for (let tx of transfers) {
        if (
          _.toLower(tx.to) == _.toLower(to) &&
          _.toLower(tx.from) == _.toLower(from) &&
          tx.value == amount &&
          _.toLower(_.get(tx, "rawContract.address")) ==
            (isNativeToken ? "" : _.toLower(tokenAddress))
        ) {
          payTx = tx.hash;
          break;
        }
      }

      if (payTx) {
        setQuestionData(index, "value", amount);
        setQuestionData(index, "txHash", payTx);
        setStatus("Success");
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: _.get(
            err,
            "response.data.message",
            _.get(err, "response.data.error", "ERROR")
          ),
          options: {
            variant: "error",
          },
        })
      );

      Sentry.captureException(err);
      setStatus("VerifyError");
    } finally {
      setLoading(false);
      setVerifyingPayment(false);
    }
  };

  const renderMessages = () => {
    return (
      <div style={{ marginTop: 20 }}>
        {status == "Success" && (
          <div
            style={{ color: "#0096FF", fontWeight: 700 }}
            className="startRow"
          >
            <i
              className="meta-crm-icon-ic_checked font-size-16"
              style={{ marginRight: 5 }}
            />
            Payment completed
          </div>
        )}
        {status == "VerifyError" && !loading && (
          <div
            style={{ color: "#FF3296", fontWeight: 700 }}
            className="startRow"
          >
            <i
              className="meta-crm-icon-ic_alert font-size-16"
              style={{ marginRight: 5 }}
            />
            Payment error
          </div>
        )}
      </div>
    );
  };
  const disabledPay =
    _.get(q, "payment.allowMultipleQuantity", "") && !quantity;
  const renderPaymentInfo = (q) => {
    const chain = _.find(chainList, {
      value: _.get(q, "payment.chainId", ""),
    })?.label;
    const token = _.get(q, "payment.address", "") ? { ...q.payment } : "";
    const remaining =
      _.get(q, "payment.maxQuantity", "") && _.get(q, "payment.amount", "")
        ? q.payment.maxQuantity - (q.totalPaid / q.payment.amount || 0)
        : "N/A";
    return (
      <div className={classes.borderTopDots}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className={classes.info}>Chain</div>
            <div className={classes.borderBottomGrey}>
              {chain ? chain : "Chain"}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.info}>Token</div>
            <div className={classes.borderBottomGrey}>
              {token ? (
                <div className="startRow">
                  <img
                    src={_.get(q, "payment.logoURI")}
                    className="smIcon"
                    onError={(e) => {
                      e.target.src = require("assets/img/spot.png").default;
                    }}
                  />
                  {_.get(q, "payment.symbol", "")}
                </div>
              ) : (
                "Token"
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.info}>Amount</div>
            <div className={`${classes.borderBottomGrey}`}>
              {_.get(q, "payment.amount", "") ? (
                <div className="startRow">
                  <img
                    src={_.get(q, "payment.logoURI")}
                    className="smIcon"
                    onError={(e) => {
                      e.target.src = require("assets/img/spot.png").default;
                    }}
                  />
                  {_.get(q, "payment.amount", "")}{" "}
                  {_.get(q, "payment.symbol", "")}
                </div>
              ) : (
                "0.0"
              )}
            </div>
          </Grid>
        </Grid>

        {_.get(q, "payment.allowMultipleQuantity", "") && (
          <div>
            <div className={classes.info}>Quantity</div>
            {!answers ? (
              <div
                className="selectToken"
                style={{ marginTop: 10, height: 40 }}
              ></div>
            ) : (
              <CustomOutlinedInput
                decimalScale={0}
                number
                sm
                maxCount={
                  _.get(q, "payment.maxQuantity", "") &&
                  _.get(q, "payment.amount", "")
                    ? remaining
                    : ""
                }
                value={quantity}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              />
            )}
          </div>
        )}
        {_.get(q, "payment.maxQuantity", "") &&
          _.get(q, "payment.amount", "") && (
            <div style={{ marginBottom: 16 }}>Remaining : {remaining}</div>
          )}
      </div>
    );
  };

  const renderSelectToken = (open, text, logo) => {
    return (
      <div
        className="selectToken"
        onClick={open}
        style={{
          border: "0px",
          background: "#F1F1F1",
          height: 39,
          paddingLeft: 13,
          margin: 0,
          cursor: isEditing ? "default" : "pointer",
        }}
      >
        <div className="startRow">
          <span>
            <img
              src={logo}
              className="smIcon"
              style={{ borderRadius: 30 }}
              onError={(e) => {
                e.target.src = require("assets/img/spot.png").default;
              }}
            />
          </span>
          <span>{text}</span>
        </div>
        <ArrowDropDownIcon style={{ verticalAlign: "middle" }} />
      </div>
    );
  };

  if (settings)
    return (
      <div>
        <CustomListItem header label="Payment Settings" />
        <div style={{ padding: 10 }}>
          <div style={{ color: "#0096FF", fontSize: 12, fontWeight: 500 }}>
            Payment will be collected by the form creator's wallet address
          </div>
          <CustomListItem subTitle label="Chain" disabled={isEditing} />
          <div>
            <CustomDropdown
              fullWidth
              disabled={isEditing}
              margin={0}
              data={_.filter(chainList, (c) =>
                _.includes(supportChains, c.value)
              )}
              value={_.get(q, "payment.chainId", "")}
              onSelect={(e) => {
                setQuestionData(index, "payment", { chainId: e.target.value });
              }}
              placeholder="Chain"
            />
          </div>
          <CustomListItem subTitle label="Token" disabled={isEditing} />
          <div>
            <SelectToken
              open={openTokenSelect}
              chainId={_.get(q, "payment.chainId", "")}
              type={"ERC20"}
              setValue={(token) => {
                setQuestionData(index, "payment", { ...q.payment, ...token });
              }}
              handleClose={() => {
                setOpenTokenSelect(false);
                checkAgain();
              }}
            />
            {renderSelectToken(
              () => {
                if (!isEditing) setOpenTokenSelect(true);
              },
              _.get(q, "payment.symbol", _.get(q, "payment.address", "Select")),
              _.get(
                q,
                "payment.logoURI",
                require("assets/img/spot.png").default
              )
            )}
          </div>
          <CustomListItem subTitle label="Amount" disabled={isEditing} />
          <CustomOutlinedInput
            onBlur={() => checkAgain()}
            number
            disabled={isEditing}
            sm
            value={_.get(q, "payment.amount", "")}
            placeholder="0.0"
            onChange={(e) => {
              setQuestionData(index, "payment.amount", e.target.value);
            }}
          />
          <CustomListItem subTitle label="Max Quantity" disabled={isEditing} />
          <CustomOutlinedInput
            number
            disabled={isEditing}
            sm
            value={_.get(q, "payment.maxQuantity", isEditing ? "N/A" : "")}
            onChange={(e) => {
              setQuestionData(index, "payment.maxQuantity", e.target.value);
            }}
          />
        </div>
        <div style={{ paddingLeft: 10 }}>
          <CustomListItem
            subTitle
            disabled={isEditing}
            label={
              <div>
                Allow
                <br /> Multiple Quantity
              </div>
            }
            rightIcon={
              <CustomSwitch
                disabled={isEditing}
                checked={_.get(q, "payment.allowMultipleQuantity", false)}
                onChange={(e) =>
                  setQuestionData(
                    index,
                    "payment.allowMultipleQuantity",
                    e.target.checked
                  )
                }
              />
            }
          />
        </div>

        {renderErrorMessages("payment")}
      </div>
    );
  if (!answers) {
    return (
      <div style={{ margin: "8px 0" }}>
        {renderPaymentInfo(q)}{" "}
        <div>
          <Button color="primary" className={classes.button}>
            Pay
          </Button>
        </div>
        <div style={{ marginTop: 16, fontWeight: 500, color: "#383538" }}>
          Payment for this question can only be processed through the wallet
          address associated with your login on the form.
        </div>
      </div>
    );
  }

  const renderButtons = () => {
    if (getIsSuccess()) return;
    if (loading) {
      return (
        <div>
          <Button
            color="blueOutlined"
            className={classes.button}
            style={{ marginTop: 15 }}
          >
            {verifyingPayment ? "Verifying payment... " : "Loading... "}
            <img
              src={require("assets/img/loading.gif").default}
              className="smIcon"
            />
          </Button>
          <div
            style={{ marginTop: 16, color: "#0096FF", fontWeight: 500 }}
            className="startRow"
          >
            <i
              className="meta-crm-icon-ic_alert font-size-16"
              style={{ marginRight: 5 }}
            />
            <div>
              Please do not close the screen or leave the webpage to avoid
              payment failure.
            </div>
          </div>
        </div>
      );
    }
    return (
      <div style={{ marginTop: 15 }}>
        <div
          style={{
            marginTop: 15,
            marginBottom: 15,
            fontWeight: 500,
            color: "#FFA500",
          }}
        >
          <i
            className="meta-crm-icon-ic_alert font-size-12"
            style={{ marginRight: 5 }}
          />
          If you have already made a payment, please do not attempt to pay
          again. Instead, try clicking on the "Verify" button, and we will
          promptly examine your transactions for you. Please input the exact
          quantity you paid to enable us to verify your payment accurately.
        </div>

        <Button
          color="primary"
          disabled={disabledPay}
          className={classes.button}
          onClick={async () => {
            try {
              if (isPreview) return;
              setLoading(true);
              const tokenAddress = _.get(q, "payment.address", "");
              const to = _.get(q, "payment.to", "");
              const chainId = _.get(q, "payment.chainId", "");
              const provider = new ethers.providers.Web3Provider(
                window.ethereum
              );
              const chain = await provider.getNetwork();
              if (chain?.chainId != chainId) {
                await window.ethereum.request({
                  method: "wallet_switchEthereumChain",
                  params: [{ chainId: "0x" + chainId.toString(16) }],
                });
                return;
              }

              const userAddress = address;
              if (userData.address !== userAddress) {
                dispatch(
                  enqueueSnackbar({
                    message: "Please connect your login wallet or log out.",
                    options: {
                      variant: "error",
                    },
                  })
                );
                return;
              }
              let tx = "";
              const total = new BigNumber(_.get(q, "payment.amount", 0))
                .multipliedBy(new BigNumber(quantity))
                .toNumber();
              const amount = convertAmountToRawNumber(
                total.toString(),
                _.get(q, "payment.decimals", 18)
              );
              if (nativeAddress == tokenAddress) {
                tx = await sendTransaction({
                  to: to,
                  value: amount,
                });
              } else {
                tx = await writeContract({
                  address: tokenAddress,
                  abi: erc20ABI,
                  functionName: "transfer",
                  args: [to, amount],
                });
              }

              if (tx) {
                let hash = _.get(tx, "hash", "");
                setQuestionData(index, "value", total);
                setQuestionData(index, "txHash", hash);
                setStatus("Success");
              }
            } catch (err) {
              console.log(err);
              dispatch(
                enqueueSnackbar({
                  message: _.get(
                    err,
                    "message",
                    _.get(err, "response.data.error", "ERROR")
                  ),
                  options: {
                    variant: "error",
                  },
                })
              );

              Sentry.captureException(err);
              setStatus("VerifyError");
            } finally {
              setLoading(false);
            }
          }}
        >
          Pay
        </Button>
        {(status !== "" || _.get(q, "payment.allowMultipleQuantity", "")) && (
          <Button
            color="blueOutlined"
            className={classes.button}
            onClick={verify}
            disabled={disabledPay}
          >
            Verify
          </Button>
        )}

        <div style={{ marginTop: 16, fontWeight: 500 }}>
          Payment for this question can only be processed through the wallet
          address associated with your login on the form.
        </div>
      </div>
    );
  };

  return (
    <div style={{}}>
      {renderPaymentInfo(q)}
      {renderMessages()}
      {renderButtons()}
    </div>
  );
};

export default Payment;
