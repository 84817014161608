import React, { useEffect, useState } from "react";
import { TextField, Modal, Divider, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import useThrottledEffect from "use-throttled-effect";
import { useFetchSwapDetail } from "../redux/fetchSwapDetail";
import Web3 from "web3";
import _ from "lodash";
import { erc20ABI, erc721ABI, chainList } from "features/configure";
import SearchIcon from "@material-ui/icons/Search";
import Avatar from "@material-ui/core/Avatar";
import { getWeb3ApiUrl } from "features/configure";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  search: {
    background: "#F1F1F1",
    width: "90%",
    padding: "5px 15px",
    borderRadius: 30,
    "& .MuiInputBase-root": {
      fontSize: 14,
    },
  },
  paper: {
    width: "30%",
    minWidth: 330,
    color: "#383538",
    backgroundColor: "white",
    borderRadius: 20,
  },
  tokenList: {
    fontWeight: 500,
    fontSize: 20,
    minWidth: 100,
    textAlign: "left",
    background: "rgba(255, 255, 255, 0.6)",
    borderRadius: 10,
    padding: 20,
    margin: 7,
    cursor: "pointer",
    boxSizing: "border-box",
  },
  selected: {
    background: "white",
    border: "2px solid #639FFF",
  },
}));

const popular = {
  ERC20: {
    1: [
      {
        symbol: "ETH",
        address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        decimals: 18,
        logoURI: "https://ethereum-optimism.github.io/data/ETH/logo.svg",
      },
      {
        symbol: "USDT",
        address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        decimals: 6,
        logoURI:
          "https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
      },
      {
        symbol: "USDC",
        address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        decimals: 6,
        logoURI:
          "https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_USDC.svg",
      },
      {
        symbol: "DAI",
        address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
        decimals: 18,
        logoURI:
          "https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_DAI.svg",
      },
      {
        symbol: "WBTC",
        address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
        decimals: 8,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
      },
    ],
    10: [
      {
        symbol: "OP",
        address: "0x4200000000000000000000000000000000000042",
        decimals: 18,
        logoURI: "https://ethereum-optimism.github.io/data/OP/logo.png",
      },
      {
        symbol: "ETH",
        address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        decimals: 18,
        logoURI: "https://ethereum-optimism.github.io/data/ETH/logo.svg",
      },
      {
        symbol: "USDC",
        address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        decimals: 6,
        logoURI:
          "https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_USDC.svg",
      },
      {
        symbol: "DAI",
        address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
        decimals: 18,
        logoURI:
          "https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_DAI.svg",
      },
      {
        symbol: "WBTC",
        address: "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
        decimals: 8,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
      },
    ],
    56: [
      {
        symbol: "BNB",
        address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        decimals: 18,
        logoURI:
          "https://assets.coingecko.com/coins/images/12591/thumb/binance-coin-logo.png?1600947313",
      },
      {
        symbol: "CAKE",
        address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
        decimals: 18,
        logoURI:
          "https://assets.coingecko.com/coins/images/12632/thumb/pancakeswap-cake-logo_%281%29.png?1629359065",
      },
      {
        symbol: "BUSD",
        address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
        decimals: 18,
        logoURI:
          "https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766",
      },
      {
        symbol: "XVS",
        address: "0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63",
        decimals: 18,
        logoURI:
          "https://assets.coingecko.com/coins/images/12677/thumb/download.jpg?1648049688",
      },
      {
        symbol: "ALPACA",
        address: "0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F",
        decimals: 18,
        logoURI:
          "https://assets.coingecko.com/coins/images/14165/thumb/Logo200.png?1614748442",
      },
    ],
    137: [
      {
        symbol: "Matic",
        address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        decimals: 18,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png",
      },
      {
        symbol: "QUICK",
        address: "0xB5C064F955D8e7F38fE0460C556a72987494eE17",
        decimals: 18,
        logoURI: "https://i.ibb.co/HGWTLM7/Quick-Icon-V2.png",
      },
      {
        symbol: "QI",
        address: "0x580A84C73811E1839F75d86d75d88cCa0c241fF4",
        decimals: 18,
        logoURI:
          "https://raw.githubusercontent.com/0xlaozi/qidao/main/images/qi.png",
      },
    ],
    42161: [
      {
        symbol: "ETH",
        address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        decimals: 18,
        logoURI: "https://ethereum-optimism.github.io/data/ETH/logo.svg",
      },
      {
        symbol: "DOPEX",
        address: "0x6C2C06790b3E3E3c38e12Ee22F8183b37a13EE55",
        decimals: 18,
        logoURI: "https://etherscan.io/token/images/dopexgovernance_32.png",
      },
      {
        symbol: "GMX",
        address: "0x590020B1005b8b25f1a2C82c5f743c540dcfa24d",
        decimals: 18,
        logoURI:
          "https://defillama.com/_next/image?url=%2Ficons%2Fgmx.jpg&w=48&q=75",
      },
      {
        symbol: "RDNT",
        address: "0x0c4681e6c0235179ec3d4f4fc4df3d14fdd96017",
        decimals: 18,
        logoURI:
          "https://defillama.com/_next/image?url=%2Ficons%2Fradiant.jpg&w=48&q=75",
      },
    ],
    43114: [
      {
        symbol: "AVAX",
        address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        decimals: 18,
        logoURI:
          "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xd00ae08403B9bbb9124bB305C09058E32C39A48c/logo.png",
      },
      {
        symbol: "QI",
        address: "0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5",
        decimals: 18,
        logoURI:
          "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5/logo.png",
      },
      {
        symbol: "JOE",
        address: "0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd",
        decimals: 18,
        logoURI:
          "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd/logo.png",
      },
    ],
    66: [],
    7001: [
      {
        symbol: "aZETA",
        address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        decimals: 18,
        logoURI:
          "https://pbs.twimg.com/profile_images/1626225438849929218/h_HtSU1a_400x400.jpg",
      },
    ],
    108: [
      {
        symbol: "TT",
        address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        decimals: 18,
        logoURI: "https://icons.llamao.fi/icons/chains/rsz_thundercore.jpg",
      },
    ],
  },
  ERC721: {
    1: [
      {
        symbol: "BAYC",
        address: "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d",
        logoURI:
          "https://lh3.googleusercontent.com/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB=s168",
      },
      {
        symbol: "CryptoPunks",
        address: "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb",
        logoURI:
          "https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s168",
      },
      {
        symbol: "MAYC",
        address: "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
        logoURI:
          "https://lh3.googleusercontent.com/lHexKRMpw-aoSyB1WdFBff5yfANLReFxHzt1DOj_sg7mS14yARpuvYcUtsyyx-Nkpk6WTcUPFoG53VnLJezYi8hAs0OxNZwlw6Y-dmI=s168",
      },
      {
        symbol: "The Otherside",
        address: "0x34d85c9CDeB23FA97cb08333b511ac86E1C4E258",
        logoURI:
          "https://lh3.googleusercontent.com/yIm-M5-BpSDdTEIJRt5D6xphizhIdozXjqSITgK4phWq7MmAU3qE7Nw7POGCiPGyhtJ3ZFP8iJ29TFl-RLcGBWX5qI4-ZcnCPcsY4zI=s168",
      },
      {
        symbol: "CloneX",
        address: "0x49cF6f5d44E70224e2E23fDcdd2C053F30aDA28B",
        logoURI:
          "https://lh3.googleusercontent.com/XN0XuD8Uh3jyRWNtPTFeXJg_ht8m5ofDx6aHklOiy4amhFuWUa0JaR6It49AH8tlnYS386Q0TW_-Lmedn0UET_ko1a3CbJGeu5iHMg=s168",
      },
      {
        symbol: "Moonbirds",
        address: "0x23581767a106ae21c074b2276D25e5C3e136a68b",
        logoURI:
          "https://lh3.googleusercontent.com/H-eyNE1MwL5ohL-tCfn_Xa1Sl9M9B4612tLYeUlQubzt4ewhr4huJIR5OLuyO3Z5PpJFSwdm7rq-TikAh7f5eUw338A2cy6HRH75=s168",
      },
      {
        symbol: "Meebits",
        address: "0x7Bd29408f11D2bFC23c34f18275bBf23bB716Bc7",
        logoURI:
          "https://lh3.googleusercontent.com/d784iHHbqQFVH1XYD6HoT4u3y_Fsu_9FZUltWjnOzoYv7qqB5dLUqpGyHBd8Gq3h4mykK5Enj8pxqOUorgD2PfIWcVj9ugvu8l0=s168",
      },
      {
        symbol: "Doodles",
        address: "0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e",
        logoURI:
          "https://lh3.googleusercontent.com/7B0qai02OdHA8P_EOVK672qUliyjQdQDGNrACxs7WnTgZAkJa_wWURnIFKeOh5VTf8cfTqW3wQpozGedaC9mteKphEOtztls02RlWQ=s168",
      },
      {
        symbol: "Azuki",
        address: "0xED5AF388653567Af2F388E6224dC7C4b3241C544",
        logoURI:
          "https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s168",
      },
      {
        symbol: "VeeFriends",
        address: "0xa3AEe8BcE55BEeA1951EF834b99f3Ac60d1ABeeB",
        logoURI:
          "https://lh3.googleusercontent.com/5y-UCAXiNOFXH551w5bWdZEYOCdHPwbqmcKb-xa3uVQEjQgxvih3HtZWSmzqDqd0uk7kIqFrZhw32Gt6xPBFg4t_n9BKhpou-dwnOg=s168",
      },
    ],
    10: [],
    56: [],
    137: [],
    42161: [],
    43114: [],
    66: [],
    7001: [],
    108: [],
  },
};

const SelectToken = ({
  open,
  handleClose,
  value,
  setValue,
  type = "ERC20",
  chainId,
}) => {
  const classes = useStyles();
  const { fetchSwapDetail, fetchSwapDetailPending, tokenList } =
    useFetchSwapDetail();
  const [search, setSearch] = useState("");
  const [tokenSearchLoading, setTokenSearchLoading] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [importToken, setImportToken] = useState("");
  let filterList =
    search && tokenList
      ? _.filter(tokenList, function (o) {
          return (
            _.toUpper(o.symbol).includes(_.toUpper(search)) ||
            _.toUpper(search) == _.toUpper(o.address)
          );
        })
      : tokenList;

  useEffect(() => {
    setSearch("");
    if (chainId) {
      fetchSwapDetail({ chainId });
    }
  }, [chainId, open]);

  useEffect(async () => {
    try {
      if (
        chainId &&
        search &&
        Web3.utils.isAddress(search) &&
        filterList.length == 0
      ) {
        const w3 = getWeb3(chainId);
        const token = new w3.eth.Contract(
          type == "ERC20" ? erc20ABI : erc721ABI,
          search
        );
        let symbol = await token.methods.symbol().call();
        let decimals = await token.methods.decimals().call();

        setImportToken({ import: true, address: search, symbol, decimals });
      }
    } catch (err) {
      setImportToken("");
    } finally {
      setTokenSearchLoading(false);
    }
  }, [search, chainId]);

  const getWeb3 = (chainId = 1) => {
    return new Web3(new Web3.providers.HttpProvider(getWeb3ApiUrl(chainId)));
  };

  const changeSearchValue = (event) => {
    let value = event.target.value;
    setTokenSearchLoading(true);
    setSearch(value);
  };

  const renderPopular = () => {
    return (
      chainId &&
      _.get(popular, `[${type}][${chainId}]`, []).map((p, i) => {
        return (
          <Grid item key={i}>
            {type == "ERC20" ? (
              <div
                style={{
                  padding: 5,
                  minWidth: 80,
                  cursor: "pointer",
                  fontWeight: 700,
                }}
                onClick={() => {
                  setValue(p);
                  handleClose();
                }}
              >
                {p.logoURI ? (
                  <img
                    src={p.logoURI || require("assets/img/spot.png").default}
                    className="smIcon"
                  />
                ) : (
                  <Avatar
                    {...{
                      sx: {
                        bgcolor: "#D16FFF",
                      },
                      children: `${p.symbol.split(" ")[0][0]}${
                        p.symbol.split(" ")[1][0]
                      }`,
                    }}
                  />
                )}

                <span style={{ verticalAlign: "middle" }}>{p.symbol}</span>
              </div>
            ) : (
              <div
                className={`${value && value == p.address && classes.selected}`}
                style={{
                  padding: 10,
                  margin: 3,
                  cursor: "pointer",
                  fontWeight: 700,
                  background: "white",
                  borderRadius: 5,
                  width: 169,
                }}
                onClick={() => {
                  setValue(p);
                  handleClose();
                }}
              >
                <img
                  src={p.logoURI || require("assets/img/spot.png").default}
                  className="smIcon"
                  loading="lazy"
                  style={{ borderRadius: 7 }}
                />
                {p.symbol}
              </div>
            )}
          </Grid>
        );
      })
    );
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={importModal}
        onClose={() => setImportModal(false)}
      >
        <div
          className={classes.paper}
          style={{ position: "relative", textAlign: "center", padding: 15 }}
        >
          <IconButton
            style={{ float: "right", padding: 0 }}
            onClick={() => setImportModal(false)}
          >
            <img
              src={require("assets/img/cancel.svg").default}
              loading="lazy"
              className="smIcon"
            />
          </IconButton>
          <div style={{ textAlign: "left" }}>
            <div>
              <img
                src={require("assets/img/alert.svg").default}
                loading="lazy"
                className="icon"
              />
            </div>
            <div style={{ margin: "20px 0" }}>
              This token doesn't appear on the active token list(s). Make sure
              this is the token that you want to use.
            </div>
          </div>
          <hr />
          <div style={{ margin: "30px 0" }}>
            <h3 className="row">
              <Avatar
                style={{ width: 30, height: 30, fontSize: 14, marginRight: 5 }}
              >
                {importToken.symbol ? importToken.symbol.substring(0, 2) : ""}
              </Avatar>
              {importToken.symbol}
            </h3>
            <div>{search}</div>
          </div>
          <hr />
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setValue(importToken);
              setImportModal(false);
              handleClose();
            }}
            style={{
              borderRadius: 15,
              width: 100,
              marginTop: 10,
            }}
          >
            Import
          </Button>
        </div>
      </Modal>
      <Modal className={classes.modal} open={open} onClose={handleClose}>
        <div
          className={classes.paper}
          style={{ position: "relative", maxWidth: 484 }}
        >
          <div style={{ padding: 20 }}>
            <IconButton
              style={{ float: "right", padding: 0 }}
              onClick={() => handleClose(false)}
            >
              <img
                src={require("assets/img/cancel.svg").default}
                loading="lazy"
                className="smIcon"
              />
            </IconButton>
            <div style={{ fontWeight: 700 }}> Select a token</div>
            <div
              style={{ color: "#A8A8A8", fontWeight: 700, margin: "10px 0" }}
            >
              Chain:{_.get(_.find(chainList, { value: chainId }), "label", "-")}
            </div>
            <TextField
              classes={{
                root: classes.search,
              }}
              value={search}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <SearchIcon style={{ marginRight: 5, color: "#777777" }} />
                ),
              }}
              placeholder={`${
                type == "ERC20" ? "Search name or " : ""
              }Paste address`}
              onChange={changeSearchValue}
              fullWidth
            />
            {type == "ERC20" && (
              <>
                <Divider style={{ margin: "10px 0" }} />
                <div
                  style={{
                    fontStyle: "italic",
                    fontWeight: 500,
                    padding: 10,
                    fontSize: 12,
                    color: "#D16FFF",
                  }}
                >
                  popular
                </div>
                <Grid container style={{}}>
                  {renderPopular()}
                </Grid>
              </>
            )}
          </div>

          <div style={{ marginTop: "10px" }} />
          <div
            style={{
              background: "#C5C5C5",
              padding: 10,
              height: 380,
              overflow: "auto",
              marginBottom: 15,
            }}
          >
            {tokenSearchLoading && (
              <div style={{ margin: "30px 0", textAlign: "center" }}>
                <img
                  src={require("assets/img/loading.gif").default}
                  className="icon"
                />
              </div>
            )}
            {importToken && (
              <div
                className={classes.tokenList}
                onClick={() => {
                  setImportModal(true);
                }}
              >
                <Grid container>
                  <Grid item xs={1}>
                    <Avatar style={{ width: 30, height: 30, fontSize: 14 }}>
                      {importToken.symbol.substring(0, 2)}
                    </Avatar>
                  </Grid>
                  <Grid item xs={7}>
                    <span
                      style={{
                        textAlign: "left",
                        marginLeft: 10,
                        fontWeight: 700,
                        fontSize: 17,
                      }}
                    >
                      {importToken.symbol}
                    </span>
                  </Grid>
                  <Grid item xs={4}>
                    {importToken.import && (
                      <Button
                        color="primary"
                        size="sm"
                        style={{
                          float: "right",
                          marginTop: "-3px",
                          borderRadius: 15,
                          width: 100,
                        }}
                      >
                        Import
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </div>
            )}
            {type == "ERC721" && (
              <Grid container style={{ padding: 15, justifyContent: "center" }}>
                {renderPopular()}
              </Grid>
            )}
            {type == "ERC20" && (
              <div>
                {filterList &&
                  filterList.map((token, index) => {
                    return (
                      <div
                        key={index}
                        className={`${classes.tokenList} ${
                          value && value == token.address && classes.selected
                        }`}
                        onClick={() => {
                          setValue(token);
                          handleClose();
                        }}
                      >
                        <span>
                          <img
                            src={
                              token.logoURI ||
                              require("assets/img/spot.png").default
                            }
                            className="icon"
                            loading="lazy"
                            style={{ borderRadius: 40, background: "white" }}
                          />
                        </span>
                        <span
                          style={{
                            fontWeight: 700,
                            fontSize: 17,
                            marginLeft: 3,
                          }}
                        >
                          {token.symbol}
                        </span>
                        {token.import && (
                          <Button
                            color="form"
                            size="sm"
                            style={{ float: "right" }}
                          >
                            Import
                          </Button>
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SelectToken;
