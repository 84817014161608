import { Connector } from "wagmi";
import QubicProvider from "@qubic-js/browser";
import * as Sentry from "@sentry/react";
export class QubicConnector extends Connector {
  id = "Qubic Wallet";
  name = "Qubic Wallet";
  ready = true;

  provider;

  constructor(config) {
    super(config);
  }

  async connect() {
    try {
      const provider = await this.getProvider();
      provider.on("disconnect", () => this.onDisconnect());
      provider.on("accountsChanged", () => this.onAccountsChanged());

      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });

      const account = accounts[0]; // Switch to chain if provided
      return {
        account,
        provider: provider,
      };
    } catch (error) {
      Sentry.captureException(error);
    }
  }
  async getProvider() {
    try {
      if (!this.provider) {
        this.provider = new QubicProvider({ autoHideWelcome: true });
      }
      return this.provider;
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  async getAccount() {
    if (!this.provider) return;
    const accounts = await this.provider.request({
      method: "eth_requestAccounts",
    });
    if (accounts.length == 0) return;
    return accounts[0];
  }
  async onDisconnect() {
    // console.log("disconnect");
    this.emit("disconnect");
  }

  async onAccountsChanged() {
    try {
      const account = await this.getAccount();
      if (!account) {
        console.log("disconnect");
      } else {
        this.emit("change", {
          account: account,
        });
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  async disconnect() {
    if (!this.provider) return;
    this.provider.removeListener("accountsChanged", () =>
      this.onAccountsChanged()
    );
    this.provider.removeListener("disconnect", () => this.onDisconnect());
  }
  async isAuthorized() {
    try {
      const account = await this.getAccount();
      return !!account;
    } catch {
      return false;
    }
  }
}
