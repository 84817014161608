import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { StyledLoadingButton } from "./LoadingButton.styles";

import styles from "./styles";

const useStyles = makeStyles(styles);

const LoadingButton = ({ text, ...otherProps }) => {
  return (
    <StyledLoadingButton {...otherProps}>
      {text}
      <img src={require("assets/img/loading2.gif").default} />
    </StyledLoadingButton>
  );
};

export default LoadingButton;
