import React, { useState } from "react";
import _ from "lodash";
import SelectToken from "./SelectToken";
import { Grid, IconButton, useMediaQuery } from "@material-ui/core";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import Button from "components/CustomButtons/Button.js";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import { chainList } from "features/configure";
import {
  StyledResponseRestrictionsEditorContainer,
  StyledRestrictionRowHeader,
  StyledRestrictionRowContentContainer,
  StyledRestrictionRowContent,
  StyledSelectToken,
} from "./ResponseRestrictionsEditor.styles";

const ResponseRestrictionsEditor = ({
  setFormData,
  formData,
  errors,
  checkAgain,
  isInit,
}) => {
  const [openTokenSelect, setOpenTokenSelect] = useState({});
  const setInputData = (label, value) => {
    let newData = { ...formData };
    if (label) {
      _.set(newData, label, value);
    } else {
      newData = { ...formData, ...value };
    }
    setFormData(newData);
  };

  const setRestrictionData = (index, label, value) => {
    let newData = [..._.get(formData, "restrictions", [])];
    _.set(newData, `[${index}]${label ? "." + label : ""}`, value);
    setInputData("restrictions", newData);
  };

  const renderSelectToken = (open, text, logo) => {
    return (
      <StyledSelectToken className="betweenRow" onClick={open}>
        <div className="startRow">
          <span>
            <img
              src={logo}
              className="smIcon"
              style={{ borderRadius: 30 }}
              onError={(e) => {
                e.target.src = require("assets/img/spot.png").default;
              }}
            />
          </span>
          <span>{text}</span>
        </div>
        <ArrowDropDownIcon style={{ verticalAlign: "middle" }} />
      </StyledSelectToken>
    );
  };

  const renderRestricitonRow = (restriction, index, isInit) => {
    return (
      <StyledRestrictionRowContent container spacing={1} key={index}>
        <Grid item xs={4}>
          <CustomDropdown
            fullWidth
            white
            data={chainList}
            value={_.get(restriction, "chainId", "")}
            onSelect={(e) =>
              setRestrictionData(index, "", {
                tokenType: formData.restrictions[index].tokenType,
                chainId: e.target.value,
              })
            }
            placeholder="Chain"
          />
        </Grid>
        <Grid item xs={4}>
          <SelectToken
            open={openTokenSelect[index]}
            chainId={_.get(restriction, "chainId", "")}
            type={_.get(restriction, "tokenType", "")}
            setValue={(token) => {
              setRestrictionData(index, "", {
                ...formData.restrictions[index],
                ...token,
              });
            }}
            handleClose={() => {
              setOpenTokenSelect({
                ...openTokenSelect,
                [index]: false,
              });
              checkAgain(isInit);
            }}
          />
          {renderSelectToken(
            () =>
              setOpenTokenSelect({
                ...openTokenSelect,
                [index]: true,
              }),
            _.get(
              restriction,
              "symbol",
              _.get(restriction, "address", "Select")
            ),
            _.get(
              restriction,
              "logoURI",
              require("assets/img/spot.png").default
            )
          )}
        </Grid>
        <Grid item xs={2}>
          <CustomOutlinedInput
            onBlur={() => checkAgain(isInit)}
            number
            sm
            value={_.get(restriction, "requiredBalance", "")}
            placeholder="0.0"
            onChange={(e) => {
              setRestrictionData(index, "requiredBalance", e.target.value);
              checkAgain(isInit);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <IconButton
            onClick={() => {
              let newData = [..._.get(formData, "restrictions", [])];
              _.pullAt(newData, [index]);
              setInputData("restrictions", newData);
            }}
          >
            <img
              src={require("assets/img/close.svg").default}
              className="smIcon"
            />
          </IconButton>
        </Grid>
      </StyledRestrictionRowContent>
    );
  };
  const renderRestricitonRowHeader = (name) => {
    let selectNameHeader = name === "POAP" ? "POAP ID" : `Select ${name}`;

    return (
      <StyledRestrictionRowHeader container>
        <Grid item xs={4}>
          Select Chain
        </Grid>
        <Grid item xs={4}>
          {selectNameHeader}
        </Grid>
        <Grid item xs={2}>
          QTY
        </Grid>
        <Grid item xs={2}></Grid>
      </StyledRestrictionRowHeader>
    );
  };

  const renderErrorMessages = (index) => {
    return errors[index] ? (
      <div
        style={{
          background: "rgba(252, 85, 85, 0.7)",
          borderRadius: 10,
          padding: 10,
          margin: "10px",
          color: "white",
        }}
      >
        {errors[index]}
      </div>
    ) : (
      <div />
    );
  };
  const containerStyle = { minHeight: 50, overflow: "auto", textAlign: "left" };
  return (
    <StyledResponseRestrictionsEditorContainer>
      <div
        className={errors["restrictions"] ? "card errorCard" : "card"}
        style={{ textAlign: "center" }}
      >
        <div className="betweenRow">
          <div>
            <img
              src={require("assets/img/lock.svg").default}
              className="smIcon"
            />
            <span style={{ fontWeight: 700, fontSize: 13 }}>
              Respondent Restrictions:
            </span>
          </div>
          {renderErrorMessages("restrictions")}
          <div>
            <CustomSwitch
              checked={_.get(formData, "isRestrictionsRequired", false)}
              onChange={async (e) => {
                let newData = {
                  ...formData,
                  isRestrictionsRequired: e.target.checked,
                  restrictionLogic: e.target.checked ? "and" : null,
                  restrictions: [],
                };
                setFormData(newData);
                checkAgain(isInit, newData);
              }}
            />
          </div>
        </div>
        {_.get(formData, "isRestrictionsRequired", false) && (
          <Grid
            container
            alignItems="stretch"
            style={{ marginTop: 10 }}
            className="setting-content"
          >
            <Grid
              item
              xs={12}
              md={3}
              style={{
                textAlign: "left",
                borderRight: "1px solid #A5A5A5",
                padding: 5,
                position: "relative",
                minHeight: 100,
              }}
            >
              <CustomDropdown
                fullWidth
                white
                data={[
                  { label: "and", value: "and" },
                  { label: "or", value: "or" },
                ]}
                value={_.get(formData, "restrictionLogic")}
                onSelect={(e) =>
                  setInputData("restrictionLogic", e.target.value)
                }
                placeholder="Restriction Logic"
              />
            </Grid>
            <Grid item xs={12} md={9}>
              {renderRestricitonRowHeader("Token")}
              <StyledRestrictionRowContentContainer>
                {_.get(formData, "restrictions", []).map(
                  (restriction, index) => {
                    if (restriction.tokenType == "ERC20")
                      return renderRestricitonRow(restriction, index, isInit);
                  }
                )}
                <Button
                  color="secondary"
                  style={{
                    minWidth: 100,
                    marginLeft: "12px",
                    marginBottom: "16px",
                  }}
                  onClick={() => {
                    let list = _.get(formData, "restrictions");
                    list.push({ tokenType: "ERC20" });
                    setInputData("restricitons", list);
                  }}
                >
                  + Token
                </Button>
              </StyledRestrictionRowContentContainer>
              {renderRestricitonRowHeader("NFT")}
              <StyledRestrictionRowContentContainer>
                {_.get(formData, "restrictions", []).map(
                  (restriction, index) => {
                    if (restriction.tokenType == "ERC721")
                      return renderRestricitonRow(restriction, index, isInit);
                  }
                )}
                <Button
                  color="secondary"
                  style={{
                    minWidth: 100,
                    marginLeft: "12px",
                    marginBottom: "16px",
                  }}
                  onClick={() => {
                    let list = _.get(formData, "restrictions", []);
                    list.push({ tokenType: "ERC721" });
                    setInputData("restricitons", list);
                  }}
                >
                  + NFT
                </Button>
              </StyledRestrictionRowContentContainer>
              {renderRestricitonRowHeader("POAP")}
              <StyledRestrictionRowContentContainer>
                {_.get(formData, "restrictions", []).map(
                  (restriction, index) => {
                    if (restriction.tokenType == "POAP")
                      return (
                        <Grid
                          key={index}
                          container
                          alignItems="center"
                          style={{ fontWeight: 700 }}
                        >
                          <Grid item xs={4}>
                            Gnosis
                          </Grid>
                          <Grid item xs={4}>
                            <CustomOutlinedInput
                              onBlur={() => checkAgain(isInit)}
                              number
                              sm
                              value={_.get(restriction, "eventId", "")}
                              placeholder="12345"
                              style={{ width: "90%" }}
                              onChange={(e) => {
                                setRestrictionData(
                                  index,
                                  "eventId",
                                  e.target.value
                                );
                                checkAgain(isInit);
                              }}
                            />
                          </Grid>
                          <Grid item xs={2} style={{ textAlign: "left" }}>
                            1
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              onClick={() => {
                                let newData = [
                                  ..._.get(formData, "restrictions", []),
                                ];
                                _.pullAt(newData, [index]);
                                setInputData("restrictions", newData);
                              }}
                            >
                              <img
                                src={require("assets/img/close.svg").default}
                                className="smIcon"
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      );
                  }
                )}
                <Button
                  color="secondary"
                  style={{
                    minWidth: 100,
                    marginLeft: "12px",
                    marginBottom: "16px",
                  }}
                  onClick={() => {
                    let list = _.get(formData, "restrictions", []);
                    list.push({ tokenType: "POAP" });
                    setInputData("restricitons", list);
                  }}
                >
                  + POAP
                </Button>
              </StyledRestrictionRowContentContainer>
            </Grid>
          </Grid>
        )}
      </div>
    </StyledResponseRestrictionsEditorContainer>
  );
};

export default ResponseRestrictionsEditor;
