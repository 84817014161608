import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Menu from "@material-ui/icons/Menu";
import Grid from "@material-ui/core/Grid";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";
import MenuItems from "./MenuItems";
import logo from "assets/img/header.svg";
import title from "assets/img/header.svg";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/CustomButtons/Button";
import { useConnectWallet, useUserSelect } from "features/home/redux/hooks";
import _ from "lodash";
import ClearIcon from "@material-ui/icons/Clear";
import { useLocation } from "react-router-dom";

const drawerWidth = "80vw";

const useStyles = makeStyles((theme) => ({
  root: {},

  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    background: "white",
    borderBottom: "0.5px solid #A5A5A5",
    boxShadow: "0px 0px 0px",
    zIndex: theme.zIndex.drawer + 1,
  },

  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    background: "linear-gradient(31.48deg, #E9E9E9 15.24%, #FFFFFF 88.85%)",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  content: {},
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  gradientBtn: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  tabButton: {
    backgroundColor: "transparent",
    color: "#383538",
    border: 0,
    fontWeight: 600,
    marginRight: 40,
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },

  // toolbar: theme.mixins.toolbar,
}));

const LandingHeader = ({ extra, hidden, maxWidth }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const { user, userSelect } = useUserSelect();
  const { auth, userData } = useConnectWallet();

  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    setMobileOpen(false);
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const renderItems = (isRow) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: isRow ? "row" : "column",
        }}
      >
        {auth && (
          <Button
            color="text"
            className={classes.tabButton}
            onClick={() => navigate(user == "user" ? "/user" : "/creator")}
          >
            {user == "user" ? "My Page" : "Workspace"}
          </Button>
        )}
        <Button
          color="text"
          className={classes.tabButton}
          onClick={() =>
            window.open(
              "https://olivine-sword-10f.notion.site/MetaCRM-Content-Portal-2486e4dbe7524205bd393a46923d9b2e"
            )
          }
        >
          {"Knowledge Base"}
        </Button>
      </div>
    );
  };
  if (hidden) {
    return <div />;
  }
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Hidden mdUp>
          <Toolbar className={classes.toolbar}>
            <Grid justifyContent="space-between" alignItems="center" container>
              <Grid item>
                <Link to={"/"}>
                  <img
                    src={logo}
                    style={{
                      height: "30px",
                      marginRight: "10px",
                      objectFit: "contain",
                      verticalAlign: "middle",
                    }}
                  />
                </Link>
              </Grid>
              <Grid item>
                {" "}
                <ConnectWallet hidden />
              </Grid>
              <Grid item>
                <IconButton
                  style={{ color: "#383538" }}
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                >
                  <Menu />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </Hidden>
        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={handleDrawerToggle}
          >
            <div
              style={{
                textAlign: "center",
                height: "100%",
                position: "relative",
              }}
            >
              <IconButton
                onClick={() => {
                  handleDrawerToggle();
                }}
                style={{ position: "absolute", top: 10, right: 10 }}
              >
                <ClearIcon />
              </IconButton>
              <MenuItems
                items={
                  <div style={{ paddingTop: "25vh" }}>
                    {renderItems(false, true)}
                  </div>
                }
                extra={extra}
              />
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
      <Hidden smDown>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Grid
              justifyContent="space-between"
              alignItems="center"
              container
              style={{ margin: "0 auto", maxWidth: maxWidth || "100vw" }}
            >
              <Grid item style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="startRow"
                  style={{
                    color: "#383538",
                    fontWeight: 700,
                    fontSize: 20,
                    padding: 10,
                  }}
                >
                  <Link to={"/"}>
                    <img
                      src={title}
                      style={{
                        height: "30px",
                        marginRight: 40,
                        objectFit: "contain",
                        verticalAlign: "middle",
                      }}
                    />
                  </Link>
                  {/* {auth && (
                    <CustomDropdown
                      data={dropdownData}
                      value={user}
                      onSelect={onSelectDrop}
                    />
                  )} */}
                  {renderItems(true)}
                </div>
              </Grid>

              <Grid>
                <MenuItems extra={extra} />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Hidden>
      <div style={{ marginBottom: 30 }} />
    </div>
  );
};

export default LandingHeader;
