import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button";
import { useConnectWallet, useUserSelect } from "features/home/redux/hooks";
import { Grid, Container, Modal, IconButton } from "@material-ui/core";
import LandingHeader from "features/home/components/LandingHeader";
import { Navigate } from "react-router-dom";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";
import { useDisconnect } from "wagmi";
const useStyles = makeStyles((theme) => ({
  card: {
    color: "#383538",
    background: "#F5F5F5",
    padding: 25,
    borderRadius: 20,
    margin: 10,
    textAlign: "center",
  },
}));

const UserLogin = () => {
  const classes = useStyles();
  const { auth } = useConnectWallet();
  const { user, userSelect } = useUserSelect();
  useEffect(() => {
    if (auth && user !== "user") userSelect("user");
  }, [auth]);

  if (auth && user == "user") {
    return <Navigate to="/user" />;
  }

  return (
    <>
      <LandingHeader />
      <div
        style={{
          background:
            "linear-gradient(89.34deg, rgba(209, 111, 255, 0.27) -13.43%, rgba(69, 155, 255, 0.27) 98.87%)",
          minHeight: "calc(100vh - 60px)",
          marginTop: 60,
        }}
      >
        <Grid
          container
          alignItems="stretch"
          justifyContent="center"
          style={{ paddingTop: 5 }}
        >
          <Grid item xs={12}>
            <div className={classes.card}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{
                    position: "relative",
                    textAlign: "left",
                    padding: 20,
                  }}
                >
                  <h1>
                    <img
                      src={require("assets/img/darkIcon.png").default}
                      className="lgIcon"
                      style={{ marginRight: 10 }}
                    />
                    User
                  </h1>
                  <h2>Blockchain-Native Survey tool With Wallet Login</h2>
                  <h4>
                    Simply connect your crypto wallet to login, NO social login
                    required.
                  </h4>
                  <div style={{ position: "absolute", bottom: "50px" }}>
                    <ConnectWallet
                      chooseUser="user"
                      buttonImg={require("assets/img/userConnect.png").default}
                      aptosOnly={false}
                      evmOnly={false}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <img
                    style={{ width: "100%" }}
                    src={require("assets/img/metaform_login.svg").default}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={classes.card}>
              <div
                style={{ fontSize: 23, textAlign: "left", color: "#459BFF" }}
              >
                Reward Form
              </div>
              <div style={{ textAlign: "center" }}>
                {" "}
                <div
                  style={{ fontSize: 28, margin: "25px 0", fontWeight: 700 }}
                >
                  Fill to Earn
                </div>
                <img
                  src={require("assets/img/fillToEarn.png").default}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={classes.card}>
              <div style={{ fontSize: 23, textAlign: "left" }}>
                Incentivized
              </div>
              <div
                style={{
                  fontSize: 28,
                  margin: "25px 0",
                  fontWeight: 700,
                }}
              >
                A Crypto-Focused
                <br />
                Survey Tool
              </div>
              <div
                className="flex-container"
                style={{
                  justifyContent: "center",
                  flexWrap: "nowrap",
                }}
              >
                <img
                  style={{ width: "100%", objectFit: "cover" }}
                  src={require("assets/img/landing_restriction.svg").default}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={classes.card}>
              {/* <div style={{ fontSize: 23, textAlign: "left" }}>Seamless</div> */}
              <div
                style={{
                  fontSize: 28,
                  margin: "15px 0",
                  fontWeight: 700,
                }}
              >
                Blockchain-Native
                <br />
                On-chain analytics
              </div>
              <div>On-chain analytics made easy with MetaForm</div>
              <div
                className="flex-container"
                style={{
                  justifyContent: "center",
                  flexWrap: "nowrap",
                }}
              >
                <img
                  style={{ width: "95%", objectFit: "cover" }}
                  src={require("assets/img/landing_surveyToEarn.svg").default}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default UserLogin;
