import React, { useEffect, useState } from "react";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import PhoneInput from "react-phone-number-input";
import _ from "lodash";
import CustomListItem from "components/CustomListItem/CustomListItem";
import TextValidation from "./TextValidation";
const Text = ({ questions, index, setValue, answers, settings }) => {
  const setQuestionData = (index, label, value) => {
    let newData = [...(answers || questions)];
    _.set(newData, `[${index}].${label}`, value);
    setValue(newData);
  };

  const q = questions[index];
  if (settings) {
    return (
      <div>
        <CustomListItem header label="Validation" />
        <TextValidation
          questions={questions}
          index={index}
          setValue={setQuestionData}
        />
      </div>
    );
  }
  if (!answers)
    return (
      <div style={{ margin: "40px 0" }}>
        <div
          style={{
            borderBottom: "1px solid #141314",
            fontSize: 14,
            fontWeight: 900,
            color: "#141314",
            textAlign: "left",
            padding: "10px 0px",
            position: "relative",
          }}
        >
          {q.type == "singleText" ? q.validation : "paragraph"}
          {q.maxCharLimit && (
            <span style={{ position: "absolute", right: 0, fontWeight: 400 }}>
              0/{q.maxCharLimit}
            </span>
          )}
        </div>
      </div>
    );

  if (q.validation == "phone") {
    return (
      <PhoneInput
        international
        placeholder="Enter phone number"
        value={_.get(answers, `[${index}].value`, "")}
        onChange={(newValue) => setQuestionData(index, "value", newValue, q)}
      />
    );
  }

  return (
    <div
      style={{
        border: q.type == "multipleText" ? "1px solid #A5A5A5" : "0px",
        padding: q.type == "multipleText" ? "5px 5px" : "5px 0px",
      }}
    >
      <CustomOutlinedInput
        multiline={q.type == "multipleText"}
        rows={4}
        noLine={q.type == "multipleText"}
        max={q.maxCharLimit}
        value={_.get(answers, `[${index}].value`, "")}
        placeholder={
          "Type your answer here… " +
          (q.type !== "verify" ? _.get(q, "validation", "") : "")
        }
        onChange={(e) => {
          let value = e.target.value;
          if (q.maxCharLimit && _.get(value, "length", 0) > q.maxCharLimit)
            return;
          setQuestionData(index, "value", value, q);
        }}
      />
    </div>
  );
};

export default Text;
