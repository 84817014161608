import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useFetchFormData } from "features/form/redux/hooks";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import Layout from "./Layout";
import _ from "lodash";
import moment from "moment/moment";
import RewardField from "./RewardField";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import TextEditor from "components/TextEditor/TextEditor";
import Restriction from "./Restriction";
import { useNavigate } from "react-router";
const useStyles = makeStyles((theme) => ({
  headerBg: {
    background: "rgba(255, 255, 255, 0.25)",
    backdropFilter: "blur(6.23961px)",
    padding: "5px",
  },
}));

const FormHeader = ({
  formData,
  nextAction,
  renderImageUpload,
  onImageLoad,
  setInputData,
  checkAgain,
  fullPage,
  renderErrorMessages,
  hasPayment,
}) => {
  const classes = useStyles();
  const reward = _.get(formData, "reward");

  const restrictions = _.get(formData, "restrictions");
  const restrictionsLogic = _.get(formData, "restrictionLogic", "");
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));

  const renderPaymentWarning = () => {
    if (!hasPayment || computer) return;
    return (
      <div
        style={{ marginTop: 30, fontWeight: 500, alignItems: "flex-start" }}
        className={`startRow ${classes.headerBg}`}
      >
        <img src={require("assets/img/payment.svg").default} className="icon" />
        <div style={{ maxWidth: 270 }}>
          This survey requires on-chain payment. <br />
          Thus, <strong>Desktop</strong> or{" "}
          <strong>MetaMask mobile app's browser</strong> are strongly
          recommended to minimize potential failure during transaction
          submission.
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        background: "white",
      }}
    >
      <Layout
        isCard={renderImageUpload || !fullPage}
        onImageLoad={onImageLoad}
        type={
          _.get(formData, "header.layout") == "background"
            ? "background"
            : "leftCover"
        }
        text={
          <div style={{ color: "#141314", fontFamily: "Inter" }}>
            <div className={classes.headerBg}>
              <div style={{ fontWeight: 900, fontSize: 27 }}>
                {setInputData ? (
                  <CustomOutlinedInput
                    multiline
                    value={_.get(formData, "header.title", "")}
                    placeholder="Title"
                    onBlur={checkAgain}
                    onChange={(e) => {
                      setInputData("header.title", e.target.value);
                      checkAgain();
                    }}
                  />
                ) : (
                  _.get(formData, "header.title")
                )}
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  marginTop: 10,
                  marginBottom: 8,
                }}
              >
                {formData.startDate && (
                  <span>
                    Start:{" "}
                    {moment(formData.startDate).format("YYYY/MM/DD HH:mm:ss")}
                  </span>
                )}
                {formData.endDate && (
                  <span style={{ marginLeft: formData.startDate ? 10 : 0 }}>
                    {" "}
                    End:{" "}
                    {moment(formData.endDate).format("YYYY/MM/DD HH:mm:ss")}
                  </span>
                )}
                {formData.responseLimit && (
                  <div>Cap on Survey #: {formData.responseLimit}</div>
                )}
              </div>
            </div>
            {formData.header && (
              <TextEditor
                id={"header.desc" + (setInputData ? "W" : "R")}
                readOnly={!setInputData}
                placeholder="Description (optional)"
                value={_.get(formData, "header.desc", "")}
                onChange={(state) => {
                  setInputData("header.desc", state);
                }}
              />
            )}

            <Restriction
              restrictions={restrictions}
              restrictionsLogic={restrictionsLogic}
            />
            <RewardField reward={reward} />
            {renderPaymentWarning()}
          </div>
        }
        imgUrl={_.get(formData, "header.img", _.get(formData, "header.img[0]"))}
        renderImageUpload={renderImageUpload}
        header={
          <div style={{ textAlign: "center", margin: 20, marginBottom: 60 }}>
            {renderErrorMessages && renderErrorMessages(0)}
            {computer ? (
              <Button color="connect" onClick={nextAction}>
                Connect Your Wallet to Start{" "}
                <img
                  src={require("assets/img/login.svg").default}
                  className="smIcon"
                  style={{ marginLeft: 10 }}
                />
              </Button>
            ) : (
              <Button
                color="mobileConnect"
                onClick={nextAction}
                style={{
                  position: fullPage ? "fixed" : "",
                  bottom: 55,
                  width: "100%",
                  left: -3,
                  borderRadius: 0,
                }}
              >
                Connect Your{" "}
                <img
                  src={
                    require(_.get(formData, "blockchain", "") == "aptos"
                      ? "assets/img/aptos.png"
                      : "assets/img/eth.png").default
                  }
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    margin: 5,
                  }}
                />{" "}
                Wallet to Start{" "}
                <img
                  src={require("assets/img/loginwhite.svg").default}
                  className="smIcon"
                  style={{ marginLeft: 10 }}
                />
              </Button>
            )}
          </div>
        }
      />
    </div>
  );
};

export default FormHeader;
