import React, { useState } from "react";
import _ from "lodash";
import SelectToken from "./SelectToken";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import { chainList } from "features/configure";
import {
  StyledRewardEditorContainer,
  StyledRewardContentContainer,
  StyledRewardGridItem,
  StyledCustomDropdown,
} from "./RewardEditor.styles";

const RewardEditor = ({
  setFormData,
  formData,
  errors,
  checkAgain,
  isInit,
  renderErrorMessages,
  setInputData,
  renderSelectToken,
}) => {
  const [openRewardTokenSelect, setOpenRewardTokenSelect] = useState(false);

  return (
    <StyledRewardEditorContainer>
      <div
        className={errors["reward"] ? "card errorCard" : "card"}
        style={{ textAlign: "center" }}
      >
        <div className="betweenRow">
          <div>
            <img
              src={require("assets/img/gift.svg").default}
              className="smIcon"
            />
            <span style={{ fontWeight: 700, fontSize: 13 }}>Rewards:</span>
          </div>
          {renderErrorMessages("reward")}
          <div>
            <CustomSwitch
              checked={_.get(formData, "reward.hasReward", false)}
              onChange={(e) => {
                setInputData("reward.hasReward", e.target.checked);
                if (!e.target.checked) {
                  setInputData("reward", {});
                }
                checkAgain(isInit);
              }}
            />
          </div>
        </div>
        {_.get(formData, "reward.hasReward", false) && (
          <StyledRewardContentContainer container alignItems="center">
            <StyledRewardGridItem item xs={12} className="cardDesc">
              Select Chain
            </StyledRewardGridItem>
            <StyledRewardGridItem item xs={6}>
              <StyledCustomDropdown
                fullWidth
                white
                data={chainList}
                value={_.get(formData, "reward.chainId")}
                onSelect={(e) =>
                  setInputData("reward", {
                    hasReward: true,
                    tokenType: "ERC20",
                    chainId: e.target.value,
                  })
                }
                placeholder="Chain"
              />
            </StyledRewardGridItem>
            <StyledRewardGridItem item xs={6} className="">
              <StyledCustomDropdown
                fullWidth
                white
                data={[
                  { label: "ERC-20", value: "ERC20" },
                  { label: "ERC-721", value: "ERC721" },
                ]}
                value={_.get(formData, "reward.tokenType")}
                onSelect={(e) =>
                  setInputData("reward", {
                    hasReward: true,
                    chainId: _.get(formData, "reward.chainId"),
                    tokenType: e.target.value,
                  })
                }
                placeholder="Token Type"
              />
            </StyledRewardGridItem>
            <StyledRewardGridItem item xs={6} className="cardDesc">
              Select Token
            </StyledRewardGridItem>
            <StyledRewardGridItem item xs={3} className="cardDesc">
              Rewards per receiver
            </StyledRewardGridItem>
            <StyledRewardGridItem item xs={3} className="cardDesc">
              # of receivers
            </StyledRewardGridItem>
            <StyledRewardGridItem item xs={6}>
              <SelectToken
                open={openRewardTokenSelect}
                chainId={_.get(formData, "reward.chainId")}
                type={_.get(formData, "reward.tokenType")}
                setValue={(token) => {
                  setInputData("reward", {
                    ...formData.reward,
                    ...token,
                  });
                }}
                handleClose={() => {
                  setOpenRewardTokenSelect(false);
                  checkAgain(isInit);
                }}
              />
              {renderSelectToken(
                () => setOpenRewardTokenSelect(true),
                _.get(
                  formData,
                  "reward.symbol",
                  _.get(formData, "reward.address", "Select")
                ),
                _.get(
                  formData,
                  "reward.logoURI",
                  require("assets/img/spot.png").default
                )
              )}
            </StyledRewardGridItem>
            <StyledRewardGridItem item xs={3}>
              <CustomOutlinedInput
                onBlur={() => checkAgain(isInit)}
                number
                sm
                value={_.get(formData, "reward.rewardAmount", "")}
                placeholder="0.0"
                onChange={(e) => {
                  setInputData("reward.rewardAmount", e.target.value);
                  checkAgain(isInit);
                }}
              />
            </StyledRewardGridItem>

            <StyledRewardGridItem item xs={3} className="cardTab">
              <CustomOutlinedInput
                onBlur={() => checkAgain(isInit)}
                number
                sm
                value={_.get(formData, "reward.rewardLimit", "")}
                placeholder="0.0"
                onChange={(e) => {
                  setInputData("reward.rewardLimit", e.target.value);
                  checkAgain(isInit);
                }}
              />
            </StyledRewardGridItem>
          </StyledRewardContentContainer>
        )}
      </div>
    </StyledRewardEditorContainer>
  );
};

export default RewardEditor;
