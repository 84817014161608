import React, { useEffect, useState, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import StatusLabel from "components/StatusLabel/StatusLabel";
import CustomDateTimePicker from "components/CustomDateTimePicker/CustomDateTimePicker";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useFetchFormData, useCreateForm } from "features/form/redux/hooks";
import useUnload from "common/useUnload";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Loading } from "features/common/Loading";
import { useParams, Navigate } from "react-router-dom";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SideMenu from "components/SideMenu/SideMenu";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  Grid,
  IconButton,
  Toolbar,
  Modal,
  List,
  Divider,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import TuneIcon from "@material-ui/icons/Tune";
import CustomListItem from "components/CustomListItem/CustomListItem";
import FillForm from "./FillForm";
import FormFooter from "./components/FormFooter";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import _ from "lodash";
import { useConnectWallet } from "features/home/redux/hooks";
import Share from "./components/Share";
import {
  shareUrl,
  chainList,
  apiUrl,
  aptosChainList,
} from "features/configure";
import Layout from "./components/Layout";
import Web3 from "web3";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./components/canvasPreview.js";
import { useDebounceEffect } from "./redux/useDebounceEffect.js";
import "react-image-crop/dist/ReactCrop.css";
import FormHeader from "./components/FormHeader";
import ReactGA from "react-ga4";
import TextEditor from "components/TextEditor/TextEditor";
import QuestionSelect from "./question/QuestionSelect";
import ImageUpload from "./components/ImageUpload";
import ResponseRestrictionsEditor from "./components/ResponseRestrictionsEditor";
import RewardEditor from "./components/RewardEditor";
import QuestionOptions from "./question/QuestionOptions";

import RewardPopover from "./components/RewardPopover";
import Prompt from "features/common/Prompt";
import {
  StyledTabs,
  StyledEditButton,
  StyledRestrictText,
  StyledDataSourceDropDown,
} from "./CreateForm.styles";
const defaultPages = 2;
function centerAspectCrop(mediaWidth, mediaHeight) {
  return centerCrop(
    {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },
    mediaWidth,
    mediaHeight
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  toolbar: theme.mixins.toolbar,
  currency: {
    fontWeight: 400,
    fontSize: 16,
    paddingLeft: 10,
    minWidth: 150,
    textAlign: "left",
    verticalAlign: "middle",
    cursor: "pointer",
  },
  content: {
    maxWidth: 1000,
    minHeight: "calc(100vh - 30px)",
    margin: "0 auto",
  },
}));
export default function CreateForm({}) {
  const { data, fetchFormData, fetchFormDataPending } = useFetchFormData();
  const { createdFormId, createForm, createFormPending } = useCreateForm();
  const { formId } = useParams();
  const classes = useStyles();
  const editing = formId;
  const { auth, checkAuth, userData } = useConnectWallet();
  const address = _.get(userData, "address");
  const blockchain = _.get(userData, "blockchain");
  const [preview, setPreview] = useState(false);
  const [showRestricitons, setShowRestricitons] = useState(false);
  const [showRewards, setShowRewards] = useState(false);
  const [init, setInit] = useState(editing ? false : true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedRow, setSelectedRow] = useState(-1);
  const previewCanvasRef = useRef([]);
  const imgRef = useRef([]);
  const [crop, setCrop] = useState({});
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const [completedCrop, setCompletedCrop] = useState({});
  const [selectedPage, setSelectedPage] = useState(0); // header =0, footer=1, question= index+2
  const [rewardAnchorEl, setRewardAnchorEl] = useState(null);
  const rewardRef = useRef(null);
  const [crosschainList, setCrosschainList] = useState(chainList);

  const handleOpenRewardPopover = () => {
    setRewardAnchorEl(rewardRef.current);
  };

  const handleCloseRewardPopover = () => {
    setRewardAnchorEl(null);
  };

  const newQuestion = {
    type: "singleSelect",
    title: "",
    subtitle: "",
    layout: "right",
    img: require("assets/img/placeholder.png").default,
    imgUpload: [
      {
        data_url: require("assets/img/placeholder.png").default,
      },
    ],
    options: [{}, {}],
  };

  const initRestrictions = {
    isRestrictionsRequired: false,
    restrictions: [],
  };
  const initReward = {
    hasReward: false,
    tokenType: "",
    chainId: "",
  };
  const [formData, setFormData] = useState({
    header: {
      title: undefined,
      layout: "left",
      imgUpload: [{ data_url: require("assets/img/placeholder.png").default }],
    },
    footer: { title: undefined, layout: "center" },
    ...initRestrictions,
    reward: initReward,
  });
  const [questions, setQuestions] = useState([{ ...newQuestion }]);
  const [shareOpen, setShareOpen] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  const hasReward = _.get(formData, "reward.hasReward", false);
  const isRestrictionsRequired = _.get(
    formData,
    "isRestrictionsRequired",
    false
  );

  useDebounceEffect(
    async () => {
      if (
        completedCrop[selectedPage]?.width &&
        completedCrop[selectedPage]?.height &&
        imgRef.current[selectedPage] &&
        previewCanvasRef.current[selectedPage]
      ) {
        canvasPreview(
          imgRef.current[selectedPage],
          previewCanvasRef.current[selectedPage],
          completedCrop[selectedPage],
          scale,
          rotate,
          setImgData()
        );
      }
    },
    200,
    [completedCrop, scale, rotate, selectedPage, questions]
  );

  const shouldBlockNavigation = auth && !shareOpen;

  useUnload((e) => {
    if (shouldBlockNavigation) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  useEffect(() => {
    if (blockchain == "aptos") {
      setCrosschainList(aptosChainList);
      setInputData("chainId", 999999);
    } else {
      setCrosschainList(chainList);
    }
  }, [blockchain]);

  useEffect(() => {
    if (formId) {
      fetchFormData({ address, formId });
    }
  }, [formId]);

  useEffect(() => {
    if (
      editing &&
      data[formId] &&
      (!_.get(formData, "header.title") ||
        _.get(formData, "formId") !== _.get(data[formId], "form.formId"))
    ) {
      setFormData(data[formId].form);
      setQuestions(data[formId].questions);
    }
  }, [data, editing]);
  const setInputData = (label, value) => {
    let newData = { ...formData };
    if (label) {
      _.set(newData, label, value);
    } else {
      newData = { ...formData, ...value };
    }

    setFormData(newData);
  };

  const setImageData = (label, value) => {
    let newData = { ...formData };
    _.set(newData, label, value);
    setFormData(newData);
  };

  const setQuestionData = (index, label, value) => {
    let newData = [...questions];

    _.set(newData, `[${index}].${label}`, value);

    setQuestions(newData);
  };

  const removeQuestionData = (index) => {
    if (questions.length == 1) return;

    let newData = [...questions];
    newData.splice(index, 1);

    setSelectedPage(defaultPages + (index == 0 ? index : index - 1));
    setQuestions(newData);
  };

  const createQuestion = () => {
    setQuestions([...questions, { ...newQuestion }]);
    const newIndex = defaultPages + questions.length;
    setSelectedPage(newIndex);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = [...Array.from(list)];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const swapJson = (data, source, destination) => {
    let prev = { ...data[source] };
    data[source] = { ...data[destination] };
    data[destination] = prev;
    return data;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let source = result.source.index + 2;
    let destination = result.destination.index + 2;

    const items = reorder(
      questions,
      result.source.index,
      result.destination.index
    );

    setQuestions(items);
    previewCanvasRef.current = swapJson(
      previewCanvasRef.current,
      source,
      destination
    );
    imgRef.current = swapJson(imgRef.current, source, destination);
    setCrop(swapJson(crop, source, destination));
    setCompletedCrop(swapJson(completedCrop, source, destination));
    setSelectedPage(destination);
  };

  const onAction = async () => {
    try {
      if (validate()) {
        ReactGA.event({
          category: "Action",
          action: "Publish Form",
          label: "",
        });

        let res = await createForm({
          formData,
          questions,
          address,
        });

        if (res) {
          ReactGA.event({
            category: "Status",
            action: "Publish Form Success",
            label: "",
          });
        }

        setShareOpen(true);
        // fetchFormData({ address, formId });
      } else {
        alert("Please fix all the errors and try again!");
      }
    } catch (err) {
      // console.log(err);
      ReactGA.event({
        category: "Status",
        action: "Publish Form Fail",
        label: _.get(err, "message", ""),
      });
    }
  };
  const checkAgain = (isInit, newData = formData) => {
    if (Object.keys(errors).length > 0) {
      validate(isInit, newData);
    }
  };
  const validate = (onlyHeader, newData = formData) => {
    let errors = {};
    let headerTitle = newData.header.title;
    let footerTitle = newData.footer.title;

    if (!headerTitle) {
      errors[0] = "Header is required!\n";
    }

    if (_.get(newData, "isRestrictionsRequired")) {
      if (newData.restrictions.length > 0) {
        for (let restrictions of newData.restrictions) {
          if (
            (restrictions.tokenType !== "POAP" &&
              (!restrictions.address || !restrictions.requiredBalance)) ||
            (restrictions.tokenType == "POAP" && !restrictions.eventId)
          ) {
            if (!errors["restrictions"]) errors["restrictions"] = "";
            errors["restrictions"] +=
              "Please select restrictions chain and token.\n";
            break;
          }
          if (restrictions.tokenType !== "POAP") {
            if (
              restrictions.address &&
              !Web3.utils.isAddress(restrictions.address)
            ) {
              if (!errors["restrictions"]) errors["restrictions"] = "";
              errors["restrictions"] += "Restriction address is invalid!\n";
            }
            if (
              restrictions.requiredBalance &&
              (isNaN(parseFloat(restrictions.requiredBalance)) ||
                parseFloat(restrictions.requiredBalance) < 0)
            ) {
              if (!errors["restrictions"]) errors["restrictions"] = "";
              errors["restrictions"] +=
                "Restriction required balance is invalid!\n";
            }
          }
        }
      } else {
        if (!errors["restrictions"]) errors["restrictions"] = "";
        errors["restrictions"] += "Please select token or NFT restrictions.\n";
      }
    }
    const reward = newData.reward;
    if (reward && reward.hasReward) {
      if (
        !reward.tokenType ||
        !reward.address ||
        !reward.rewardAmount ||
        !reward.rewardLimit
      ) {
        if (!errors["reward"]) errors["reward"] = "";
        errors["reward"] += "Select chain and token type.\n";
      }
      if (reward.address && !Web3.utils.isAddress(reward.address)) {
        if (!errors["reward"]) errors["reward"] = "";
        errors["reward"] += "rewardToken address is invalid!\n";
      }
      if (
        reward.rewardAmount &&
        (isNaN(parseFloat(reward.rewardAmount)) ||
          parseFloat(reward.rewardAmount) < 0)
      ) {
        if (!errors["reward"]) errors["reward"] = "";
        errors["reward"] += "Reward required balance is invalid!\n";
      }
    }

    if (!onlyHeader) {
      if (!footerTitle) {
        errors[1] = "Footer title is required!\n";
      }
      for (let index = 0; index < questions.length; index++) {
        let q = questions[index];
        let qError = "";
        if (!q.title) {
          qError += "Question title is required!\n";
        }
        if (q.type == "verify" && !q.validation) {
          errors["verify"] = "Verify answer is required!\n";
        }
        if (
          q.type == "payment" &&
          (!_.get(q, "payment.amount") ||
            !_.get(q, "payment.chainId") ||
            !_.get(q, "payment.address"))
        ) {
          errors["payment"] = "Payment data is required!\n";
        }
        if (q.type == "bind") {
          if (!q.content) {
            errors["bind"] = "Validation target is required!\n";
          }
          if (
            _.get(q, "validation") == "Discord" &&
            _.get(q, "discordValidation.bindingRole", false) &&
            _.get(q, "discordValidation.roles", []).length == 0
          ) {
            errors["bind"] = "Discord validation roles is empty!\n";
          }
        }
        if (q.type == "singleSelect") {
          let optionTitles = _.map(
            _.filter(q.options, (o) => !o.isOther),
            (o) => {
              return o.title;
            }
          );
          if (
            _.findIndex(optionTitles, function (o) {
              return !o;
            }) >= 0
          ) {
            qError += "Options title is required!\n";
          }
          let hasDuplicates =
            new Set(optionTitles).size !== optionTitles.length;
          if (hasDuplicates) {
            qError += "Options has duplicates!";
          }
        }

        if (qError && qError.length > 0) {
          errors[defaultPages + index] = qError;
        }
      }
    }

    setErrors(errors);

    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  const renderHeaderPage = () => {
    return (
      <FormHeader
        setInputData={setInputData}
        hasPayment={_.some(questions, { type: "payment" })}
        checkAgain={checkAgain}
        renderErrorMessages={renderErrorMessages}
        nextAction={() => {
          setSelectedPage(2);
        }}
        onImageLoad={onImageLoad}
        formData={formData}
        renderImageUpload={(soruce, img) => {
          return (
            <ImageUpload
              value={soruce}
              onChange={(list) => {
                setImageData("header.imgUpload", list);
                setImageData("header.img", _.get(list, "[0].data_url"));
              }}
              customButton={img}
            />
          );
        }}
      />
    );
  };
  const renderSelectToken = (open, text, logo) => {
    return (
      <div className="selectToken" onClick={open}>
        <div className="startRow">
          <span>
            <img
              src={logo}
              className="smIcon"
              style={{ borderRadius: 30 }}
              onError={(e) => {
                e.target.src = require("assets/img/spot.png").default;
              }}
            />
          </span>
          <span>{text}</span>
        </div>
        <ArrowDropDownIcon style={{ verticalAlign: "middle" }} />
      </div>
    );
  };

  const renderRestrictionPage = (isInit) => {
    return (
      <ResponseRestrictionsEditor
        setFormData={setFormData}
        formData={formData}
        errors={errors}
        checkAgain={checkAgain}
        isInit={isInit}
      />
    );
  };
  const renderDataSourcePage = () => {
    return (
      <div>
        <div className="tableSubHeader">Data Source Chain</div>
        {formId ? (
          <div style={{ fontWeight: 700, fontSize: 14, padding: "5px 0" }}>
            {_.get(
              _.find(crosschainList, { value: _.get(formData, "chainId", "") }),
              "label"
            )}
          </div>
        ) : (
          <StyledDataSourceDropDown
            fullWidth
            white
            data={crosschainList}
            value={_.get(formData, "chainId", "")}
            onSelect={(e) =>
              setInputData("", {
                chainId: e.target.value,
              })
            }
            placeholder="Chain"
          />
        )}
      </div>
    );
  };
  const renderRewardPage = (isInit) => {
    return (
      <RewardEditor
        setFormData={setFormData}
        formData={formData}
        errors={errors}
        checkAgain={checkAgain}
        isInit={isInit}
        renderErrorMessages={renderErrorMessages}
        setInputData={setInputData}
        renderSelectToken={renderSelectToken}
      />
    );
  };

  const renderFooterPage = () => {
    return (
      <>
        <Layout
          isCard={true}
          onImageLoad={onImageLoad}
          text={
            <>
              <div
                style={{
                  margin: "20px auto",
                  width: 350,
                  background: "rgba(255, 255, 255, 0.25)",
                  backdropFilter: "blur(6.23961px)",
                  padding: 10,
                }}
              >
                <CustomOutlinedInput
                  multiline
                  onBlur={checkAgain}
                  value={_.get(formData, "footer.title", "")}
                  placeholder="Title"
                  onChange={(e) => setInputData("footer.title", e.target.value)}
                />
                <TextEditor
                  id={"footer.desc"}
                  placeholder="Description (optional)"
                  value={_.get(formData, "footer.desc", "")}
                  onChange={(state) => setInputData("footer.desc", state)}
                />

                {renderErrorMessages(1)}
              </div>
            </>
          }
          upside={true}
          imgUrl={_.get(formData, "footer.img", "")}
          source={_.get(formData, "footer.imgUpload", [])}
          renderImageUpload={(soruce, img) => {
            return (
              <ImageUpload
                value={soruce}
                onChange={(list) => {
                  setImageData("footer.imgUpload", list);
                  setImageData("footer.img", _.get(list, "[0].data_url"));
                }}
                customButton={img}
              />
            );
          }}
          type={_.get(formData, "footer.layout")}
        />
      </>
    );
  };
  const renderDescTitle = (text) => {
    return (
      <div
        style={{
          fontSize: 12,
          fontWeight: 700,
          color: "#0096FF",
        }}
      >
        {text}
        <img
          className="smIcon"
          style={{ marginLeft: 5, width: 15, height: 15 }}
          src={require("assets/img/right.svg").default}
        />
      </div>
    );
  };
  const renderQuestionPage = () => {
    let index = selectedPage - defaultPages;
    let q = questions[index];
    if (q) {
      return (
        <Layout
          isCard={true}
          onImageLoad={onImageLoad}
          text={
            <div
              style={{
                margin: "0 auto",
                maxWidth: 350,
                marginBottom: 60,
                position: "relative",
                background: "rgba(255, 255, 255, 0.25)",
                backdropFilter: "blur(6.23961px)",
                padding: 10,
              }}
            >
              {renderDescTitle(index + 1)}
              {(q.isRequired || q.multiple) && (
                <div
                  style={{
                    textAlign: "left",
                    color: "#FF3296",
                    fontWeight: 700,
                    padding: 5,
                  }}
                >
                  {q.isRequired && (
                    <span>
                      <img
                        src={require("assets/img/checked.svg").default}
                        className="smIcon"
                      />
                      Required
                    </span>
                  )}
                  {q.multiple && (
                    <span style={{ marginLeft: 10 }}>
                      <img
                        src={require("assets/img/checked.svg").default}
                        className="smIcon"
                      />
                      Multiple select
                    </span>
                  )}
                </div>
              )}

              <CustomOutlinedInput
                multiline
                value={_.get(q, "title", "")}
                onBlur={checkAgain}
                placeholder={"Question " + (index + 1)}
                onChange={(e) =>
                  setQuestionData(index, "title", e.target.value)
                }
              />
              <TextEditor
                id={"q" + index}
                placeholder="Subtitle (optional)"
                value={_.get(q, "subtitle", "")}
                onChange={(state) => setQuestionData(index, "subtitle", state)}
              />
              <QuestionOptions
                questions={questions}
                setValue={setQuestions}
                index={index}
                renderErrorMessages={renderErrorMessages}
                checkAgain={checkAgain}
              />
              {renderErrorMessages(selectedPage)}
            </div>
          }
          imgUrl={_.get(q, "img")}
          source={_.get(q, "imgUpload", [])}
          renderImageUpload={(soruce, img) => {
            return (
              <ImageUpload
                value={soruce}
                onChange={(list) => {
                  setQuestionData(index, "imgUpload", list);
                  setQuestionData(index, "img", _.get(list, "[0].data_url"));
                }}
                customButton={img}
              />
            );
          }}
          type={q.layout}
        />
      );
    }
  };

  const renderStepper = (i) => {
    return (
      <div className="row" style={{ justifyContent: "flex-start" }}>
        <span
          style={{
            cursor: i == 1 ? "pointer" : "",
          }}
          onClick={() => {
            if (i == 1) setInit(true);
          }}
          className={`item ${i == 0 && "selectedItem"}`}
        >
          Create
        </span>
        <img
          src={require("assets/img/arrowRight.svg").default}
          className="smIcon"
        />
        <span className={`item ${i == 1 && "selectedItem"}`}>Build</span>{" "}
        <img
          src={require("assets/img/arrowRight.svg").default}
          className="smIcon"
        />
        <span className={`item ${i == 2 && "selectedItem"}`}>Share</span>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div style={{ padding: "60px 0", height: "100%" }}>
        <div
          className="betweenRow"
          style={{ flexWrap: "wrap", marginBottom: 10 }}
        >
          {renderStepper(1)}
          <div className="endRow">
            <Button
              color="dark"
              size="md"
              onClick={() => {
                if (validate()) {
                  setPreview(true);
                } else {
                  alert("Please fix all the errors and try again!");
                }
              }}
            >
              <img
                src={require("assets/img/previewWhite.svg").default}
                style={{ marginRight: 5 }}
              />
              Preview
            </Button>
            <Button
              size="md"
              color="primary"
              style={{ width: 100 }}
              onClick={() => {
                onAction();
              }}
            >
              <img
                src={require("assets/img/saveWhite.svg").default}
                style={{ marginRight: 5 }}
              />
              {editing ? "Update" : "Publish"}
            </Button>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            borderRadius: 10,
            boxShadow: "0px 1.28242px 6.41211px rgba(0, 0, 0, 0.1)",
          }}
        >
          {renderSelectedPage()}
          <FormFooter
            absolute="true"
            page={selectedPage - 1}
            allPages={questions.length}
            onBackPress={() => {
              if (selectedPage == 2) {
                setSelectedPage(0);
              } else {
                setSelectedPage(selectedPage - 1);
              }
            }}
            onNextPress={() => {
              setSelectedPage(selectedPage + 1);
            }}
          />
        </div>
      </div>
    );
  };

  const renderSelectedPage = () => {
    if (selectedPage == 0) {
      return renderHeaderPage();
    }
    if (selectedPage == 1) {
      return renderFooterPage();
    }
    if (selectedPage >= 2) {
      return renderQuestionPage();
    }
  };

  const renderErrorMessages = (index) => {
    return errors[index] ? (
      <div
        style={{
          background: "rgba(252, 85, 85, 0.7)",
          borderRadius: 10,
          padding: 10,
          margin: "5px",
          color: "white",
        }}
      >
        {errors[index]}
      </div>
    ) : (
      <div />
    );
  };

  const renderDrawerContent = () => {
    return (
      <>
        <CustomListItem header label={"Starting"} />
        <CustomListItem
          formPage
          // icon={renderItemIcon(1, "#459BFF")}
          error={errors[0]}
          selected={selectedPage == 0}
          label={
            <div className="row">
              <span style={{ width: 15 }}></span>
              <img
                src={require("assets/img/type.svg").default}
                className="smIcon"
              />
              {_.truncate(_.get(formData, "header.title", "Header Title"), {
                length: 15,
              })}
            </div>
          }
          onClick={() => {
            setSelectedPage(0);
            setSelectedTab(0);
          }}
        />
        <CustomListItem
          header
          label={"Content"}
          rightIcon={
            <>
              <Button color="transparent" onClick={createQuestion}>
                <span
                  style={{ fontSize: 11, fontWeight: 700, color: "#555555" }}
                >
                  + Add
                </span>
              </Button>
            </>
          }
        />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {questions &&
                  questions.map((q, index) => {
                    return (
                      <Draggable
                        key={"q" + index}
                        draggableId={"q" + index}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <CustomListItem
                              formPage
                              error={errors[index + defaultPages]}
                              onClick={() => {
                                setSelectedPage(index + defaultPages);
                                setSelectedTab(0);
                              }}
                              rightIcon={
                                <>
                                  <IconButton
                                    style={{ padding: 0, marginRight: 0 }}
                                    onClick={() => {
                                      if (questions.length == 1) return;
                                      setSelectedRow(index);
                                    }}
                                  >
                                    <HighlightOffIcon
                                      style={{
                                        color: "#A5A5A5",
                                        fontSize: 13,
                                      }}
                                    />
                                  </IconButton>
                                </>
                              }
                              selected={selectedPage == index + defaultPages}
                              // icon={renderItemIcon(index + 1)}
                              label={
                                <div className="row">
                                  <span
                                    style={{
                                      minWidth: 15,
                                      fontSize: 10,
                                      fontWeight: 400,
                                    }}
                                  >
                                    {index + 1}.
                                  </span>
                                  <img
                                    src={
                                      require(`assets/img/${q.type}.svg`)
                                        .default
                                    }
                                    className="smIcon"
                                  />
                                  <div>
                                    {q.isRequired && (
                                      <div
                                        style={{
                                          color: "#FF3296",
                                          fontSize: 8,
                                          fontWeight: 400,
                                        }}
                                      >
                                        *Required{" "}
                                      </div>
                                    )}
                                    {_.truncate(q.title, {
                                      length: 15,
                                    }) || "Question " + (index + 1)}
                                    <div> </div>
                                  </div>
                                </div>
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <CustomListItem header label={"Ending Page"} />
        <CustomListItem
          formPage
          selected={selectedPage == 1}
          // icon={renderItemIcon(1, "#F08282")}
          error={errors[1]}
          label={
            <div className="row">
              <span style={{ width: 15 }}></span>
              <img
                src={require("assets/img/type.svg").default}
                className="smIcon"
              />
              {_.truncate(_.get(formData, "footer.title", "End Title"), {
                length: 15,
              })}
            </div>
          }
          onClick={() => {
            setSelectedPage(1);
            setSelectedTab(0);
          }}
        />
      </>
    );
  };
  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;

    if (!crop[selectedPage]) {
      setCrop({
        ...crop,
        [selectedPage]: centerAspectCrop(width, height),
      });
    }
  };

  const renderCrop = (imgData, setValue) => {
    return (
      _.get(imgData, "imgUpload[0].data_url") && (
        <>
          <ReactCrop
            keepSelection={true}
            crop={crop[selectedPage]}
            onChange={(_, percentCrop) => {
              setCrop({
                ...crop,
                [selectedPage]: percentCrop,
              });
            }}
            onComplete={(c) => {
              setCompletedCrop({
                ...completedCrop,
                [selectedPage]: c,
              });
            }}
            // aspect={aspect}
          >
            <img
              ref={(ref) => (imgRef.current[selectedPage] = ref)}
              alt="Crop"
              src={_.get(imgData, "imgUpload[0].data_url")}
              style={{
                transform: `scale(${scale}) rotate(${rotate}deg)`,
                width: "100%",
              }}
              onLoad={(e) => onImageLoad(e)}
            />
          </ReactCrop>
          {Boolean(completedCrop[selectedPage]) && (
            <canvas
              ref={(ref) => (previewCanvasRef.current[selectedPage] = ref)}
              style={{
                objectFit: "contain",
                display: "none",
                width: completedCrop[selectedPage].width,
                height: completedCrop[selectedPage].height,
              }}
            />
          )}
        </>
      )
    );
  };

  const setImgData = () => {
    if (selectedPage == 0) {
      return (c) => {
        const newData = { ...formData };
        _.set(newData, "header.img", c);
        setFormData(newData);
      };
    }
    if (selectedPage == 1) {
      return (c) => {
        const newData = { ...formData };
        _.set(newData, "footer.img", c);
        setFormData(newData);
      };
    }
    let index = selectedPage - defaultPages;
    return (c) => {
      setQuestionData(index, "img", c);
    };
  };

  const renderToolBarImageContent = () => {
    if (selectedPage == 0) {
      return (
        <List component="nav">
          <CustomListItem
            content
            label={"Image(10MB)"}
            rightIcon={
              <ImageUpload
                value={_.get(formData, "header.imgUpload", [])}
                onChange={(imageList, addUpdateIndex) => {
                  setImageData("header.imgUpload", imageList);
                  setImageData("header.img", _.get(imageList, "[0].data_url"));
                }}
              />
            }
          />
          {renderCrop(formData.header, (c) => {
            const newData = { ...formData };
            _.set(newData, "header.img", c);
            setFormData(newData);
          })}
        </List>
      );
    }
    if (selectedPage == 1) {
      return (
        <List component="nav">
          <CustomListItem
            content
            label={"Image(10MB)"}
            rightIcon={
              <ImageUpload
                value={_.get(formData, "footer.imgUpload", [])}
                onChange={(imageList, addUpdateIndex) => {
                  setImageData("footer.imgUpload", imageList);
                  setImageData("footer.img", _.get(imageList, "[0].data_url"));
                }}
              />
            }
          />
          {renderCrop(formData.footer, (c) => {
            const newData = { ...formData };
            _.set(newData, "footer.img", c);

            setFormData(newData);
          })}
        </List>
      );
    }
    let index = selectedPage - defaultPages;
    if (index < 0) return;
    let q = questions[index];
    return (
      <List component="nav">
        <CustomListItem
          content
          label={"Image(10MB)"}
          rightIcon={
            <ImageUpload
              value={q.imgUpload}
              onChange={(list) => {
                setQuestionData(index, "imgUpload", list);
                setQuestionData(index, "img", _.get(list, "[0].data_url"));
              }}
            />
          }
        />
        {renderCrop(q, (c) => {
          setQuestionData(index, "img", c);
        })}
      </List>
    );
  };

  const renderToolBarSettings = () => {
    if (selectedPage <= 1) return <div />;
    let index = selectedPage - defaultPages;
    let q = questions[index];
    const disabledSwitchRequire =
      q.type == "verify" ||
      q.type == "statement" ||
      q.type == "payment" ||
      (q.type == "bind" &&
        _.get(q, "discordValidation.bindingRole") &&
        q.validation == "Discord");
    return (
      <List component="nav">
        <CustomListItem header label={"Settings"} />
        <CustomListItem
          content
          disabled={disabledSwitchRequire}
          label={"Required"}
          rightIcon={
            <CustomSwitch
              checked={_.get(q, "isRequired", false)}
              disabled={disabledSwitchRequire}
              onChange={(e) =>
                setQuestionData(index, "isRequired", e.target.checked)
              }
            />
          }
        />
        {(q.type == "singleText" || q.type == "multipleText") && (
          <CustomListItem
            content
            label={"Max characters"}
            rightIcon={
              <CustomOutlinedInput
                style={{ width: 70 }}
                number
                sm
                value={_.get(q, "maxCharLimit")}
                placeholder="Amount"
                onChange={(e) =>
                  setQuestionData(index, "maxCharLimit", e.target.value)
                }
              />
            }
          />
        )}
        {q.type == "singleSelect" && (
          <CustomListItem
            content
            label={"Mutiple Selection"}
            rightIcon={
              <CustomSwitch
                checked={_.get(q, "multiple", false)}
                onChange={(e) =>
                  setQuestionData(index, "multiple", e.target.checked)
                }
              />
            }
          />
        )}
      </List>
    );
  };
  const renderCapConfig = () => {
    return (
      <CustomOutlinedInput
        number
        style={{ maxWidth: 200 }}
        sm
        value={_.get(formData, "responseLimit")}
        placeholder="Amount"
        onChange={(e) => setInputData("responseLimit", e.target.value)}
        // type="number"
      />
    );
  };

  const renderDateConfig = (isInit) => {
    const width = isInit ? "50%" : "100%";
    const iconStyle = { color: "#A5A5A5", fontSize: 13 };
    return (
      // <Application theme={rainbowTheme}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          color: "#A5A5A5",
          fontWeight: 400,
          fontSize: 10,
          marginTop: 5,
        }}
      >
        <div style={{ width }}>
          <div>From</div>
          <div className="row">
            <CustomDateTimePicker
              formatStyle="small"
              hour24={true}
              onChange={(value) => setInputData("startDate", value)}
              value={formData.startDate && new Date(formData.startDate)}
              minDate={new Date()}
              borderRadius="semi-square"
              placeholder="Select date and time"
              size="small"
              icon={
                <img
                  src={require("assets/img/ic_calendar.svg").default}
                  className="smIcon"
                />
              }
              renderIconBtn={() => (
                <IconButton
                  onClick={() => setInputData("startDate", undefined)}
                  style={{ padding: 2, marginRight: "0" }}
                >
                  <RefreshIcon style={iconStyle} />
                </IconButton>
              )}
              valueAlignment="right"
            />
          </div>
        </div>
        <div style={{ width }}>
          <div>To</div>
          <div className="row">
            <CustomDateTimePicker
              hour24={true}
              onChange={(value) => setInputData("endDate", value)}
              value={formData.endDate && new Date(formData.endDate)}
              minDate={new Date()}
              borderRadius="semi-square"
              size="small"
              icon={
                <img
                  src={require("assets/img/ic_calendar.svg").default}
                  className="smIcon"
                />
              }
              placeholder="Select date and time"
              valueAlignment="left"
              renderIconBtn={() => (
                <IconButton
                  onClick={() => setInputData("endDate", undefined)}
                  style={{ padding: 2, marginRight: "0" }}
                >
                  <RefreshIcon style={iconStyle} />
                </IconButton>
              )}
            />
          </div>
        </div>{" "}
      </div>
      // </Application>
    );
  };
  const renderLayoutButton = (type, selected, onClick, key) => {
    const isSelected = selected == type;
    return (
      <img
        key={key}
        style={{
          cursor: "pointer",
          margin: 2,
          height: 35,
          width: 53,
        }}
        onClick={onClick}
        src={
          require(`assets/img/layout_${type}${isSelected ? "_s" : ""}.svg`)
            .default
        }
      />
    );
  };
  const renderLayoutSelection = () => {
    if (selectedPage <= 1) {
      const place = selectedPage == 0 ? "header" : "footer";
      const selected = _.get(formData, place + ".layout");
      return (
        <>
          <div>
            <CustomListItem content label={"Layout"} />
            <div style={{ margin: 5 }}>
              {[selectedPage == 0 ? "left" : "center", "background"].map(
                (type, i) =>
                  renderLayoutButton(
                    type,
                    selected,
                    () => {
                      setInputData(place + ".layout", type);
                    },
                    i
                  )
              )}
            </div>
          </div>
        </>
      );
    }
    let index = selectedPage - defaultPages;
    let q = questions[index];
    const selected = _.get(q, "layout");
    return (
      <div>
        <CustomListItem content label={"Layout"} />
        <div style={{ margin: 5 }}>
          {[
            "right",
            "left",
            "center",
            "rightCover",
            "leftCover",
            "background",
          ].map((type, i) =>
            renderLayoutButton(
              type,
              selected,
              () => {
                setQuestionData(index, "layout", type);
              },
              i
            )
          )}
        </div>
      </div>
    );
  };

  const renderToolBarAdjustContent = () => {
    return (
      <div ref={rewardRef}>
        <StyledTabs
          fullWidth
          tabIndex={selectedTab}
          handleChange={(e, newValue) => setSelectedTab(newValue)}
          tabs={[
            {
              label: (
                <div className="row">
                  <TuneIcon style={{ fontSize: 14, marginRight: 5 }} />
                  General
                </div>
              ),
              content: (
                <div>
                  {renderToolBarQuestionTypeContent()}
                  {renderToolBarSettings()}
                  <CustomListItem label={"Background"} header />
                  {renderToolBarImageContent()}
                  {renderLayoutSelection()}
                </div>
              ),
            },
            {
              label: (
                <div className="row">
                  <SettingsIcon style={{ fontSize: 14, marginRight: 5 }} />
                  Advanced
                </div>
              ),
              content: (
                <div>
                  <CustomListItem
                    label={
                      <StyledRestrictText>
                        Restriction and
                        <br />
                        Reward Setting
                      </StyledRestrictText>
                    }
                    header
                    rightIcon={
                      formData.chainId != 999999 ? (
                        <StyledEditButton
                          size="md"
                          color="primary"
                          onClick={handleOpenRewardPopover}
                          disabled={formData.chainId == 999999}
                        >
                          <img
                            src={require("assets/img/ic_edit.svg").default}
                          />
                          Edit
                        </StyledEditButton>
                      ) : (
                        ""
                      )
                    }
                  />
                  <div style={{ padding: "0 5px" }}>
                    <div className="startRow">
                      {errors["restrictions"] ? (
                        <img
                          src={require("assets/img/error.svg").default}
                          style={{ margin: "0 5px", height: 16, width: 16 }}
                          alt="error-icon"
                        />
                      ) : (
                        ""
                      )}
                      <Button
                        color="secondary"
                        style={{
                          justifyContent: "left",
                          height: "36px",
                          padding: "5px",
                          marginTop: "10px",
                          marginRight: 0,
                        }}
                        fullWidth
                      >
                        <img
                          src={require("assets/img/lock.svg").default}
                          className="smIcon"
                          alt="lock-icon"
                        />
                        <span style={{ fontSize: 12 }}>Restrictions</span>

                        <StatusLabel
                          type={isRestrictionsRequired ? "success" : "error"}
                          style={{ marginLeft: "auto" }}
                        >
                          {isRestrictionsRequired ? "Enabled" : "Disabled"}
                        </StatusLabel>
                      </Button>
                    </div>

                    <div className="startRow">
                      {errors["reward"] ? (
                        <img
                          src={require("assets/img/error.svg").default}
                          style={{ margin: "0 5px", height: 16, width: 16 }}
                          alt="error-icon"
                        />
                      ) : (
                        ""
                      )}
                      <Button
                        color="secondary"
                        style={{
                          justifyContent: "left",
                          height: "36px",
                          padding: "5px",
                          marginTop: "10px",
                          marginRight: 0,
                        }}
                        fullWidth
                      >
                        <img
                          src={require("assets/img/gift.svg").default}
                          className="smIcon"
                          alt="gift-icon"
                        />
                        <span style={{ fontSize: 12 }}>Rewards</span>
                        <StatusLabel
                          type={hasReward ? "success" : "error"}
                          style={{ marginLeft: "auto" }}
                        >
                          {hasReward ? "Enabled" : "Disabled"}
                        </StatusLabel>
                      </Button>
                    </div>
                  </div>

                  <CustomListItem
                    label={"Date Setting"}
                    style={{ marginTop: "24px", color: "#555555" }}
                    header
                  />
                  <div style={{ padding: "10px" }}>{renderDateConfig()}</div>
                  <CustomListItem label={"Cap on Survey"} header />
                  <div style={{ padding: "10px" }}>{renderCapConfig()}</div>
                  <div style={{ padding: 5 }}>{renderDataSourcePage()}</div>
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  };

  const renderToolBarQuestionTypeContent = () => {
    if (selectedPage < defaultPages) {
      return;
    }
    let index = selectedPage - defaultPages;
    let q = questions[index];
    return (
      <>
        <CustomListItem header label="Type" />
        <QuestionSelect
          questions={questions}
          index={index}
          setQuestions={setQuestions}
          blockchain={blockchain}
        />
        <QuestionOptions
          questions={questions}
          setValue={setQuestions}
          renderErrorMessages={renderErrorMessages}
          index={index}
          checkAgain={checkAgain}
          settings={true}
        />
      </>
    );
  };

  const renderToolBar = () => {
    return renderToolBarAdjustContent();
  };

  const renderHeaderAdjust = () => {
    return (
      <>
        <div className="tableSubHeader" style={{ textAlign: "left" }}>
          Survey Availability Date
        </div>
        <div>{renderDateConfig(true)}</div>
        <div className="tableSubHeader" style={{ textAlign: "left" }}>
          Cap on Survey
        </div>
        <div style={{ padding: "5px 0", textAlign: "left" }}>
          {renderCapConfig(true)}
        </div>
      </>
    );
  };
  if (!checkAuth) return <Loading open={true} />;
  if (!auth) return <Navigate to="/" />;
  // if (!auth) return <Navigate to="/" />;

  if (init) {
    return (
      <div style={{ background: "#F8F8F8", minHeight: "calc(100vh - 30px)" }}>
        <Toolbar />
        <div
          style={{
            margin: "0px auto",
            width: "100%",
            maxWidth: 1050,
          }}
        >
          <Button
            color="primary"
            style={{
              float: "right",
            }}
            onClick={() => {
              if (validate(true)) {
                ReactGA.event({
                  category: "Action",
                  action: "Create New Survey",
                  label: "step create",
                });
                setInit(false);
              }
            }}
          >
            <div className="startRow">
              <div style={{ fontSize: 14, fontWeight: 600, paddingRight: 30 }}>
                Create
              </div>
            </div>
            <AddIcon style={{ fontSize: 22, marginLeft: 20 }} />
          </Button>
          {renderStepper(0)}
          <Grid container alignItems="flex-start" spacing={1}>
            <Grid item xs={12} lg={5}>
              <div className="card">
                <div className="tableSubHeader">Name</div>
                <div>
                  <CustomOutlinedInput
                    multiline
                    outline={true}
                    fullWidth
                    value={_.get(formData, "header.title")}
                    placeholder="Type your form name here...."
                    onChange={(e) => {
                      setInputData("header.title", e.target.value);
                      validate(true);
                    }}
                  />
                  <div className="card">
                    <TextEditor
                      noLine={true}
                      id={"header.descInit"}
                      placeholder="Description (optional)"
                      value={_.get(formData, "header.desc", "")}
                      onChange={(state) => {
                        setInputData("header.desc", state);
                      }}
                    />
                  </div>
                </div>

                <div>{renderErrorMessages(0)}</div>
                <div>{renderHeaderAdjust()}</div>
              </div>
            </Grid>
            <Grid item xs={12} lg={7}>
              <div className="card">
                <div
                  className="tableHeader"
                  style={{
                    textAlign: "left",
                    position: "relative",
                    margin: "0px 5px",
                    fontSize: 16,
                    fontWeight: 900,
                  }}
                >
                  <img
                    src={require("assets/img/headerDeco.svg").default}
                    className="smIcon"
                  />
                  Advanced
                </div>
                {blockchain != "aptos" ? renderRestrictionPage(true) : ""}
                {blockchain != "aptos" ? renderRewardPage(true) : ""}
                {renderDataSourcePage()}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
  if (createFormPending) {
    return (
      <Loading
        open
        text={editing ? "Updating your form data" : "Encrypt your form data..."}
      />
    );
  }
  return (
    <div>
      <div
        style={{
          minHeight: "calc(100vh - 60px)",
          background: "#F8F8F8",
        }}
      >
        <Modal open={shareOpen} className={"modal"}>
          <div>
            <div className="loadingPaper">
              <Share link={shareUrl + createdFormId} />
            </div>
          </div>
        </Modal>

        <Modal
          open={preview}
          onClose={() => setPreview(false)}
          className={"modal"}
        >
          <div className={"modalPaper"}>
            <FillForm
              fullPage={true}
              previewData={{ form: formData, questions: questions }}
              closePreview={() => setPreview(false)}
            />
          </div>
        </Modal>
        <Modal open={selectedRow >= 0} className={"modal"}>
          <div className="card" style={{ padding: "40px" }}>
            <div style={{ marginBottom: 40, fontSize: 16 }}>
              Are you sure to delete?
            </div>
            <div className="row">
              <Button color="blueOutlined" onClick={() => setSelectedRow(-1)}>
                Cancel
              </Button>
              <Button
                color="primary"
                style={{ background: "#FF3296" }}
                onClick={async () => {
                  removeQuestionData(selectedRow);
                  setSelectedRow(-1);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal>
        <Prompt
          message="Are you sure you want to leave?"
          when={shouldBlockNavigation}
        />

        <SideMenu content={renderDrawerContent()} />
        <SideMenu content={renderToolBar()} anchor="right" />
        <RewardPopover
          onClose={handleCloseRewardPopover}
          onAnchorEl={rewardAnchorEl}
          setFormData={setFormData}
          formData={formData}
          errors={errors}
          checkAgain={checkAgain}
          onRenderRestrictionPage={renderRestrictionPage}
          onRenderRewardPage={renderRewardPage}
        />
        <Modal
          open={showRestricitons}
          className={"modal"}
          onClose={() => setShowRestricitons(false)}
        >
          {renderRestrictionPage(false)}
        </Modal>
        <Modal
          open={showRewards}
          className={"modal"}
          onClose={() => setShowRewards(false)}
        >
          {renderRewardPage(false)}
        </Modal>
        <main className={classes.content}>{renderContent()}</main>
      </div>
    </div>
  );
}
