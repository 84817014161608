import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
const useStyles = makeStyles((theme) => ({}));
const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
    "&:before": {
      border: "1px solid #555555",
    },
  },

  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#383538",
    border: "1px solid #555555",
    fontSize: 12,
    textAlign: "center",
    fontWeight: 500,
    maxWidth: 140,
  },
}))(Tooltip);
const Tag = ({ label, color = "#FF3296", tooltip = false, desc = "" }) => {
  const classes = useStyles();
  if (tooltip) {
    return (
      <LightTooltip
        title={desc}
        arrow
        PopperProps={{ style: { marginTop: -13 } }}
      >
        <span
          className="startRow"
          style={{
            background: color,
            width: 140,
            borderRadius: 3,

            margin: "5px",
            marginLeft: 0,
            border: "1.3px solid #555555",
            boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",
          }}
        >
          <span
            style={{
              textAlign: "center",
              borderRight: "1.3px solid #555555",
              width: 34,
              paddingTop: 3,
              verticalAlign: "middle",
            }}
          >
            <img
              src={require("assets/img/" + tooltip).default}
              style={{ width: 16, height: 16 }}
            />
          </span>

          <div
            style={{
              color: "#FFFFFF",
              textAlign: "center",
              width: "100%",
              textShadow: "1px 1px 0px rgba(0, 0, 0, 0.2)",
              fontWeight: 700,
              fontSize: 13,
            }}
          >
            {label}
          </div>
        </span>
      </LightTooltip>
    );
  } else {
    return (
      <span
        style={{
          fontSize: 11,
          fontWeight: 700,
          color: "#FFFFFF",
          padding: "3px 5px",
          background: color,
          marginRight: 3,
          marginBottom: 3,
          borderRadius: 3,
        }}
      >
        {label}
      </span>
    );
  }
};

export default Tag;
