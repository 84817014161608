import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { chainList } from "features/configure";
const useStyles = makeStyles((theme) => ({
  headerBg: {
    background: "rgba(255, 255, 255, 0.25)",
    backdropFilter: "blur(6.23961px)",
    padding: 10,
  },
}));

const Restriction = ({ restrictions, notPass, checked, restrictionsLogic }) => {
  const classes = useStyles();
  return (
    <div>
      {restrictions && restrictions.length > 0 && (
        <div style={{ fontSize: 17, fontWeight: 700 }} className="startRow">
          <img
            src={require("assets/img/lock.svg").default}
            className="smIcon"
          />
          Restrictions:
        </div>
      )}
      {restrictions &&
        restrictions.map((restriction, index) => {
          if (restriction.tokenType == "POAP")
            return (
              <div style={{ textAlign: "left" }} key={index}>
                Your wallet need <span className="highlight">POAP</span> event
                ID <span className="highlight">{restriction.eventId}</span> to
                fill this from.
              </div>
            );
          const chainId = _.get(restriction, "chainId");
          const chainLabel = _.get(
            _.find(chainList, { value: chainId }),
            "label",
            ""
          );
          return (
            <div style={{ textAlign: "left" }} key={index}>
              {restriction && restriction.requiredBalance && (
                <div
                  style={{
                    margin: "10px 0",
                  }}
                  className={classes.headerBg}
                >
                  {notPass && (
                    <div
                      style={{
                        textAlign: "right",
                        color: "#FF3CA0",
                        fontWeight: 700,
                        fontSize: 12,
                        padding: 5,
                      }}
                    >
                      Your wallet need{" "}
                      <img
                        src={_.get(
                          restriction,
                          "logoURI",
                          require("assets/img/spot.png").default
                        )}
                        style={{
                          verticalAlign: "top",
                          borderRadius: 30,
                          height: 18,
                        }}
                      />
                      <span className="highlight">
                        {restriction.requiredBalance} {restriction.symbol}
                      </span>{" "}
                      to fill this from.
                    </div>
                  )}
                  <div
                    style={{
                      border: notPass ? "1px solid #FF3CA0" : "",
                      padding: "5px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 13,
                        fontWeight: 700,
                        lineHeight: 1.5,
                      }}
                    >
                      Chain:<span className="highlight">{chainLabel}</span>{" "}
                      Token:{" "}
                      <img
                        src={_.get(
                          restriction,
                          "logoURI",
                          require("assets/img/spot.png").default
                        )}
                        className="smIcon"
                        style={{
                          verticalAlign: "top",
                          borderRadius: 30,
                        }}
                      />
                      <span className="highlight">
                        {restriction.symbol || restriction.address}
                      </span>
                      <img
                        src={require("assets/img/greaterThan.svg").default}
                        style={{ verticalAlign: "top", margin: "0 5px" }}
                      />
                      QTY:{" "}
                      <span className="highlight">
                        {restriction.requiredBalance} {restriction.symbol}
                      </span>{" "}
                      can fill this form.
                      {checked && (
                        <img
                          src={require("assets/img/check.svg").default}
                          className="smIcon"
                        />
                      )}
                      {/* {previewData && " Preview will always pass! "} */}
                    </div>
                  </div>
                </div>
              )}
              {index < restrictions.length - 1 ? (
                <span
                  className="highlight"
                  style={{ display: "block", textAlign: "center" }}
                >
                  {restrictionsLogic}
                </span>
              ) : (
                ""
              )}
            </div>
          );
        })}
    </div>
  );
};

export default Restriction;
