import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Popover,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import { Navigate, useParams } from "react-router-dom";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Loading } from "features/common";
import { fetchBalance, readContract } from "@wagmi/core";
import {
  useFetchFormData,
  useUpdateReward,
  useFetchCreatorRawResponseData,
} from "features/form/redux/hooks";
import { useConnectWallet } from "features/home/redux/hooks";
import SelectToken from "./components/SelectToken";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";

import _ from "lodash";
import {
  chainList,
  contracts,
  erc20ABI,
  nativeAddress,
} from "features/configure";
import { ethers } from "ethers";
import Web3 from "web3";
import { useAccount, useNetwork, useSwitchNetwork, erc721ABI } from "wagmi";
import {
  convertAmountFromRawNumber,
  convertAmountToNumber,
} from "features/helpers/bignumber";
import {
  useFetchCreatorResponseData,
  useFetchApproval,
  useFetchNFTApproval,
  useFetchDistribute,
  useFetchDistributeNFT,
} from "features/form/redux/hooks";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import moment from "moment";
import { initializeAlchemy, getNftsForOwner } from "@alch/alchemy-sdk";
import Restriction from "./components/Restriction";
import RewardField from "./components/RewardField";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
  },
  popoverPaper: {
    padding: 15,
    maxWidth: 400,
    fontWeight: 700,
    fontSize: 14,
    border: "1px solid #A5A5A5",
    borderRadius: 10,
  },
}));

const Reward = ({ tabId }) => {
  const { formId } = useParams();
  const classes = useStyles();
  const { data } = useFetchFormData();
  const [rewardData, setRewardData] = useState();
  const [filterAddress, setFilterAddress] = useState([]);
  // const [selectedfilterAddress, setSelectedFilterAddress] = useState([]);
  const [excludeFilterAddress, setExcludeFilterAddress] = useState([]);

  const { updateReward, updateRewardPending, updatedReward } =
    useUpdateReward();
  const { auth, checkAuth } = useConnectWallet();
  const navigate = useNavigate();
  const { chain } = useNetwork();
  const { fetchApproval, fetchApprovalPending } = useFetchApproval();
  const { fetchNFTApproval, fetchNFTApprovalPending } = useFetchNFTApproval();
  const { fetchDistribute, fetchDistributePending } = useFetchDistribute();
  const { fetchDistributeNFT } = useFetchDistributeNFT();
  const { creatorResponseData } = useFetchCreatorResponseData();
  const {
    creatorRawResponseData,
    fetchCreatorRawResponseData,
    fetchCreatorRawResponseDataPending,
  } = useFetchCreatorRawResponseData();

  const { address, connector } = useAccount({});
  const [rewardAllowance, setRewardAllowance] = useState(0);
  const [rewardTokenBalance, setRewardTokenBalance] = useState(0);
  const [needSave, setNeedSave] = useState(false);
  const [rewardNFTApproved, setRewardNFTApproved] = useState(false);
  const [rewardNFTIds, setRewardNFTIds] = useState([]);

  const [openDistribute, setOpenDistribute] = useState(false);
  const [selectedTab, setSelectedTab] = useState(parseInt(tabId) || 0);
  const [errors, setErrors] = useState({});
  const [openRewardTokenSelect, setOpenRewardTokenSelect] = useState(false);
  const [allAddressData, setAllAddressData] = useState([]);

  const reward = _.get(data, `[${formId}].reward`, {});
  const restrictions = _.get(data, `[${formId}].restrictions`, []);

  const questions = _.get(data, `[${formId}].questions`, []);
  const selectedResponsesId = _.get(rewardData, "selectedResponsesId", []);
  const { switchNetwork } = useSwitchNetwork();

  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredEl, setHoveredEl] = useState(null);
  useEffect(() => {
    fetchCreatorRawResponseData({
      formId,
      page: 0,
      pageSize: 1000,
    });
  }, [formId]);

  useEffect(() => {
    const rData = _.get(
      creatorRawResponseData,
      `[${formId}].responses[0].data`,
      []
    );
    if (!rData) return;
    setAllAddressData(
      _.reduce(
        _.groupBy(rData, "address"),
        (result, d, address) => {
          let row = d[0];
          const responseInfo = _.get(row, "responseInfo");

          result.push({
            address: row.address,
            addressFirstTransactionDate: _.get(
              responseInfo,
              "addressFirstTransactionDate"
            )
              ? moment(
                  _.get(responseInfo, "addressFirstTransactionDate")
                ).format("YYYY/MM/DD HH:mm:ss")
              : "N/A",
            addressLatestTransactionDate: _.get(
              responseInfo,
              "addressLatestTransactionDate"
            )
              ? moment(
                  _.get(responseInfo, "addressLatestTransactionDate")
                ).format("YYYY/MM/DD HH:mm:ss")
              : "N/A",
            nonce: _.get(responseInfo, "addressNonce"),
            date: _.get(responseInfo, "created")
              ? moment(_.get(responseInfo, "created")).format(
                  "YYYY/MM/DD HH:mm:ss"
                )
              : "N/A",
            ...row,
          });
          return result;
        },
        []
      )
    );
  }, [
    formId,
    creatorRawResponseData,
    rewardData,
    fetchCreatorRawResponseDataPending,
  ]);

  useEffect(() => {
    resetFilter();
  }, [allAddressData]);

  const handlePopoverOpen = (event, identifier) => {
    setAnchorEl(event.currentTarget);
    setHoveredEl(identifier);
  };

  const handlePopoverClose = (event, identifier) => {
    setAnchorEl(null);
    setHoveredEl(null);
  };

  const checkChain = () => {
    if (_.get(chain, "id") !== _.get(rewardData, "chainId")) {
      switchNetwork?.(rewardData.chainId);
      return false;
    }
    return true;
  };

  const alchemyNetworkMapping = {
    1: "eth-mainnet",
    137: "polygon-mainnet",
  };

  const fetchUserTokenInfo = async () => {
    if (
      rewardData &&
      rewardData.tokenType == "ERC20" &&
      openDistribute &&
      address &&
      nativeAddress !== rewardData.address
    ) {
      try {
        const allowanceData = await readContract({
          address: rewardData.address,
          abi: erc20ABI,
          functionName: "allowance",
          args: [address, contracts.distribute[rewardData.chainId]],
        });
        let tokenBalance = _.get(await fetchBalance({ address }), "value", 0);

        let decimals = 18;
        if (rewardData.address !== nativeAddress) {
          const balance = await fetchBalance({
            address,
            token: rewardData.address,
          });
          tokenBalance = balance.value;
          decimals = balance.decimals;
        }
        let allowance = convertAmountFromRawNumber(allowanceData, decimals);
        let balance = convertAmountFromRawNumber(tokenBalance, decimals);

        setRewardTokenBalance(parseFloat(balance));
        setRewardAllowance(parseFloat(allowance));
      } catch (err) {
        // console.log(err);
      }
    } else if (
      rewardData &&
      openDistribute &&
      address &&
      nativeAddress == rewardData.address
    ) {
      setRewardTokenBalance(
        parseFloat((await fetchBalance({ address })).formatted)
      );
    } else if (
      rewardData &&
      rewardData.tokenType == "ERC721" &&
      openDistribute &&
      address
    ) {
      const settings = {
        apiKey: "TugKnx44-9srf-2Ldp0DhEpUA1tdsLpd", // Replace with your Alchemy API Key.
        maxRetries: 10,
        network: alchemyNetworkMapping[rewardData.chainId],
      };

      const alchemy = initializeAlchemy(settings);

      try {
        const isApprovedData = await readContract({
          address: rewardData.address,
          abi: erc721ABI,
          functionName: "isApprovedForAll",
          args: [address, contracts.distributeNFT[rewardData.chainId]],
        });

        setRewardNFTApproved(isApprovedData);

        let balance = parseFloat(
          _.get(
            await fetchBalance({ address, token: rewardData.address }),
            "formatted",
            0
          )
        );
        let mappedIds = [];

        if (balance > 0) {
          let ids = await getNftsForOwner(alchemy, address, {
            contractAddresses: [rewardData.address],
          });
          mappedIds = _.map(ids.ownedNfts, (id) => {
            return id.tokenId;
          });
        }

        setRewardNFTIds(mappedIds);
      } catch (err) {
        // console.log(err);
      }
    }
  };

  useEffect(() => {
    if (openDistribute) {
      fetchUserTokenInfo();
      const id = setInterval(() => {
        fetchUserTokenInfo();
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address, formId, openDistribute, rewardData]);

  useEffect(() => {
    setSelectedTab(parseInt(tabId) || 0);
    setOpenDistribute(false);
  }, [tabId]);

  useEffect(() => {
    if (_.get(reward, "hasReward") && creatorResponseData) {
      setRewardData(reward);
      resetFilter(
        _.get(reward, "distributionCriteria", []),
        _.get(reward, "distributionRestritions", {})
      );
    }
  }, [data, creatorResponseData]);

  const saveUpdate = async () => {
    await updateReward({ formId, reward: rewardData, address });
  };

  const renderRewardStats = () => {
    return (
      <Grid
        container
        style={{ padding: 10, borderBottom: "1px solid rgba(56, 53, 56, 0.5)" }}
      >
        <Grid item xs={4}>
          <div className="cardTitle">Total Rewards</div>
          <div className="cardValue">
            {parseFloat(
              parseFloat(_.get(rewardData, "rewardAmount", 0)) *
                parseFloat(_.get(rewardData, "rewardLimit", 0)).toFixed(2)
            )}{" "}
            {_.get(rewardData, "symbol", "")}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="cardTitle"># of Receivers</div>
          <div className="cardValue">
            {parseFloat(_.get(rewardData, "rewardLimit", 0))}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="cardTitle">Rewards per Receiver</div>
          <div className="cardValue">
            {parseFloat(_.get(rewardData, "rewardAmount", 0))}{" "}
            {_.get(rewardData, "symbol", "")}
          </div>
        </Grid>
      </Grid>
    );
  };

  const checkAgain = () => {
    if (Object.keys(errors).length > 0) {
      validate();
    }
  };
  const validate = () => {
    let errors = {};

    if (
      !rewardData ||
      !rewardData.tokenType ||
      !rewardData.address ||
      !rewardData.rewardAmount ||
      !rewardData.rewardLimit
    ) {
      if (!errors["reward"]) errors["reward"] = "";
      errors["reward"] += "Please select reward chain and token.\n";
    } else if (
      rewardData.address &&
      !Web3.utils.isAddress(rewardData.address)
    ) {
      if (!errors["reward"]) errors["reward"] = "";
      errors["reward"] += "rewardToken address is invalid!\n";
    } else if (
      rewardData.rewardAmount &&
      (isNaN(parseFloat(rewardData.rewardAmount)) ||
        parseFloat(rewardData.rewardAmount) < 0)
    ) {
      if (!errors["reward"]) errors["reward"] = "";
      errors["reward"] += "Reward required balance is invalid!\n";
    }

    setErrors(errors);

    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  const setInputData = (label, value) => {
    let newData = { ...rewardData };
    if (!label) {
      newData = { ...newData, ...value };
    } else {
      _.set(newData, label, value);
    }

    setRewardData(newData);
  };

  const resetFilter = (inputData, inputCondition, cleanSelected) => {
    const r = inputData || _.get(rewardData, "distributionCriteria", []);
    const condition =
      inputCondition || _.get(rewardData, "distributionRestritions", {});
    let addresses = new Set();

    const requiredTxnCount = parseInt(_.get(condition, "requiredTxnCount", 0));
    const requiredDays = parseInt(_.get(condition, "requiredDays", 0));
    if (r.length > 0 || requiredTxnCount > 0 || requiredDays > 0) {
      let now = moment();
      for (let addressData of allAddressData) {
        let pass = 0;

        if (
          requiredDays > 0 &&
          (addressData.addressFirstTransactionDate == "N/A" ||
            now.diff(moment(addressData.addressFirstTransactionDate), "days") <
              parseInt(requiredDays))
        ) {
          continue;
        }
        if (
          requiredTxnCount > 0 &&
          (!addressData.nonce || parseInt(addressData.nonce) < requiredTxnCount)
        ) {
          continue;
        }
        for (let criteria of r) {
          let question = criteria.question;
          let response = criteria.response;
          if (!question || !response) continue;

          let userResponse = _.find(addressData.responses, {
            question: question,
          });

          if (!userResponse) break;
          if (userResponse.response == response) {
            pass += 1;
          } else if (
            Array.isArray(userResponse.response) &&
            userResponse.response.includes(response)
          ) {
            pass += 1;
          } else {
            break;
          }
        }

        if (pass == r.length) addresses.add(addressData);
      }
    }
    const newData = [...addresses];

    setFilterAddress(newData);

    setExcludeFilterAddress(_.xor(allAddressData, newData));

    if (cleanSelected) {
      setInputData("", { ...cleanSelected, selectedResponsesId: [] });
      setNeedSave(true);
    }
  };
  const renderSelectToken = (open, text, logo) => {
    return (
      <div
        className="betweenRow"
        style={{
          background: "white",
          borderRadius: 5,
          border: "1px solid rgb(165, 165, 165)",
          padding: 1,
          margin: 5,
          width: "81%",
          padding: "9px 15px",
          fontSize: 13,
          fontWeight: 700,
          cursor: "pointer",
        }}
        onClick={open}
      >
        <div className="startRow">
          <span>
            <img
              src={logo}
              className="smIcon"
              style={{ borderRadius: 30 }}
              onError={(e) => {
                e.target.src = require("assets/img/spot.png").default;
              }}
            />
          </span>
          <span>{text}</span>
        </div>
        <ArrowDropDownIcon style={{ verticalAlign: "middle" }} />
      </div>
    );
  };

  const renderAddressList = (data, selectedList, selectFunc) => {
    const ids = selectedList;
    const q1Id = _.get(
      _.find(_.get(creatorResponseData, `[${formId}].questions`), {
        index: 0,
      }),
      "_id",
      ""
    );
    return data.map((addressData, i) => {
      const addrs = _.get(addressData, "address", addressData);
      const selected = ids.includes(addressData.address);
      return (
        <div
          key={i}
          className={selectFunc ? "aroundRow" : "startRow"}
          onClick={() => {
            let newData = [];
            if (ids.length > 0) {
              newData = [...ids];
            }
            let dataIndex = _.indexOf(newData, addressData.address);
            if (dataIndex >= 0) {
              _.pullAt(newData, [dataIndex]);
            } else {
              newData.push(addressData.address);
            }
            selectFunc(newData);
          }}
          style={{
            fontWeight: 500,
            borderTop: "1px solid rgba(92, 92, 92, 0.5)",
            cursor: selectFunc ? "pointer" : "",
            background: selected ? "rgba(0, 150, 255, 0.15)" : "white",
          }}
        >
          {selectFunc && (
            <Checkbox
              checked={selected}
              style={{ padding: 3, color: "#0096FF" }}
            />
          )}
          {formId == "63a036fa9be070c8b5909781" ? addressData[q1Id] : ""}
          {
            <div style={{ width: 200, padding: 5 }}>
              {selectFunc
                ? `${addrs.slice(0, 6)}............${addrs.slice(-6)}`
                : addrs}
            </div>
          }
        </div>
      );
    });
  };

  const renderDistributeButton = () => {
    if (reward && reward.tokenType == "ERC20") {
      if (
        _.get(rewardData, "address", "") == nativeAddress ||
        rewardAllowance >= total
      ) {
        return (
          <Button
            color="primary"
            disabled={fetchDistributePending || total > rewardTokenBalance}
            onClick={async () => {
              if (checkChain()) {
                let data = {
                  contractAddress: contracts.distribute[rewardData.chainId],
                  formId,
                  address,
                  addresses: selectedResponsesId,
                  value: convertAmountToNumber(
                    _.get(rewardData, "rewardAmount", 0),
                    rewardData.decimals || 18
                  ),
                };

                if (rewardData.address == nativeAddress) {
                  data.totalRewardAmount = ethers.utils.parseEther(
                    total.toString()
                  );
                } else {
                  data.tokenAddress = rewardData.address;
                }
                await fetchDistribute(data);
              }
            }}
          >
            {total > rewardTokenBalance ? "Not Enough" : "Distribute"}
          </Button>
        );
      } else {
        return (
          <Button
            color="primary"
            disabled={fetchApprovalPending}
            onClick={async () => {
              if (checkChain()) {
                await fetchApproval({
                  contractAddress: contracts.distribute[rewardData.chainId],
                  tokenAddress: rewardData.address,
                  amount: convertAmountToNumber(
                    total.toString(),
                    rewardData.decimals || 18
                  ),
                });
              }
            }}
          >
            Approve
          </Button>
        );
      }
    } else if (reward && reward.tokenType == "ERC721") {
      if (rewardNFTApproved) {
        return (
          <Button
            color="primary"
            disabled={fetchDistributePending || total > rewardNFTIds.length}
            onClick={async () => {
              if (checkChain()) {
                let data = {
                  contractAddress: contracts.distributeNFT[rewardData.chainId],
                  formId,
                  address,
                  addresses: selectedResponsesId,
                  ids: _.dropRight(rewardNFTIds, rewardNFTIds.length - total),
                  tokenAddress: rewardData.address,
                };
                await fetchDistributeNFT(data);
              }
            }}
          >
            {total > rewardNFTIds.length ? "Not Enough" : "Distribute"}
          </Button>
        );
      } else {
        return (
          <Button
            color="primary"
            disabled={fetchNFTApprovalPending}
            onClick={async () => {
              if (checkChain()) {
                await fetchNFTApproval({
                  contractAddress: contracts.distributeNFT[rewardData.chainId],
                  tokenAddress: rewardData.address,
                });
              }
            }}
          >
            Approve
          </Button>
        );
      }
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ delimiter: ";" }} />
      </GridToolbarContainer>
    );
  };

  const renderDistributeSetting = () => {
    const seletedLength = selectedResponsesId.length;

    const excludeSelcted = _.intersection(
      _.map(excludeFilterAddress, "address"),
      selectedResponsesId
    );

    const filterSelcted = _.intersection(
      _.map(filterAddress, "address"),
      selectedResponsesId
    );
    const hasDistributionCriteria = _.get(
      rewardData,
      "hasDistributionCriteria"
    );
    const distributionCriteria = _.get(rewardData, "distributionCriteria", []);
    return (
      <div className="card">
        <Grid container>
          <Grid item xs={12} sm={12}>
            {renderRewardStats()}
          </Grid>

          <Grid item xs={12} sm={4}>
            <div className="card">
              <div className="cardValue betweenRow">
                All Addresses{" "}
                <div>
                  <Button
                    color="formPrimary"
                    onClick={() => {
                      const diffAmount =
                        parseInt(_.get(reward, "rewardLimit", 0)) -
                        filterSelcted.length;
                      if (diffAmount <= 0) return;
                      setInputData(`selectedResponsesId`, [
                        ...filterSelcted,
                        ..._.sampleSize(
                          _.map(excludeFilterAddress, "address"),
                          diffAmount
                        ),
                      ]);
                      setNeedSave(true);
                    }}
                    onMouseEnter={(e) => handlePopoverOpen(e, "random")}
                    onMouseLeave={(e) => handlePopoverClose(e, "random")}
                  >
                    Random{" "}
                    <img
                      src={require("assets/img/dice.svg").default}
                      className="smIcon"
                      style={{ marginLeft: 5 }}
                    />
                  </Button>
                  <Popover
                    style={{ pointerEvents: "none" }}
                    classes={{
                      paper: classes.popoverPaper,
                    }}
                    open={hoveredEl === "random"}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClose={(e) => handlePopoverClose(e, "random")}
                    disableRestoreFocus
                  >
                    <div>Randomly select addresses in this section</div>
                  </Popover>
                </div>
              </div>
              <div style={{ marginTop: 10 }}>
                <div
                  className="betweenRow"
                  style={{ fontWeight: 700, fontSize: 13, padding: "5px 0" }}
                >
                  <span style={{ color: "#0096FF" }}>
                    {excludeSelcted.length} selected
                  </span>
                  <span># of addresses: {excludeFilterAddress.length}</span>
                </div>

                <div style={{ height: 400, margin: 0, overflow: "auto" }}>
                  <div>
                    {renderAddressList(
                      excludeFilterAddress,
                      selectedResponsesId,
                      (newData) => {
                        setInputData(`selectedResponsesId`, newData);
                        setNeedSave(true);
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={4}>
            <div className="card">
              <div className="cardValue betweenRow">
                <div
                  onMouseEnter={(e) =>
                    handlePopoverOpen(e, "filteredAddresses")
                  }
                  onMouseLeave={(e) =>
                    handlePopoverClose(e, "filteredAddresses")
                  }
                >
                  Filtered Addresses{" "}
                </div>
                <Popover
                  style={{ pointerEvents: "none" }}
                  classes={{
                    paper: classes.popoverPaper,
                  }}
                  open={hoveredEl === "filteredAddresses"}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  onClose={(e) => handlePopoverClose(e, "filteredAddresses")}
                  disableRestoreFocus
                >
                  <div>
                    The addresses are the output of the filtered distribution
                  </div>
                </Popover>
                <div>
                  <Button
                    color="formPrimary"
                    onClick={() => {
                      const diffAmount =
                        parseInt(_.get(reward, "rewardLimit", 0)) -
                        excludeSelcted.length;
                      if (diffAmount <= 0) return;
                      setInputData(`selectedResponsesId`, [
                        ...excludeSelcted,
                        ..._.sampleSize(
                          _.map(filterAddress, "address"),
                          diffAmount
                        ),
                      ]);
                      setNeedSave(true);
                    }}
                    onMouseEnter={(e) => handlePopoverOpen(e, "random2")}
                    onMouseLeave={(e) => handlePopoverClose(e, "random2")}
                  >
                    Random{" "}
                    <img
                      src={require("assets/img/dice.svg").default}
                      className="smIcon"
                      style={{ marginLeft: 5 }}
                    />
                  </Button>
                  <Popover
                    style={{ pointerEvents: "none" }}
                    classes={{
                      paper: classes.popoverPaper,
                    }}
                    open={hoveredEl === "random2"}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClose={(e) => handlePopoverClose(e, "random2")}
                    disableRestoreFocus
                  >
                    <div>Randomly select addresses in this section</div>
                  </Popover>
                </div>
              </div>

              <div style={{ marginTop: 10 }}>
                <div
                  className="betweenRow"
                  style={{ fontWeight: 700, fontSize: 13, padding: "5px 0" }}
                >
                  <span style={{ color: "#0096FF" }}>
                    {filterSelcted.length} selected
                  </span>
                  <span># of addresses: {filterAddress.length}</span>
                </div>

                <div style={{ height: 400, margin: 0, overflow: "auto" }}>
                  <div>
                    {renderAddressList(
                      filterAddress,
                      selectedResponsesId,
                      (newData) => {
                        setInputData(`selectedResponsesId`, newData);
                        setNeedSave(true);
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={4}>
            <div className="card">
              <div className="betweenRow cardValue">
                <div className="row">
                  <img
                    src={require("assets/img/filter.svg").default}
                    className="smIcon"
                  />
                  Filter Distribution
                </div>
                <div>
                  <CustomSwitch
                    checked={hasDistributionCriteria}
                    onChange={async (e) => {
                      const checked = e.target.checked;
                      let newData = { ...rewardData };
                      if (!checked) {
                        newData.distributionRestritions = {};
                        newData.distributionCriteria = [];
                        newData.selectedResponsesId = [];
                      }
                      resetFilter([], {}, { hasDistributionCriteria: checked });
                    }}
                  />
                </div>
              </div>
              {hasDistributionCriteria && (
                <>
                  <div
                    className="cardTitle"
                    style={{
                      borderBottom: "1px solid rgba(56, 53, 56, 0.5)",
                      padding: "10px 0",
                    }}
                  >
                    Filter Based on Wallet Info
                  </div>
                  <Grid container alignItems="center" style={{ marginTop: 5 }}>
                    <Grid item xs={12} sm={6}>
                      <div className="cardDesc"># of transactions</div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="cardDesc">
                        # of days since address creation
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="cardTab" style={{ marginRight: 5 }}>
                        <CustomOutlinedInput
                          number
                          value={_.get(
                            rewardData,
                            "distributionRestritions.requiredTxnCount",
                            ""
                          )}
                          placeholder="0"
                          onChange={(e) => {
                            let r = _.get(
                              rewardData,
                              "distributionRestritions",
                              {}
                            );
                            r.requiredTxnCount = e.target.value;
                            resetFilter(undefined, r, {
                              distributionRestritions: r,
                            });
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="cardTab">
                        {" "}
                        <CustomOutlinedInput
                          number
                          value={_.get(
                            rewardData,
                            "distributionRestritions.requiredDays",
                            ""
                          )}
                          placeholder="0"
                          onChange={(e) => {
                            let r = _.get(
                              rewardData,
                              "distributionRestritions",
                              {}
                            );
                            r.requiredDays = e.target.value;
                            resetFilter(undefined, r, {
                              distributionRestritions: r,
                            });
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <div>
                    <IconButton
                      style={{ padding: "5px" }}
                      onClick={async () => {
                        setInputData(`distributionRestritions`, {});
                        resetFilter(
                          undefined,
                          {},
                          { distributionRestritions: {} }
                        );
                      }}
                    >
                      <HighlightOffIcon
                        style={{
                          color: "#A5A5A5",
                          fontSize: 13,
                        }}
                      />
                    </IconButton>
                  </div>
                  <div
                    className="cardTitle"
                    style={{
                      borderBottom: "1px solid rgba(56, 53, 56, 0.5)",
                      padding: "10px 0",
                    }}
                  >
                    Filter Based on Answers to Assigned Questions
                  </div>
                  {distributionCriteria.map((d, i) => {
                    return (
                      <Grid
                        container
                        key={i}
                        alignItems="center"
                        style={{ marginTop: 5 }}
                      >
                        <Grid item xs={11}>
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <div className="cardDesc">Select Question</div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <div className="cardDesc">Select Answer</div>
                            </Grid>
                          </Grid>
                          <div
                            className="cardTab"
                            style={{ background: "rgba(0, 150, 255, 0.15)" }}
                          >
                            <Grid container>
                              <Grid item xs={12} sm={6}>
                                <CustomDropdown
                                  white
                                  placeholder={"Question"}
                                  data={_.filter(questions, {
                                    type: "singleSelect",
                                  }).map((q, qi) => {
                                    if (q.type !== "singleSelect") return; //TODO: select first
                                    return {
                                      label: (
                                        <span>
                                          <img
                                            src={
                                              require(`assets/img/${q.type}.svg`)
                                                .default
                                            }
                                            style={{
                                              width: 18,
                                              height: 18,
                                              marginRight: 5,
                                              verticalAlign: "middle",
                                            }}
                                          />
                                          {`${qi + 1}. ${q.title}`}
                                        </span>
                                      ),
                                      value: q._id,
                                    };
                                  })}
                                  value={d.question}
                                  fullWidth
                                  onSelect={(e) => {
                                    const newValue = e.target.value;
                                    setInputData(
                                      `distributionCriteria[${i}].question`,
                                      newValue
                                    );
                                    resetFilter(undefined, undefined, {});
                                  }}
                                ></CustomDropdown>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <CustomDropdown
                                  white
                                  placeholder={"Option"}
                                  data={_.get(
                                    _.find(questions, { _id: d.question }),
                                    `options`,
                                    []
                                  ).map((option, oi) => {
                                    return {
                                      label: option.title,
                                      value: option.title,
                                    };
                                  })}
                                  value={d.response}
                                  fullWidth
                                  onSelect={(e) => {
                                    const newValue = e.target.value;
                                    setInputData(
                                      `distributionCriteria[${i}].response`,
                                      newValue
                                    );
                                    resetFilter(undefined, undefined, {});
                                  }}
                                ></CustomDropdown>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            style={{ padding: "1px", paddingTop: 15 }}
                            onClick={async () => {
                              distributionCriteria.splice(i, 1);

                              resetFilter(distributionCriteria, undefined, {
                                distributionCriteria: distributionCriteria,
                              });
                            }}
                          >
                            <HighlightOffIcon
                              style={{
                                color: "#A5A5A5",
                                fontSize: 13,
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <div style={{ marginTop: 10 }}>
                    <Button
                      color="form"
                      onClick={() => {
                        distributionCriteria.push({});
                        setInputData(
                          `distributionCriteria`,
                          distributionCriteria
                        );
                      }}
                    >
                      +Add New
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={12}>
            <div className="card">
              <div>
                <div
                  className="cardValue betweenRow"
                  style={{ marginBottom: 20 }}
                  onMouseEnter={(e) =>
                    handlePopoverOpen(e, "totalSelectedAddresses")
                  }
                  onMouseLeave={(e) =>
                    handlePopoverClose(e, "totalSelectedAddresses")
                  }
                >
                  Total Selected Addresses{" "}
                </div>
                <Popover
                  style={{ pointerEvents: "none" }}
                  classes={{
                    paper: classes.popoverPaper,
                  }}
                  open={hoveredEl === "totalSelectedAddresses"}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  onClose={(e) =>
                    handlePopoverClose(e, "totalSelectedAddresses")
                  }
                  disableRestoreFocus
                >
                  <div>
                    Total Selected Addresses = Selection from Filtered Addresses
                    + Remaining Addresses
                  </div>
                </Popover>
              </div>

              <div
                style={{ color: "#0096FF", fontWeight: 700, padding: "5px 0" }}
              >
                {seletedLength} out of{" "}
                {parseFloat(_.get(rewardData, "rewardLimit", 0))} required
                addresses
              </div>
              {renderAddressList(selectedResponsesId, selectedResponsesId)}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };
  const total =
    parseFloat(_.get(rewardData, "rewardAmount", 0)) *
    parseFloat(selectedResponsesId.length);

  const renderDistributeReward = () => {
    return (
      <div style={{}}>
        <div
          style={{ fontWeight: 900, fontSize: 25, marginBottom: 36 }}
          className="startRow"
        >
          <img
            className="icon"
            src={require("assets/img/approve.svg").default}
          />{" "}
          Distribute
        </div>
        <Grid container>
          <Grid item xs={12} sm={7}>
            <div className="card">
              {renderRewardStats()}
              <Restriction restrictions={restrictions} />
              <RewardField reward={reward} />
            </div>
            <div className="card">
              <div
                className="cardValue betweenRow"
                style={{ marginBottom: 20 }}
              >
                Total Selected Addresses{" "}
              </div>
              <div
                style={{ color: "#0096FF", fontWeight: 700, padding: "5px 0" }}
              >
                {selectedResponsesId.length} selected address
              </div>
              {renderAddressList(selectedResponsesId, selectedResponsesId)}
            </div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div className="card" style={{ margin: 10 }}>
              <div
                style={{
                  fontWeight: 700,
                  fontSize: 22,
                  padding: "20px 0",
                  borderBottom: "1px solid rgba(56, 56, 56, 0.5)",
                }}
              >
                Required Amount: {total} {_.get(rewardData, "symbol")}
              </div>

              <h4>
                Chain:{" "}
                {_.get(
                  _.find(chainList, ["value", _.get(rewardData, "chainId")]),
                  "label"
                )}
              </h4>
              <h4>Token: {_.get(rewardData, "symbol")}</h4>

              <div style={{ padding: 30 }}></div>
              <div style={{ textAlign: "center" }}>
                {renderDistributeButton()}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderRewardSetting = () => {
    return (
      <div>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div className="card" style={{ minHeight: 290 }}>
              {renderRewardStats()}
              <div style={{ margin: 10, marginBottom: 0 }}>
                <img src={require("assets/img/userPreview.svg").default} />
              </div>
              <Restriction restrictions={restrictions} />
              <RewardField reward={reward} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectToken
              open={openRewardTokenSelect}
              chainId={_.get(rewardData, "chainId", "")}
              type={_.get(rewardData, "tokenType", "")}
              setValue={(token) => {
                setRewardData({
                  ...rewardData,
                  ...token,
                });
              }}
              handleClose={() => {
                setOpenRewardTokenSelect(false);
                checkAgain();
              }}
            />
            <div style={{ margin: 10, padding: 15 }} className="card">
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: 900,
                      marginBottom: "20px",
                    }}
                  >
                    Rewards
                  </div>
                </Grid>
                <Grid item xs={12} className="cardDesc">
                  Select Chain
                </Grid>
                <Grid item xs={6}>
                  <CustomDropdown
                    fullWidth
                    white
                    data={chainList}
                    value={_.get(rewardData, "chainId", "")}
                    onSelect={(e) =>
                      setRewardData({
                        // ...rewardData,
                        hasReward: true,
                        tokenType: "ERC20",
                        chainId: e.target.value,
                      })
                    }
                    placeholder="Select Chain"
                  />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 5 }}>
                  <CustomDropdown
                    fullWidth
                    white
                    data={[
                      { label: "ERC-20", value: "ERC20" },
                      { label: "ERC-721", value: "ERC721" },
                    ]}
                    value={_.get(rewardData, "tokenType", "")}
                    onSelect={(e) =>
                      setRewardData({
                        // ...rewardData,
                        hasReward: true,
                        chainId: _.get(rewardData, "chainId"),
                        tokenType: e.target.value,
                      })
                    }
                    placeholder="Select Token Type"
                  />
                </Grid>
                <Grid item xs={12} className="cardDesc">
                  Select Token
                </Grid>
                <Grid item xs={6}>
                  <SelectToken
                    open={openRewardTokenSelect}
                    chainId={_.get(rewardData, "chainId")}
                    type={_.get(rewardData, "tokenType")}
                    setValue={(token) => {
                      setRewardData({
                        ...rewardData,
                        ...token,
                      });
                    }}
                    handleClose={() => {
                      setOpenRewardTokenSelect(false);
                      checkAgain();
                    }}
                  />
                  {renderSelectToken(
                    () => setOpenRewardTokenSelect(true),
                    _.get(
                      rewardData,
                      "symbol",
                      _.get(rewardData, "address", "Select")
                    ),
                    _.get(
                      rewardData,
                      "logoURI",
                      require("assets/img/spot.png").default
                    )
                  )}
                </Grid>{" "}
                <Grid item xs={5}>
                  <CustomOutlinedInput
                    onBlur={() => checkAgain()}
                    number
                    fullWidth
                    value={_.get(rewardData, "rewardAmount", "")}
                    placeholder="0.0"
                    onChange={(e) => {
                      setInputData("rewardAmount", e.target.value);
                      checkAgain();
                    }}
                  />
                </Grid>
                <Grid item xs={12} className="cardDesc">
                  # of Receivers
                </Grid>
                <Grid item xs={8} className="cardTab">
                  <CustomOutlinedInput
                    onBlur={() => checkAgain()}
                    number
                    sm
                    value={_.get(rewardData, "rewardLimit", "")}
                    placeholder="0.0"
                    onChange={(e) => {
                      setInputData("rewardLimit", e.target.value);
                      checkAgain();
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            {errors["reward"] && (
              <div
                style={{
                  background: "rgb(240, 130, 130)",
                  borderRadius: 10,
                  padding: 10,
                  margin: "20px 0",
                  color: "white",
                }}
              >
                {errors["reward"]}
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  const status = _.get(rewardData, "status");

  const renderContent = () => {
    if (openDistribute) return renderDistributeReward();
    if (selectedTab == 0) {
      return renderRewardSetting();
    }
    if (selectedTab == 1) {
      return renderDistributeSetting();
    }

    return <div />;
  };

  // if (!checkAuth)
  //   return (
  //     <div>
  //       <LandingHeader />
  //       <Loading open={true} />
  //     </div>
  //   );
  if (!auth) return <Navigate to="/creator" />;
  if (status == "distributed") {
    if (tabId == 2) {
      return (
        <div>
          <div className="card">
            <div className="cardDesc">
              {_.get(rewardData, "distributedData[0].txHash")}
            </div>
            <div className="cardValue betweenRow" style={{ marginBottom: 20 }}>
              Distributed Addresses{" "}
            </div>
            <div style={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={_.map(
                  _.get(rewardData, "distributedData[0].addresses", []),
                  (addr, i) => {
                    return { address: addr, id: i };
                  }
                )}
                columns={[
                  { field: "address", headerName: "Address", width: "100%" },
                ]}
                disableSelectionOnClick
                getCellClassName={(params) => "tableCell"}
                getRowClassName={(params) => `tableValue`}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div
          style={{
            maxWidth: 800,
            textAlign: "center",
            margin: "0 auto",
            padding: 30,
          }}
          className="card"
        >
          <img
            src={require("assets/img/check.svg").default}
            style={{ width: 76, height: 76 }}
          />
          <div style={{ fontWeight: 700, fontSize: 22 }}>
            Distribution Success!
          </div>
          <Button color="secondary" onClick={() => navigate("/creator")}>
            Back
          </Button>
          <div
            style={{
              padding: "20px 40px",
              borderTop: "1px solid #E6E6E6",
              margin: 10,
            }}
          >
            {renderRewardStats()}
            <Restriction restrictions={restrictions} />
            <RewardField reward={reward} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      {/* <LandingHeader /> */}
      {/* <SideMenu content={renderMenuContent()} /> */}
      <Loading open={updateRewardPending} />
      <main className={classes.content}>
        <div>
          <div style={{ textAlign: "right" }}>
            {!openDistribute && (
              <Button
                color="form"
                style={{ color: "#0096FF" }}
                onClick={async () => {
                  if (validate()) {
                    await saveUpdate();
                    setNeedSave(false);
                    // setSelectedTab(1);
                  }
                }}
              >
                <img
                  className="smIcon"
                  src={require("assets/img/save.svg").default}
                />{" "}
                Save
              </Button>
            )}
            {!openDistribute && selectedTab == 1 && (
              <Button
                color="form"
                disabled={
                  _.get(reward, "status") != "distributionConfigSet" || needSave
                }
                onClick={() => {
                  if (checkChain()) setOpenDistribute(true);
                }}
              >
                <img
                  className="smIcon"
                  src={require("assets/img/approve.svg").default}
                />{" "}
                Distribute
              </Button>
            )}
          </div>

          {renderContent()}
        </div>
      </main>
    </div>
  );
};

export default Reward;
