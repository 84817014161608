import React, { useEffect, useState } from "react";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";

import _ from "lodash";

const TextValidation = ({ questions, index, setValue }) => {
  const setQuestionData = (index, label, value) => {
    let newData = [...questions];
    _.set(newData, `[${index}].${label}`, value);
    setValue(newData);
  };

  const q = questions[index];

  return (
    <div style={{ padding: "0 5px" }}>
      <CustomDropdown
        fullWidth
        data={[
          {
            label: (
              <div className="startRow">
                <img
                  src={require("assets/img/singleText.svg").default}
                  className="smIcon"
                />
                Text
              </div>
            ),
            value: "text",
          },
          {
            label: (
              <div className="startRow">
                <img
                  src={require("assets/img/address.svg").default}
                  className="smIcon"
                />
                Wallet
              </div>
            ),
            value: "wallet",
          },
          {
            label: (
              <div className="startRow">
                <img
                  src={require("assets/img/email.svg").default}
                  className="smIcon"
                />
                Email
              </div>
            ),
            value: "email",
          },
          {
            label: (
              <div className="startRow">
                <img
                  src={require("assets/img/phone.svg").default}
                  className="smIcon"
                />
                Phone
              </div>
            ),
            value: "phone",
          },
        ]}
        value={q.validation}
        onSelect={(e) => setQuestionData(index, "validation", e.target.value)}
      />
    </div>
  );
};

export default TextValidation;
