import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useFetchSurveyList } from "features/form/redux/hooks";
import Button from "components/CustomButtons/Button.js";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { Grid, Toolbar, Divider } from "@material-ui/core";

import { Loading } from "features/common/Loading";
import _ from "lodash";
import { useConnectWallet } from "features/home/redux/hooks";
import moment from "moment";

import LandingHeader from "features/home/components/LandingHeader";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Pagination from "@mui/material/Pagination";
import { Helmet } from "react-helmet";

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: "white",
    border: "1px solid #F0F0F0",
    boxShadow: "0 1px 5px 2px rgba(50, 50, 50, .1)",
  },
  content: {
    flexGrow: 1,
    maxWidth: 1200,
    margin: "0 auto",
    textAlign: "center",
    overflowX: "hidden",
    // ...container,
  },
}));

export default function SurveyFormList({}) {
  const classes = useStyles();
  const {
    fetchSurveyList,
    surveyList,
    completedList,
    totalCount,
    fetchSurveyListDone,
    fetchSurveyListPending,
  } = useFetchSurveyList();
  const { userData, auth } = useConnectWallet();
  const theme = useTheme();
  const [listLoading, setListLoading] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);

  const handlePageChange = (event, value) => {
    setListLoading(true);
    setPage(value);
  };

  const renderMetadata = (
    title = "MetaForm",
    desc = "MetaForm",
    url,
    imageURL
  ) => {
    if (!url) {
      url = window.location.href;
    }
    if (!imageURL) {
      imageURL =
        window.location.origin +
        require("assets/img/defaultBanner.png").default;
    }
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="fragment" content="!" />
        <meta itemprop="name" content={title} />
        <meta itemprop="description" content={desc} />
        <meta itemprop="image" content={imageURL} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={imageURL} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content={imageURL} />
        <meta name="description" content={desc} />
      </Helmet>
    );
  };

  useEffect(() => {
    fetchSurveyList({ address: _.get(userData, "address"), page });
  }, [userData, page, auth]);

  useEffect(() => {
    setListLoading(false);
  }, [surveyList]);

  const completedData = completedList ? completedList : [];
  const listData = surveyList ? surveyList : [];
  return (
    <div>
      {renderMetadata("MetaForm", "", window.location.href, null)}
      <LandingHeader />
      <Toolbar />

      <main className={classes.content}>
        {auth && completedData.length > 0 && (
          <>
            <h3>Completed</h3>
            <Divider />
            <Grid container style={{ marginTop: 30 }}>
              {completedData.map((data, i) => (
                <Grid item xs={12} sm={3} key={i}>
                  <Link to={"/user/" + data._id}>
                    <div
                      style={{
                        padding: 0,
                        margin: 15,
                        textAlign: "left",
                        color: "#383538",
                      }}
                    >
                      <img
                        src={
                          data.header.img ||
                          require("assets/img/defaultBanner.png").default
                        }
                        style={{
                          width: "100%",
                          height: 135,
                          objectFit: "cover",
                          borderRadius: 10,
                        }}
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/defaultBanner.png").default;
                        }}
                      />
                      <div style={{ padding: "5px 5px" }}>
                        <div
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            height: 20,
                          }}
                        >
                          {_.get(data, "header.title")}
                        </div>
                        <div style={{ fontSize: 12 }}>
                          {moment(data.created).format("YYYY/MM/DD HH:mm:ss")}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {!fetchSurveyListDone && fetchSurveyListPending && (
          <Loading open={true} />
        )}
        <h3>Survey List</h3>
        <Divider />
        {
          <Pagination
            count={parseInt(totalCount / 20)}
            color="primary"
            page={page}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
            defaultPage={6}
            boundaryCount={2}
          />
        }
        {listLoading ? (
          <div style={{ textAlign: "center" }}>
            <img
              src={require("assets/img/loading.gif").default}
              className="icon"
            />
          </div>
        ) : (
          <>
            <Grid container style={{ marginTop: 30 }} spacing={3}>
              {listData.map((data, i) => (
                <Grid item xs={12} sm={6} key={i} style={{}}>
                  <div
                    style={{
                      padding: 0,
                      margin: 5,
                      textAlign: "left",
                      color: "#383538",
                      borderRadius: "2px 50px 2px 2px",
                      boxShadow: "2px 2px 3px 1px rgba(0, 0, 0, 0.2)",
                      padding: 10,
                    }}
                  >
                    <div style={{ fontSize: 18, fontWeight: 500 }}>
                      {_.truncate(_.get(data, "header.title"))}
                    </div>
                    <Grid container>
                      <Grid item xs={4}>
                        <img
                          src={
                            data.header.img ||
                            require("assets/img/defaultBanner.png").default
                          }
                          style={{
                            width: "100%",
                            height: 155,
                            borderRadius: 10,
                            marginTop: 5,
                            objectFit: "cover",
                          }}
                          onError={(e) => {
                            e.target.src =
                              require("assets/img/defaultBanner.png").default;
                          }}
                        />
                      </Grid>
                      <Grid item xs={8} style={{ position: "relative" }}>
                        <div style={{ padding: "7px 0px", paddingLeft: 10 }}>
                          <div style={{ fontSize: 12 }}>
                            {_.truncate(_.get(data, "header.desc", ""))}
                            {_.get(data, "restrictions[0]") && (
                              <img
                                className="lgIcon"
                                src={require("assets/img/lock.svg").default}
                                style={{
                                  right: 50,
                                  position: "absolute",
                                }}
                              />
                            )}
                            {_.get(data, "reward") && (
                              <img
                                className="smIcon"
                                src={require("assets/img/gift.svg").default}
                                style={{
                                  right: 15,
                                  position: "absolute",
                                }}
                              />
                            )}
                            <div
                              style={{
                                right: 20,
                                position: "absolute",
                                bottom: 50,
                                textAlign: "right",
                              }}
                            >
                              {data.startDate && (
                                <div>
                                  Start:{" "}
                                  {moment(data.startDate).format(
                                    "YYYY/MM/DD HH:mm:ss"
                                  )}
                                </div>
                              )}
                              {data.endDate && (
                                <div>
                                  End:{" "}
                                  {moment(data.endDate).format(
                                    "YYYY/MM/DD HH:mm:ss"
                                  )}
                                </div>
                              )}
                              {data.responseLimit && (
                                <div>Cap on Survey #: {data.responseLimit}</div>
                              )}
                              {moment(data.created).format(
                                "YYYY/MM/DD HH:mm:ss"
                              )}
                            </div>
                            <div
                              style={{
                                right: 10,
                                position: "absolute",
                                bottom: 10,
                              }}
                            >
                              <Link to={"/user/" + data._id}>
                                <Button
                                  color="form"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "1px solid #777777",
                                    borderRadius: 40,
                                    color: "#383538",
                                    width: 100,
                                    height: 30,
                                  }}
                                >
                                  {"Start"}
                                  <PlayCircleOutlineIcon
                                    style={{
                                      margin: "0 7px",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              ))}
            </Grid>
            {
              <Pagination
                count={parseInt(totalCount / 20)}
                color="primary"
                page={page}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
                defaultPage={6}
                boundaryCount={2}
              />
            }
          </>
        )}

        {!fetchSurveyListDone && fetchSurveyListPending && (
          <Loading open={true} />
        )}
      </main>
    </div>
  );
}
