import React, { useEffect, useState, useRef, useCallback } from "react";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import _ from "lodash";
import ClearIcon from "@material-ui/icons/Clear";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import { IconButton, Modal, Popover, Button } from "@material-ui/core";
import CustomListItem from "components/CustomListItem/CustomListItem";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import {
  StyledPopoverContainer,
  StyledPopoverHeader,
  StyledLineInPopover,
  StyledPopoverInfoArea,
  StyledPopoverButtonArea,
  StyledDiscordRoleList,
  StyledDiscordRoleSingleItem,
  StyledDiscordRoleInputContainer,
  StyledFakeInput,
  StyledAddRoleButton,
} from "./RequiredRoleModal.styles";
import { v4 as uuidv4 } from "uuid";

const BindingVerify = ({ questions, index, checkAgain, setValue }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [rolesCache, setRolesCache] = useState(null);
  const [binding, setBinding] = useState(
    _.get(questions[index], "discordValidation.bindingRole", false)
  );
  const requiredRolesRef = useRef(null);

  const setQuestionData = (index, label, value) => {
    let newData = [...questions];
    _.set(newData, `[${index}].${label}`, value);
    if (label == "validation") {
      if (value == "Discord") {
        _.set(
          newData,
          `[${index}].img`,
          require("assets/img/defaultDiscordBanner.png").default
        );
        _.set(newData, `[${index}].imgUpload`, [
          {
            data_url: require("assets/img/defaultDiscordBanner.png").default,
          },
        ]);
      } else if (value == "Twitter") {
        _.set(
          newData,
          `[${index}].img`,
          require("assets/img/defaultTwitterBanner.png").default
        );
        _.set(newData, `[${index}].imgUpload`, [
          {
            data_url: require("assets/img/defaultTwitterBanner.png").default,
          },
        ]);
      }
    }
    setValue(newData);
  };

  const q = questions[index];

  const isBinding = _.get(q, "discordValidation.bindingRole");

  const inviteLink = _.get(q, "content", "");

  const newEmptyRole = () => {
    return { roleName: "", roleId: "", id: uuidv4() };
  };

  const handleAddNewRole = () => {
    setRolesCache([...(rolesCache ? rolesCache : []), newEmptyRole()]);
  };

  useEffect(() => {
    setBinding(Boolean(isBinding));
  }, [isBinding]);

  useEffect(() => {
    if (binding && _.isEmpty(rolesCache)) {
      handleAddNewRole();
    }
  }, [binding, rolesCache]);

  const handleOpenRolesPopover = (cloneRoles) => {
    setRolesCache(cloneRoles);
    setAnchorEl(requiredRolesRef.current);
  };

  const handleCloseRolesPopover = () => {
    setAnchorEl(null);
  };

  const handleRoleChange = useCallback(
    (e, role) => {
      const { name, value } = e.target;
      const id = _.get(role, "id");

      const targetRole = rolesCache.find((item) => item.id === id);
      if (targetRole) {
        const newTargetRole = { ...targetRole };
        newTargetRole[name] = value;
        const newRoles = rolesCache.map((item) => {
          if (item.id === newTargetRole.id) {
            return newTargetRole;
          }
          return item;
        });

        setRolesCache(newRoles);
      }
    },
    [rolesCache]
  );

  const handleSaveRoles = useCallback(
    (index) => {
      const filterRolesCache = rolesCache.filter(
        (item) => item.roleName && item.roleId
      );

      setQuestionData(index, "discordValidation", {
        roles: filterRolesCache,
        bindingRole: true,
      });
      handleCloseRolesPopover();
    },
    [handleCloseRolesPopover, rolesCache, setQuestionData]
  );

  const handleDeleteRoleInPopover = (role) => {
    const newRolesCache = rolesCache.filter((item) => item.id !== role.id);
    setRolesCache(newRolesCache);
  };

  const handleDeleteRole = (q, index, role) => {
    const roles = [..._.get(q, "discordValidation.roles", [])];
    const filterRoles = roles.filter((item) => item.id !== role.id);
    setQuestionData(index, "discordValidation", {
      roles: filterRoles,
      bindingRole: true,
    });
  };

  const renderDiscordRoleVerify = useCallback(
    (q, index) => {
      if (_.get(q, "validation") !== "Discord") return;
      const selectedRoles = _.isEmpty(_.get(q, "discordValidation.roles", []))
        ? []
        : _.get(q, "discordValidation.roles", []);
      const cloneRoles = _.cloneDeep(selectedRoles);

      return (
        <div>
          <CustomListItem
            background="#F1F1F1"
            content
            label={<div>Required Roles</div>}
            rightIcon={
              <CustomSwitch
                checked={binding}
                onChange={async (e) => {
                  setQuestionData(index, "discordValidation", {
                    bindingRole: e.target.checked,
                    roles: selectedRoles,
                  });
                  if (e.target.checked && !_.get(q, "isRequired")) {
                    setQuestionData(index, "isRequired", true);
                  }
                }}
              />
            }
          />
          <div
            style={{ background: "#F1F1F1", margin: "5px 5px" }}
            ref={requiredRolesRef}
          >
            {binding && (
              <StyledDiscordRoleList>
                {!_.isEmpty(selectedRoles) && (
                  <>
                    <div className="roles-name-title">Roles Name</div>
                    {_.map(selectedRoles, (role, i) => {
                      return (
                        <StyledDiscordRoleSingleItem key={role.id}>
                          <StyledDiscordRoleInputContainer
                            onClick={() => handleOpenRolesPopover(cloneRoles)}
                          >
                            <StyledFakeInput>{role.roleName}</StyledFakeInput>
                          </StyledDiscordRoleInputContainer>
                          <IconButton
                            className="close-icon"
                            onClick={() => handleOpenRolesPopover(cloneRoles)}
                          >
                            <img
                              src={require("assets/img/edit_pen.svg").default}
                            />
                          </IconButton>
                          <IconButton
                            className="close-icon"
                            onClick={() => handleDeleteRole(q, index, role)}
                          >
                            <img
                              src={require("assets/img/ic_trash-2.svg").default}
                            />
                          </IconButton>
                        </StyledDiscordRoleSingleItem>
                      );
                    })}
                  </>
                )}

                <StyledAddRoleButton
                  onClick={() => handleOpenRolesPopover(cloneRoles)}
                >
                  +Add Role
                </StyledAddRoleButton>
              </StyledDiscordRoleList>
            )}

            <div>
              <Popover
                onClose={handleCloseRolesPopover}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: -460,
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <StyledPopoverContainer>
                  <StyledPopoverHeader>
                    <div className="title">Required Roles</div>
                    <IconButton
                      className="close-icon"
                      onClick={handleCloseRolesPopover}
                    >
                      <img src={require("assets/img/x-circle.svg").default} />
                    </IconButton>
                  </StyledPopoverHeader>
                  <StyledLineInPopover></StyledLineInPopover>
                  <StyledPopoverInfoArea>
                    {_.map(rolesCache, (role, i) => {
                      return (
                        <div className="single-info-item" key={role.id}>
                          <div className="single-info-item-role-name">
                            <div className="single-info-item-title">
                              Role Name
                            </div>
                            <CustomOutlinedInput
                              containerStyle={{
                                border: "1px solid #A5A5A5",
                                padding: 0,
                                borderRadius: "2.5px",
                              }}
                              noLine
                              background="white"
                              sm
                              name="roleName"
                              value={_.get(role, "roleName")}
                              blurSideEffectOnChange={false}
                              onChange={(e) => handleRoleChange(e, role)}
                            />
                          </div>
                          <div className="single-info-item-role-id">
                            <div className="single-info-item-title">
                              Role ID
                            </div>
                            <CustomOutlinedInput
                              noLine
                              background="white"
                              containerStyle={{
                                border: "1px solid #A5A5A5",
                                padding: 0,
                                borderRadius: "2.5px",
                              }}
                              sm
                              name="roleId"
                              value={_.get(role, "roleId")}
                              blurSideEffectOnChange={false}
                              onChange={(e) => handleRoleChange(e, role)}
                            />
                          </div>
                          <div className="single-info-item-role-delete">
                            <IconButton
                              onClick={() => handleDeleteRoleInPopover(role)}
                            >
                              <img
                                src={
                                  require("assets/img/ic_trash-2.svg").default
                                }
                                className="smIcon"
                              />
                            </IconButton>
                          </div>
                        </div>
                      );
                    })}
                  </StyledPopoverInfoArea>
                  <StyledPopoverButtonArea>
                    <Button
                      className="add-btn"
                      color="secondary"
                      onClick={handleAddNewRole}
                    >
                      +Add
                    </Button>
                    <Button
                      className="save-btn"
                      color="primary"
                      onClick={() => handleSaveRoles(index, cloneRoles)}
                    >
                      Save
                    </Button>
                  </StyledPopoverButtonArea>
                </StyledPopoverContainer>
              </Popover>
            </div>
          </div>
        </div>
      );
    },
    [
      anchorEl,
      handleCloseRolesPopover,
      handleRoleChange,
      handleSaveRoles,
      rolesCache,
      setQuestionData,
    ]
  );

  if (!q) return <div />;

  if (q.type !== "bind") return;

  return (
    <div>
      <CustomListItem header label="Validation" />
      <div style={{ padding: "0 5px" }}>
        <CustomDropdown
          fullWidth
          data={[
            {
              label: (
                <div className="startRow">
                  <img
                    src={require("assets/img/bindTwitter.svg").default}
                    className="smIcon"
                  />
                  Twitter
                </div>
              ),
              value: "Twitter",
            },
            {
              label: (
                <div className="startRow">
                  <img
                    src={require("assets/img/bindDiscord.svg").default}
                    className="smIcon"
                  />
                  Discord
                </div>
              ),
              value: "Discord",
            },
          ]}
          value={q.validation}
          onSelect={(e) => {
            checkAgain(false);
            setQuestionData(index, "validation", e.target.value);
          }}
        />
        <CustomListItem
          content
          subTitle
          label={
            _.get(q, "validation") == "Twitter"
              ? "Twitter Handle"
              : "Discord Invite Link"
          }
          rightIcon={
            inviteLink && (
              <img
                src={require("assets/img/check2.svg").default}
                alt="check-icon"
                style={{
                  width: "14px",
                  height: "14px",
                }}
              />
            )
          }
        />

        <CustomOutlinedInput
          onBlur={checkAgain}
          background="#F1F1F1"
          sm
          containerStyle={{ padding: 0 }}
          style={{ paddingLeft: 6, fontSize: 14, margin: 0 }}
          value={_.get(q, "content", "")}
          startAdornment={
            <span style={{ fontWeight: 500, fontSize: 12 }}>
              {_.get(q, "validation") == "Twitter" ? "@" : "discord.gg/"}
            </span>
          }
          placeholder={
            _.get(q, "validation") == "Twitter" ? "metacrm_inc" : "metaformxyz"
          }
          onChange={(e) => setQuestionData(index, "content", e.target.value)}
        />
      </div>
      {renderDiscordRoleVerify(q, index)}
    </div>
  );
};

export default BindingVerify;
