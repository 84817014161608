import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material";

import {
  AppBar,
  useMediaQuery,
  Box,
  CircularProgress,
} from "@material-ui/core";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { StyledCloseBlock, StyledCloseBtn } from "./FormFooter.styles";

const useStyles = makeStyles((theme) => ({
  appBar: (props) => {
    return {
      top: "auto",
      bottom: 0,
      zIndex: 500,
      height: 60,
      background: props.customColors.grey[800],
    };
  },
}));

const FormFooter = ({
  page,
  allPages,
  onBackPress,
  onNextPress,
  absolute = true,
  previewData,
  closePreview,
  isValidDate,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();
  const location = useLocation();

  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const CircularProgressWithLabel = (props) => {
    return (
      <Box
        position="relative"
        display="inline-flex"
        style={{ color: "#459BFF", overflow: "visible" }}
      >
        <CircularProgress
          variant="determinate"
          value={100}
          thickness={4}
          size={29}
          style={{
            position: "absolute",
            left: 1,
            top: 1,
            color: "white",
          }}
        />
        <CircularProgress
          size={31}
          thickness={6}
          variant="determinate"
          color="inherit"
          value={parseFloat((props.value / props.total) * 100)}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <div style={{ color: "white", fontSize: 9, fontWeight: 700 }}>
            {props.value}/{props.total}
          </div>
        </Box>
      </Box>
    );
  };
  const renderPoweredBy = (color = "white") => {
    return (
      <Link
        to="/"
        style={{
          color: "#FFFFFF",
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
          height: 60,
          padding: 8,
          width: "100%",
          fontSize: 15,
          justifyContent: "center",
        }}
      >
        <span style={{ color: "#a5a5a5", fontWeight: 300 }}>
          {"Powered by "}
        </span>
        <img
          src={require("assets/img/logo.png").default}
          style={{ height: 12, margin: 5 }}
        />
        <span style={{ color: color, fontWeight: 700 }}>MetaForm</span>
      </Link>
    );
  };

  const handleClose = () => {
    if (previewData && closePreview) closePreview();
    else {
      navigate("/");
    }
  };

  const isCloseBlockShow = () => {
    if (previewData && closePreview) {
      return true;
    } else if (isValidDate && !location.pathname.includes("preview")) {
      // 在列表中已經建立的 點選preview不顯示
      return true;
    } else {
      return false;
    }
  };

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      style={{ position: absolute ? "absolute" : "" }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ px: "20px", height: 60 }}
      >
        <Grid item xs={4} sm={4}>
          {isCloseBlockShow() && (
            <StyledCloseBlock onClick={handleClose}>
              <div className="close-text">Close</div>
              <i className="meta-crm-icon-ic_x_circle font-size-18" />
            </StyledCloseBlock>
          )}
        </Grid>
        {computer && (
          <Grid item xs={4} sm={4}>
            {renderPoweredBy()}
          </Grid>
        )}
        <Grid
          item
          xs={computer ? 4 : 8}
          sm={4}
          className="endRow"
          style={{ paddingRight: 10 }}
        >
          {!computer && page == 0 && (
            <div style={{ transform: "scale(0.7)" }} className="row">
              <img
                src={require("assets/img/MetaMask.png").default}
                className="smIcon"
              />
              <div
                style={{ cursor: "pointer", color: "white" }}
                onClick={() => {
                  if (!navigator.clipboard) {
                    return;
                  }
                  navigator.clipboard
                    .writeText(window.location)
                    .then(() => {
                      alert(
                        "URL copied, please try to open it in the Metamask built-in browser"
                      );
                    })
                    .catch((err) => {
                      alert(err);
                      // console.log("Something went wrong", err);
                    });
                }}
              >
                For better survey experience You can use{" "}
                <span style={{ fontWeight: 700 }}>MetaMask</span> browser{" "}
              </div>
            </div>
          )}

          {page <= allPages && page > 0 && (
            <>
              {page > 1 && (
                <IconButton
                  style={{ padding: 0, marginRight: 10 }}
                  onClick={() => {
                    onBackPress();
                  }}
                >
                  <img
                    className="iconButton"
                    src={require("assets/img/forward.png").default}
                  />
                </IconButton>
              )}

              {page < allPages && (
                <IconButton
                  style={{ padding: 0, marginRight: 10 }}
                  onClick={() => {
                    onNextPress();
                  }}
                >
                  <img
                    className="iconButton"
                    src={require("assets/img/next.png").default}
                  />
                </IconButton>
              )}

              <CircularProgressWithLabel
                value={page <= allPages ? page : allPages}
                total={allPages}
              />
            </>
          )}
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default FormFooter;
