import {
  encrypt,
  recoverPersonalSignature,
  recoverTypedSignatureLegacy,
  recoverTypedSignature,
  recoverTypedSignature_v4 as recoverTypedSignatureV4,
} from "eth-sig-util";
import { ethers } from "ethers";
import { JSEncrypt } from "jsencrypt";
import { RSA, Crypt } from "hybrid-crypto-js";

export function stringifiableToHex(value) {
  return ethers.utils.hexlify(Buffer.from(JSON.stringify(value)));
}

export const onDecryptText = async (formIsOld, decryptedPrivateKey, encryptedText) => {
  if (formIsOld) {
    var decrypt = new JSEncrypt();
    decrypt.setPrivateKey(decryptedPrivateKey);
    var decrypted = decrypt.decrypt(encryptedText);
    return decrypted;
  } else {
    var crypt = new Crypt();
    var decrypted = crypt.decrypt(decryptedPrivateKey, encryptedText);
    return decrypted.message;
  }
};

export const onEncryptText = async (publicKey, text) => {
  var crypt = new Crypt();
  var encrypted = crypt.encrypt(publicKey, text);
  return encrypted;
};

export const onDecrypt = async (encryptedPrivateKey, address) => {
  //user private key decrypt
  let clearText = await window.ethereum.request({
    method: "eth_decrypt",
    params: [encryptedPrivateKey, address],
  });
  return clearText;
};

export const onEncrypt = async (encryptionKey, privateKey) => {
  let ciperText = encrypt(
    encryptionKey,
    { data: privateKey },
    "x25519-xsalsa20-poly1305"
  );
  return stringifiableToHex(ciperText);
};

export const onGenerateKeys = async () => {
  // Generate a RSA key pair using the `hybrid-crypto-js` library.
  var rsa = new RSA();
  var keyPair = await rsa.generateKeyPairAsync();
  return { private: keyPair.privateKey, public: keyPair.publicKey };
};

export const onGetEncryptionKey = async (address) => {
  let key = await window.ethereum.request({
    method: "eth_getEncryptionPublicKey",
    params: [address],
  });
  return key;
};
