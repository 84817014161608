import React, { useEffect, useState } from "react";
import { useConnectWallet, useUserSelect } from "features/home/redux/hooks";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import { useParams } from "react-router-dom";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";
import useBrowserContextCommunication from "react-window-communication-hook";
import { apiUrl, twitterAuthUrl } from "features/configure";
import { Button, COLOR } from "@metacrm/metacrm-material-ui/dist/Button";
import { StyledConnectSuccess } from "./Auth.styles";

const Auth = () => {
  const { auth } = useConnectWallet();
  const { type } = useParams();
  const [communicationState, postMessage] =
    useBrowserContextCommunication("metaform");
  const location = useLocation();
  const [code, setCode] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const [authPending, setAuthPending] = useState(true);
  const [loginData, setLoginData] = useState(null);

  useEffect(() => {
    if (auth) {
      const query = queryString.parse(_.get(location, "search"));
      const code = _.get(query, "code");
      const state = _.get(query, "state");
      const error = _.get(query, "error");
      if (error) {
        setAuthPending(false);
        setIsAuth(false);
      } else {
        setCode(code);
      }
    }
  }, [location, auth]);

  useEffect(async () => {
    if (code) {
      let result = await axios.post(apiUrl + "api/user/login" + type, { code });
      setLoginData(result.data);
    }
  }, [code]);

  useEffect(() => {
    if (loginData) {
      if (loginData.loginSuccess) {
        postMessage({
          account: loginData.account,
          token: loginData.token,
          isLoading: false,
          type,
        });
        setIsAuth(loginData.loginSuccess);
      }
      setAuthPending(false);
    }
  }, [loginData]);

  useEffect(() => {
    if (!authPending && isAuth) {
      window.close();
    }
  }, [authPending]);

  if (authPending) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 550,
        }}
      >
        <ConnectWallet hidden />
        <img
          src={require("assets/img/loading.gif").default}
          className="lgIcon"
        />
      </div>
    );
  } else if (isAuth) {
    return (
      <StyledConnectSuccess>
        <img src={require("assets/img/check2.svg").default} />
        <h2>Success!</h2>
        <div className="text-content">
          <p>
            {type === "Discord"
              ? "Discord server joined and verified."
              : "Connect success"}
          </p>
          <p>Now you can close this tab and return to the survey.</p>
        </div>
      </StyledConnectSuccess>
    );
  } else {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 550,
          }}
        >
          <h2>Connect Failed! Please try again.</h2>
          <Button
            color={COLOR.SECONDARY}
            style={{
              marginLeft: "10px",
            }}
            onClick={() => window.open(twitterAuthUrl, "_self")}
          >
            Try Again
          </Button>
        </div>
      </>
    );
  }
};

export default Auth;
