import React, { useMemo } from "react";
import { Grid } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Controller,
  Thumbs,
  Autoplay,
} from "swiper";
import "swiper/swiper-bundle.css";
import {
  StyledOurPartnersTitle,
  StyledCustomSwiperContainer,
  StyledPartnerSliderContainer,
  StyledPartnerSliderLogo,
  StyledPartnerSliderLogoUnderLine,
  StyledPartnerDescribe,
  StyledPartnerBossArea,
  StyledPartnerBossImage,
  StyledPartnerBossInfo,
  StyledGrid,
  StylePartnersIconArea,
  StylePartnersIconSwipe,
  StylePartnersIconComputerContainer,
} from "./partner.styles";

SwiperCore.use([Navigation, Pagination, Controller, Thumbs, Autoplay]);

const partnersForMobile = (partners) => {
  let newArr = [];
  for (let i = 0; i < partners.length; i += 12) {
    newArr.push(partners.slice(i, i + 12));
  }
  return newArr;
};

const LandingOurPartners = ({
  computer,
  partnerSwipeInfo,
  partnersIcon,
  titleBackgroundColor,
}) => {
  const partnersForMobileArray = useMemo(
    () => partnersForMobile(partnersIcon),
    [partnersIcon]
  );

  return (
    <>
      <StyledOurPartnersTitle computer={computer}>
        <div className="title-text">Trusted By</div>

        <div className="title-line"></div>
      </StyledOurPartnersTitle>
      <StyledCustomSwiperContainer>
        <Swiper
          id="main"
          tag="section"
          wrapperTag="ul"
          navigation
          pagination={{ clickable: true, type: "bullets" }}
          spaceBetween={50}
          slidesPerView={"auto"}
          centeredSlides={true}
          breakpoints={{ 960: { slidesPerView: "auto", spaceBetween: 120 } }}
          autoplay={{
            delay: 3000,
          }}
        >
          {partnerSwipeInfo.map((slideItem) => (
            <SwiperSlide key={slideItem.id}>
              <StyledPartnerSliderContainer>
                <StyledPartnerSliderLogo>
                  <img src={slideItem.partnerLogo} />
                </StyledPartnerSliderLogo>
                <StyledPartnerSliderLogoUnderLine></StyledPartnerSliderLogoUnderLine>
                <StyledPartnerDescribe>
                  {slideItem.content}
                </StyledPartnerDescribe>
                <StyledPartnerBossArea>
                  <StyledPartnerBossInfo>
                    <div className="partner-boss-title">
                      {slideItem.partnerTitle}
                    </div>
                    <div className="partner-name">{slideItem.partnerName}</div>
                  </StyledPartnerBossInfo>
                </StyledPartnerBossArea>
              </StyledPartnerSliderContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledCustomSwiperContainer>

      <StylePartnersIconArea>
        {computer ? (
          <StylePartnersIconComputerContainer container>
            <Grid container>
              {partnersIcon.map((partner, idx) => (
                <StyledGrid item xs={6} md={3} lg={2} key={idx}>
                  <img
                    src={require(`assets/img/parner_ic/${partner}.svg`).default}
                    alt="partner-icon"
                  />
                </StyledGrid>
              ))}
            </Grid>
          </StylePartnersIconComputerContainer>
        ) : (
          <>
            <StylePartnersIconSwipe maxWidth={"298px"}>
              <Swiper
                id="partners-icon"
                tag="section"
                wrapperTag="ul"
                navigation
                pagination={{ clickable: true, type: "bullets" }}
              >
                {partnersForMobileArray.map((slideItem, idx) => (
                  <SwiperSlide key={idx}>
                    <Grid container>
                      {slideItem.map((iconItem, iconIdx) => (
                        <StyledGrid item xs={3} key={iconItem + iconIdx}>
                          <img
                            src={
                              require(`assets/img/parner_ic/${iconItem}.svg`)
                                .default
                            }
                            alt="partner-icon"
                          />
                        </StyledGrid>
                      ))}
                    </Grid>
                  </SwiperSlide>
                ))}
              </Swiper>
            </StylePartnersIconSwipe>
          </>
        )}
      </StylePartnersIconArea>
    </>
  );
};

export default LandingOurPartners;
