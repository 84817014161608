import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Modal, MenuItem, CircularProgress } from "@material-ui/core";
export const Loading = ({ open, text, fullScreen = true, popover }) => {
  if (popover) {
    return (
      <Modal className="modal" open={open}>
        <div
          style={{
            background: "#FFFFFF",
            borderRadius: 10,
            width: 180,
            textAlign: "center",
            padding: 48,
          }}
        >
          <img
            src={require("assets/img/loading.gif").default}
            style={{ height: 70, width: 70 }}
          />
          <div style={{ fontSize: 18, fontWeight: 700, color: "#383538" }}>
            {text || "Loading"}
          </div>
        </div>
      </Modal>
    );
  }
  if (fullScreen)
    return (
      <Modal open={open} className={"modal"}>
        <div className={"loadingPaper"}>
          <div>
            {/* <img
            src={require("assets/img/logo.svg").default}
            style={{ height: 70, objectFit: "contain" }}
          /> */}
          </div>
          <div style={{ marginTop: 20 }}>
            {/* <CircularProgress /> */}
            <img
              src={require("assets/img/loading.gif").default}
              style={{ height: 100, width: 100 }}
            />
          </div>
          <h4>{text}</h4>
        </div>
      </Modal>
    );
  return (
    <div
      style={{
        visibility: open ? "visible" : "hidden",
        height: "100%",
        background: "rgba(255,255,255,0.6)",
        width: "100%",
        position: "absolute",
        display: "flex",
        zIndex: 100,
        textAlign: "center",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <img
        src={require("assets/img/loading.gif").default}
        style={{ height: 70, width: 70 }}
      />
      <div style={{ fontSize: 20, fontWeight: 600, color: "#383538" }}>
        {text}
      </div>
    </div>
  );
};

export default Loading;
